import { useEffect } from "react";
import { Card, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

const AviationTourBox = (props) => {
  const statusIcon = () => {
    var iconName = "";
    var iconColour = "";
    if (props.tour.status === "upload") {
      iconName = "upload_file";
      iconColour = "redIcon";
    } else if (props.tour.status === "processing") {
      iconName = "cycle";
      iconColour = "orangeIcon";
    } else {
      iconName = "done";
      iconColour = "greenIcon";
    }

    return (
      <span className={"material-symbols-outlined ms-auto me-3 " + iconColour}>
        {iconName}
      </span>
    );
  };

  const getStatusText = () => {
    if (props.tour.status === "upload") {
      return "Upload Pictures";
    } else if (props.tour.status === "processing") {
      return "Processing Tour";
    } else {
      return "Complete";
    }
  };

  useEffect(() => {}, [props]);
  return (
    <Link className="text-link" to={`/tours/${props.tour.id}`}>
      <Stack className="grow aircraft-box" direction="horizontal">
        <img
          className="card-image-underline"
          height="80"
          width="100"
          src={props.tour.preview_url ? props.tour.preview_url : ""}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://via.placeholder.com/500x260?text=" + props.tour.status;
          }}
          style={{
            maxHeight: "260px",
            maxWidth: "700px",
            objectFit: "fit",
            borderRadius: "5px",
          }}
        />
        <div>
          <div className=" text-truncate tour-box-title">
            {props.tour.title}
          </div>
        </div>
        {props.short ? (
          <></>
        ) : (
          <Stack direction="horizontal">
            <div>Status: {getStatusText()}</div>
            {/* {statusIcon()} */}
          </Stack>
        )}

        {props.tour.aircraft ? (
          <div className=" text-truncate tour-box-title ms-auto">
            {props.tour.aircraft.title}
          </div>
        ) : (
          <></>
        )}
      </Stack>
    </Link>
  );
};

export default AviationTourBox;
