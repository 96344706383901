import { Button, Col, Container, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import {
  addTour,
  addTourToSpace,
  deleteAircraft,
  deleteAircraftDocument,
  deleteSpace,
  getAircraft,
  getAircraftDocuments,
  getAircraftTours,
  getClientTours,
  getSpaceDetails,
  getSpaceTours,
  getTourDetails,
  updateAircraft,
  updateSpaceDetails,
  uploadAircraftDocument,
  uploadAircraftPicture,
  uploadSpacePicture,
} from "../../../firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import EditableBox from "../../../components/EditableBox";
import AviationTourBox from "../../../components/aviation_components/AviationTourBox";
import {
  addAviationTour,
  deleteAviationSpace,
  showHash,
} from "../../../firebase";
import ConfirmationBox from "../../../components/ConfirmationBox";
import DocumentsTabContent from "../../../components/aviation_components/tour_details_components/DocumentsTabContent";
import Loading from "../../../components/Loading";
const AviationAircraftPage = () => {
  const [spaceDetails, setSpaceDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [tours, setTours] = useState([]);
  const [tourIds, setTourIds] = useState({});
  const [updateSpace, setUpdateSpace] = useState(false);
  const [model, setModel] = useState("");
  const [hangar, setHangar] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTours, setLoadingTours] = useState(true);

  const [showDeleteDocConfirm, setShowDeleteDocConfirm] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState("");
  const [documents, setDocumnets] = useState([]);

  const user = useContext(UserContext);
  let { aircraftId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getAircraft(aircraftId).then((res) => {
      setSpaceDetails(res);
      setTitle(res.title);
      setImageUrl(res.aircraft_image_url);

      setHangar(res.hangar);
      setModel(res.model);
      setLoading(false);
    });

    getAircraftTours(aircraftId).then((ids) => {
      setTourIds(ids);
      Promise.all(
        ids.map(async (tour) => {
          return await getTourDetails("", tour);
        })
      ).then((res2) => {
        let order = orderTours(res2);
        setTours(order);
        setLoadingTours(false);
      });
    });

    getAircraftDocuments(aircraftId).then((res) => {
      setDocumnets(res);
    });
  }, [aircraftId, updateSpace]);

  const orderTours = (tours) => {
    return tours.sort((a, b) => {
      return a.creation_date.seconds < b.creation_date.seconds;
    });
  };

  const toggleEdit = async (e) => {
    if (edit === true) {
      let space = {
        ...spaceDetails,
        title: title ? title : "",
        hangar: hangar ? hangar : "",
        model: model ? model : "",
      };
      setSpaceDetails(space);
      await updateAircraft(space);
    }
    setEdit(!edit);
  };

  const toggleDelete = async () => {
    setShowDeleteConfirm(true);
  };

  const changeSpaceImage = async (e) => {
    const file = e.target.files[0];
    let url = await uploadAircraftPicture(aircraftId, file);
    setImageUrl(url);
  };

  const addEmptyTour = async () => {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    let dateString = date.toISOString().split("T")[0];
    let id = uuidv4().toString();
    let tour = {
      status: "upload",
      aircraft_id: aircraftId,
      id: id,
      title: dateString,
      tour_id: "",
      creation_date: date,
      client_email: user.user.email,
      company_id: spaceDetails.company_id,
      creator_id: user.user.uid,
    };
    setTours([tour, ...tours]);
    setTourIds([id, ...tourIds]);
    console.log(tour);
    await addAviationTour(tour);
  };

  const deleteEntireSpace = async () => {
    setShowDeleteConfirm(false);
    await deleteAircraft(spaceDetails);
    navigate(-1);
  };

  const uploadDocument = async (name, url) => {
    let id = uuidv4().toString();

    const document = {
      id: id,
      title: name,
      url: url,
    };

    await uploadAircraftDocument(aircraftId, document);
    setDocumnets([...documents, document]);
  };

  const deleteDocument = async () => {
    await deleteAircraftDocument(aircraftId, deleteDocId);
    setDocumnets(documents.filter((e) => e.id !== deleteDocId));
    setDeleteDocId("");
    setShowDeleteDocConfirm(false);
  };

  const toggleDeleteDocument = async (id) => {
    setShowDeleteDocConfirm(true);
    setDeleteDocId(id);
  };

  return (
    <Container className="page-margin">
      <ConfirmationBox
        heading="Delete Aircraft"
        show={showDeleteConfirm}
        handleClose={() => setShowDeleteConfirm(false)}
        cancelText="Cancel"
        body="Are you sure that you wish to delete this aircraft. This cannot be undone."
        confirmText="Delete Aircraft"
        handleSubmit={() => deleteEntireSpace()}
      />

      <ConfirmationBox
        heading="Delete Document"
        show={showDeleteDocConfirm}
        handleClose={() => setShowDeleteDocConfirm(false)}
        cancelText="Cancel"
        body="Are you sure that you wish to delete this document?"
        confirmText="Delete Document"
        handleSubmit={() => deleteDocument()}
      />
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <h1 className="page-title title-margin">{title}</h1>
          <Tabs
            defaultActiveKey="tours"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="tours" title="Tours">
              <Stack direction="horizontal">
                <div className="section-header">Virtual Tours</div>
                <Button
                  onClick={() => addEmptyTour()}
                  variant="primary"
                  bg="black"
                  className={"login-submit-button ms-auto "}
                >
                  Add a tour
                </Button>
              </Stack>

              <hr />
              {loadingTours ? (
                <div className="small-box-content d-flex align-items-center align-content-center">
                  <Loading center={true} />
                </div>
              ) : (
                <div>
                  {tours?.length === 0 ? (
                    <div className="no-tour-box">
                      <div className="no-tour-text">
                        Add your first tour to get started!
                      </div>
                    </div>
                  ) : (
                    <Row>
                      {tours.map((tour, i) => {
                        return (
                          <div key={i}>
                            <AviationTourBox tour={tour} />
                            <hr />
                          </div>
                        );
                      })}
                    </Row>
                  )}
                </div>
              )}
            </Tab>
            <Tab eventKey="details" title="Details">
              <Row className="">
                <Col md={6} xs={12} className="">
                  <div className="section-header">Details</div>
                  <div className="subsection-header">Registration Number</div>
                  <EditableBox
                    content={title}
                    updateVal={setTitle}
                    edit={edit}
                  />
                  <div className="subsection-header">Model</div>
                  <EditableBox
                    content={model}
                    updateVal={setModel}
                    edit={edit}
                  />
                  <div className="subsection-header">Hangar</div>
                  <EditableBox
                    content={hangar}
                    updateVal={setHangar}
                    edit={edit}
                  />
                  <hr className="d-block d-sm-none" />
                </Col>
                <Col md={6} xs={12}>
                  <div className="section-header">Image</div>
                  <label htmlFor="space_image_upload" className="file-upload">
                    <img
                      className="space-image"
                      src={imageUrl}
                      width="100%"
                      height="350px"
                    ></img>
                    <div className="file-upload-content">
                      <span className="material-symbols-outlined file-upload-icon">
                        upload_file
                      </span>
                    </div>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => changeSpaceImage(e)}
                    accept="image/*"
                    id="space_image_upload"
                    style={{ display: "none" }}
                  />
                </Col>
              </Row>

              <hr />

              <Stack direction="horizontal">
                <Button
                  variant="primary"
                  bg="black"
                  className="login-submit-button"
                  onClick={() => toggleEdit()}
                >
                  {edit ? "Confirm" : "Edit Details"}
                </Button>
                <Button
                  variant="primary"
                  bg="black"
                  className="delete-button ms-auto"
                  onClick={() => toggleDelete()}
                >
                  Delete Aircraft
                </Button>
              </Stack>
            </Tab>
            <Tab eventKey="documents" title="Documents">
              <DocumentsTabContent
                documents={documents}
                uploadDocument={uploadDocument}
                deleteDocument={toggleDeleteDocument}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </Container>
  );
};

export default AviationAircraftPage;
