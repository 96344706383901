import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Stack,
} from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import logo from "../../../logo.png";
import AviationSpaceBox from "../../../components/aviation_components/AviationSpaceBox";
import {
  addSpace,
  createAircraft,
  getAircraft,
  getAircraftTourCount,
  getCompanyAircraft,
  getSpaceDetails,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import AircraftBox from "../../../components/aviation_components/AircraftBox";
import Loading from "../../../components/Loading";
import EditableBox from "../../../components/EditableBox";
const ManagerAircraftPage = () => {
  const [spaces, setSpaces] = useState([]);
  const [sortedAircraft, setSortedAircraft] = useState([]);
  const [updateSpace, setUpdateSpace] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [sortMSN, setSortMSN] = useState(true);
  const [sortTours, setSortTours] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    getCompanyAircraft(user.details.company_id).then((res) => {
      Promise.all(
        res.map(async (space) => {
          let aircraft = await getAircraft(space.id);
          aircraft.num_of_tours = await getAircraftTourCount(space.id);
          return aircraft;
        })
      ).then((res2) => {
        setSpaces(res2);
        setSortedAircraft(res2.sort((a, b) => a.title.localeCompare(b.title)));
        setLoaded(true);
      });
    });
  }, [user.user]);

  const addEmptySpace = async () => {
    let space = await createAircraft(
      user.details.company_id,
      user.details.company,
      user.user.uid,
      "untitled aircraft"
    );
    setSortedAircraft([space, ...spaces]);
    setSpaces([space, ...spaces]);
  };

  const changeSearch = (text) => {
    if (text === "") {
      setSortedAircraft(spaces);
      return;
    }
    setSortedAircraft(
      spaces.filter((aircraft) =>
        aircraft.title.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  const sortByMSN = () => {
    setSortMSN(true);
    setSortTours(false);
    setSortedAircraft(
      sortedAircraft.sort((a, b) => a.title.localeCompare(b.title))
    );
  };

  const sortByTours = () => {
    setSortMSN(false);
    setSortTours(true);
    setSortedAircraft(
      sortedAircraft.sort((a, b) => a.num_of_tours < b.num_of_tours)
    );
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title">{`Aircraft`}</h1>
      <hr className="mt-0" />
      <Stack direction="horizontal">
        <Button
          variant="primary"
          bg="black"
          className="login-submit-button"
          onClick={() => addEmptySpace()}
        >
          Add an Aircraft
        </Button>
        <div className="ms-auto me-3 mb-auto mt-1">
          <DropdownButton
            title={
              <span className={"material-symbols-outlined sort-icon"}>
                sort
              </span>
            }
            bg="black"
            align={"end"}
            className=""
            variant="dropdown"
          >
            <Dropdown.ItemText>
              <Form.Control
                onChange={(e) => changeSearch(e.target.value)}
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Search by MSN..."
              />
            </Dropdown.ItemText>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              onClick={() => sortByMSN()}
              className={sortMSN ? "dropdown-item-selected" : ""}
            >
              Sort By MSN
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => sortByTours()}
              className={sortTours ? "dropdown-item-selected" : ""}
            >
              Sort By Number of Tours
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </Stack>

      <hr className="" />
      {loaded ? (
        <>
          {spaces?.length === 0 ? (
            <Row className="aviation-welcome-box-container d-flex justify-content-center">
              <Col className="text-center aviation-welcome-box" md={4} xs={12}>
                <img
                  alt=""
                  src={logo}
                  width="250"
                  height="80"
                  className="d-inline-block align-top"
                />

                <div className="aviation-welcome">
                  Welcome to VuProp!
                  <br />
                  Add your first aircraft to get started.
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                {sortedAircraft?.map((space, i) => {
                  return (
                    <div key={i}>
                      <AircraftBox aircraft={space} key={i} />
                      <hr />
                    </div>
                  );
                })}
              </Row>
            </>
          )}
        </>
      ) : (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      )}
    </Container>
  );
};

export default ManagerAircraftPage;
