import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AviationEquipmentBox from "../../../components/aviation_components/AviationEquipmentBox";
import AviationAircraftContent from "../../../components/aviation_components/dashboard_components/AviationAircraftContent";
import AviationEquipmentContent from "../../../components/aviation_components/dashboard_components/AviationEquipmentContent";
import AviationNewToursContent from "../../../components/aviation_components/dashboard_components/AviationNewToursContent";
import AviationStaffCountContent from "../../../components/aviation_components/dashboard_components/AviationStaffCountContent";
import AviationWelcomeContent from "../../../components/aviation_components/dashboard_components/AviationWelcomeContent";
import BillingEstimateContent from "../../../components/aviation_components/dashboard_components/BillingEstimateContent";
import BoxTitle from "../../../components/aviation_components/dashboard_components/BoxTitle";
import {
  getBillingEstimate,
  getCompanyAircraftCount,
  getCompanyEquipmentCount,
  getCompanyStaffCount,
  getCompanyTourCount,
} from "../../../firebase";

const AviationCompanyDetailsPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [numOfTours, setNumOfTours] = useState(0);
  const [numOfAircraft, setNumOfAircraft] = useState(0);
  const [numOfEquipment, setNumOfEquipment] = useState(0);
  const [numOfStaff, setNumOfStaff] = useState(0);
  const [billingEstimate, setBillingEstimate] = useState(0);

  useEffect(() => {
    setLoading(true);
    let promises = [];
    let getNumOfTours = getCompanyTourCount(props.id).then((res) => {
      setNumOfTours(res);
    });
    promises.push(getNumOfTours);

    let getNumOfAircraft = getCompanyAircraftCount(props.id).then((res) => {
      setNumOfAircraft(res);
    });
    promises.push(getNumOfAircraft);

    let getNumOfEquipment = getCompanyEquipmentCount(props.id).then((res) => {
      setNumOfEquipment(res);
    });
    promises.push(getNumOfEquipment);

    let getNumOfStaff = getCompanyStaffCount(props.id).then((res) => {
      setNumOfStaff(res);
    });
    promises.push(getNumOfStaff);

    let getBillEstimate = getBillingEstimate(props.id).then((res) => {
      setBillingEstimate(res);
    });
    promises.push(getBillEstimate);

    Promise.all(promises).then((res) => {
      setLoading(false);
    });
  }, [props]);
  return (
    <Container className="page-margin">
      <h1 className="page-title">{`Details`}</h1>
      <hr />
      <Row>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div className="dashboard-box-small">
            <BoxTitle title="Logo" color="black" />
            <AviationWelcomeContent logo_url="https://firebasestorage.googleapis.com/v0/b/vuprop-a0b9f.appspot.com/o/company_images%2FSMBC.jpg?alt=media&token=b61d9f38-d041-49be-b917-a18a5c976ff6" />
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboard-box-small">
            <BoxTitle title="Accounts" color="black" />

            <AviationStaffCountContent
              num_staff={numOfStaff}
              loading={loading}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboard-box-small">
            <BoxTitle title="Total Tours" color="black" />

            <AviationNewToursContent
              num_new_tours={numOfTours}
              loading={loading}
            />
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div className="dashboard-box-small">
            <BoxTitle title="Total Aircraft" color="black" />
            <AviationAircraftContent
              num_aircraft={numOfAircraft}
              loading={loading}
            />
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div className="dashboard-box-small">
            <BoxTitle title="Total Equipment" color="black" />
            <AviationEquipmentContent
              num_equipment={numOfEquipment}
              loading={loading}
            />
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div className="dashboard-box-small">
            <BoxTitle title="Monthly Billing Estimate" color="black" />
            <BillingEstimateContent
              billing_estimate={billingEstimate}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AviationCompanyDetailsPage;
