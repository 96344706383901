import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerAircraftListPage from "./manager_pages/ManagerAircraftListPage";
import OperatorAircraftListPage from "./operator_pages/OperatorAircraftListPage";

const AviationAircraftListPage = (props) => {
  const user = useContext(UserContext);
  const getAircraftPage = () => {
    if (user.details.access === "manager") {
      return <ManagerAircraftListPage />;
    } else if (user.details.access === "operator") {
      return <OperatorAircraftListPage />;
    }
  };
  return getAircraftPage();
};

export default AviationAircraftListPage;
