import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import CompanyBox from "../../components/admin_components/CompanyBox";
import Loading from "../../components/Loading";
import { getAllCompanies } from "../../firebase";

const ListOfCompaniesPage = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    getAllCompanies().then((res) => {
      setCompanies(res);
      setLoading(false);
    });
  }, []);

  const addCompany = () => {
    navigate("/company/add");
  };
  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">Client Companies</h1>
      <hr />
      <div className="m-3">
        <Row>
          <Col xs={6}>
            <div className="list-category">Company</div>
          </Col>
          <Col xs={3}>
            <div className="list-category">Industry</div>
          </Col>
          <Col xs={3}>
            <div className="list-category">Unfinished Tours</div>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <Loading />
        ) : (
          companies.map((company, i) => {
            return (
              <div key={i}>
                <CompanyBox company={company} />
                {i !== companies.length - 1 ? <hr className="" /> : <></>}
              </div>
            );
          })
        )}
      </div>
      <Button
        variant="primary"
        bg="black"
        className={"login-submit-button mt-3"}
        onClick={() => addCompany()}
      >
        Add New Company
      </Button>
    </Container>
  );
};

export default ListOfCompaniesPage;
