import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router";
import { addClient, addEquipmentToCompany, cyrb53 } from "../../../firebase";
import EditableDropdown from "../../../components/EditableDropdown";
import EditableBox from "../../../components/EditableBox";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { UserContext } from "../../../providers/UserProvider";

const AviationAddEquipmentPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(true);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [hasTripod, setHasTripod] = useState("");
  const [tripodId, setTripodId] = useState("");
  const [type, setType] = useState("");
  const [serial, setSerial] = useState("");
  const [model, setModel] = useState("");

  const user = useContext(UserContext);
  const navigate = useNavigate();
  let { companyName } = useParams();
  useEffect(() => {}, []);

  const saveEquipment = async () => {
    if (
      title === "" ||
      type === "" ||
      model === "" ||
      hasTripod === "" ||
      tripodId === "" ||
      serial === ""
    ) {
      return;
    }

    const companyId = cyrb53(companyName);
    let id = uuidv4().toString();
    let equipment = {
      title: title,
      type: type,
      model: model,
      serial: serial,
      has_tripod: hasTripod,
      tripod_id: tripodId,
      address: address,
      address_2: address2,
      city: city,
      country: country,
      post_code: postalCode,
      company: companyName,
      company_id: companyId,
      id: id,
    };

    await addEquipmentToCompany(companyId, equipment);
    navigate(-1);
  };
  return (
    <Container className="page-margin">
      <div>
        <h1 className="page-title title-margin">{`Add Equipment: ${companyName}`}</h1>
        <hr />
        <div className="section-header">Details</div>
        <Row>
          <Col md={6}>
            <div className="subsection-header">Title*</div>
            <EditableBox content={title} updateVal={setTitle} edit={edit} />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Type*</div>
            <EditableBox content={type} updateVal={setType} edit={edit} />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Model*</div>
            <EditableBox content={model} updateVal={setModel} edit={edit} />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Serial Number*</div>
            <EditableBox content={serial} updateVal={setSerial} edit={edit} />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Has Tripod*</div>
            <EditableBox
              content={hasTripod}
              updateVal={setHasTripod}
              edit={edit}
            />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Tripod ID*</div>
            <EditableBox
              content={tripodId}
              updateVal={setTripodId}
              edit={edit}
            />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Address</div>
            <EditableBox content={address} updateVal={setAddress} edit={edit} />
          </Col>
          <Col md={6}>
            <div className="subsection-header">Address 2</div>
            <EditableBox
              content={address2}
              updateVal={setAddress2}
              edit={edit}
            />
          </Col>
          <Col md={4}>
            <div className="subsection-header">City</div>
            <EditableBox content={city} updateVal={setCity} edit={edit} />
          </Col>
          <Col md={4}>
            <div className="subsection-header">Country</div>
            <EditableBox content={country} updateVal={setCountry} edit={edit} />
          </Col>
          <Col md={4}>
            <div className="subsection-header">Postal Address</div>
            <EditableBox
              content={postalCode}
              updateVal={setPostalCode}
              edit={edit}
            />
          </Col>
        </Row>
        <Button
          variant="primary"
          bg="black"
          className={"login-submit-button"}
          onClick={() => saveEquipment()}
        >
          Save Equipment
        </Button>
        <br />
      </div>
    </Container>
  );
};

export default AviationAddEquipmentPage;
