import { Button, Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import TourBox from "../../components/TourBox";
import { Link } from "react-router-dom";
import { getClientTours, getTourDetails } from "../../firebase";
const ClientTours = () => {
  const [tours, setTours] = useState([]);

  const user = useContext(UserContext);

  useEffect(() => {
    getClientTours(user.user.uid, false).then((res) => {
      Promise.all(
        res.map(async (tour) => {
          return await getTourDetails("", tour.tour_id);
        })
      ).then((res2) => {
        setTours(res2);
      });
    });
  }, [user.user]);

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">All Tours</h1>
      <hr />
      <Row>
        {tours.map((tour, i) => {
          return (
            <Col key={i} xl={3} lg={4} md={6} sm={6}>
              <TourBox tour={tour} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ClientTours;
