import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const AviationWelcomeContent = (props) => {
  return (
    <div className="d-flex align-content-center align-items-center box-black">
      <img
        src={props.logo_url}
        className="company-logo-image"
      ></img>
    </div>
  );
};

export default AviationWelcomeContent;
