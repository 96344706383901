import { Col, Container, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const AviationEquipmentBox = (props) => {
  const navigate = useNavigate();

  const goToEquipment = () => {
    let link = props.admin
      ? `/aviation/company/${props.company}/equipment/${props.equipment.id}`
      : `/equipment/${props.equipment.id}`;
    navigate(link);
  };
  return (
    <tr className="client-box grow mb-3 mt-3" onClick={() => goToEquipment()}>
      <td className="staff-box-title">{props.index}</td>
      {/* <span className={"material-symbols-outlined me-3 small-box-icon"}>
            photo_camera
          </span> */}
      <td className="">{props.equipment.title}</td>
      <td className="">{props.equipment.serial}</td>
      <td className="">{props.equipment.has_tripod ? "YES" : "NO"}</td>
      <td className="">{props.equipment.city}</td>
      <td className="">{props.equipment.post_code}</td>
    </tr>
  );
};

export default AviationEquipmentBox;
