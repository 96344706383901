import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import AviationEquipmentBox from "../../../components/aviation_components/AviationEquipmentBox";
import AviationHangarBox from "../../../components/aviation_components/AviationHangarBox";
import Loading from "../../../components/Loading";
import {
  getCompanyEquipment,
  getCompanyHangars,
  getEquipment,
  getHangarDetails,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

const ManagerEquipmentListPage = (props) => {
  const [equipment, setEquipment] = useState([]);
  const [equipmentIds, setEquipmentIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  useEffect(() => {
    setLoading(true);
    getCompanyEquipment(user.details.company_id).then((res) => {
      setEquipmentIds(res);
      Promise.all(
        res.map(async (piece) => {
          return await getEquipment(piece.id);
        })
      ).then((res2) => {
        setEquipment(res2);
        setLoading(false);
      });
    });
  }, [user.details]);

  const orderEquipment = () => {
    navigate("order");
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Equipment`}</h1>
      <hr />
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Serial #</th>
                <th>Has Tripod</th>
                <th>City</th>
                <th>Post Code</th>
              </tr>
            </thead>
            <tbody>
              {equipment.length > 0 ? (
                equipment.map((piece, i) => {
                  return (
                    <AviationEquipmentBox
                      equipment={piece}
                      isAdmin={false}
                      index={i + 1}
                      key={i}
                    />
                  );
                })
              ) : (
                <div className="my-3">
                  Order your first camera to start making virtual tours.
                </div>
              )}
            </tbody>
          </Table>
          <hr />
          <Button
            variant="primary"
            bg="black"
            className={"login-submit-button"}
            onClick={() => orderEquipment()}
          >
            Order Camera
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ManagerEquipmentListPage;
