import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CompanyBox = (props) => {
  return (
    <Link
      className="text-link"
      to={`/${props.company.industry.toLowerCase()}/company/${
        props.company.name
      }`}
    >
      <div className="company-box grow">
        <Row>
          <Col xs={6}>
            <div className="company-box-title">{props.company.name}</div>
          </Col>
          <Col xs={3}>
            <div className="company-box-text">{props.company.industry}</div>
          </Col>
          <Col xs={3}>
            <div className="company-box-text">
              {props.company.unfinished_tours}
            </div>
          </Col>
        </Row>
      </div>
    </Link>
  );
};

export default CompanyBox;
