import { useEffect } from "react";
import { Card, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../Loading";

const AviationAircraftContent = (props) => {
  useEffect(() => {}, [props]);
  return (
    <div className="small-box-content">
      {props.loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center box-black">
          <Loading center={true} />
        </div>
      ) : (
        <div className="small-box-content d-flex align-items-center align-content-center box-black  px-3 pt-3 mt-1">
          <span
            className={"material-symbols-outlined me-auto me-3 small-box-icon"}
          >
            flight
          </span>
          <div className="ms-auto me-3">
            <div className="small-box-value ">
              {props.num_aircraft.toString()}
            </div>
            <div className="small-box-text">Aircraft</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AviationAircraftContent;
