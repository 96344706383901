import { useEffect } from "react";
import { Card, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

const AircraftBox = (props) => {
  useEffect(() => {}, [props]);
  return (
    <Link
      className="text-link"
      to={
        props.isAdmin
          ? `aircraft/${props.aircraft.id}`
          : `/aircraft/${props.aircraft.id}`
      }
    >
      <Stack className="grow aircraft-box" direction="horizontal">
        <img
          className="card-image-underline"
          variant="top"
          height="80"
          width="100"
          src={
            props.aircraft.aircraft_image_url
              ? props.aircraft.aircraft_image_url
              : ""
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "https://via.placeholder.com/500x260?text=" +
              props.aircraft.title;
          }}
          style={{
            maxHeight: "260px",
            maxWidth: "700px",
            objectFit: "fit",
            borderRadius: "5px",
          }}
        />
        <div className="text-truncate tour-box-title ms-3">
          {props.aircraft.title}
        </div>
        <div className="tour-box-title ms-auto me-2">
          {props.aircraft.num_of_tours
            ? "Tours: " + props.aircraft.num_of_tours
            : "Tours: 0"}
        </div>
      </Stack>
    </Link>
  );
};

export default AircraftBox;
