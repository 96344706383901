import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditableBox from "../../EditableBox";
import Loading from "../../Loading";
import AviationBillingBox from "../AviationBillingBox";
import AviationDocumentsBox from "../AviationDocumentsBox";

const DocumentsTabContent = (props) => {
  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  useEffect(() => {}, [props]);

  const uploadDocument = () => {
    if (documentName === "" || documentUrl === "") {
      return;
    }
    props.uploadDocument(documentName, documentUrl);
  };

  return (
    <Row>
      <Col md={6}>
        <div className="section-header">Documents</div>
        <div className="section-text">
          Upload the links to any sharepoint documents here.
        </div>
        <hr />
        <div className="subsection-header">Document Name</div>
        <EditableBox
          content={documentName}
          updateVal={setDocumentName}
          edit={true}
        />
        <div className="subsection-header">Document Url</div>
        <EditableBox
          content={documentUrl}
          updateVal={setDocumentUrl}
          edit={true}
        />

        <Button
          variant="primary"
          bg="black"
          className="login-submit-button"
          onClick={() => uploadDocument()}
        >
          Upload Document
        </Button>
      </Col>
      <Col md={6}>
        {props.loading ? (
          <div className="small-box-content d-flex align-items-center align-content-center">
            <Loading center={true} />
          </div>
        ) : Object.keys(props.documents).length === 0 ? (
          <div className="section-header text-center mt-3">
            No documents have been uploaded.
          </div>
        ) : (
          Object.values(props.documents).map((document, i) => {
            return (
              <div key={i}>
                <AviationDocumentsBox
                  document={document}
                  delete={props.deleteDocument}
                />
                {i !== props.documents.length - 1 ? <hr /> : <></>}
              </div>
            );
          })
        )}
      </Col>
    </Row>
  );
};

export default DocumentsTabContent;
