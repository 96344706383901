import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import TourBox from "../../components/TourBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addSpace,
  getClientSpaces,
  getClientTours,
  getSpaceDetails,
  getTourDetails,
  sendSpaceRequestEmail,
  sendTourRequestEmail,
} from "../../firebase";
import SpaceBox from "../../components/SpaceBox";
const ClientFloorplanAdPage = (props) => {
  const [spaces, setSpaces] = useState([]);
  const [hasRequested, setHasRequested] = useState(false);
  const [spaceName, setSpaceName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [numberOfTours, setNumberOfTours] = useState(1);
  const [otherDetails, setOtherDetails] = useState("");
  const [validated, setValidated] = useState(false);

  const user = useContext(UserContext);
  const navigate = useNavigate();
  let { spaceId } = useParams();
  useEffect(() => {
    setContactEmail(user.user.email);
  }, [user.user]);

  const requestFloorplan = async () => {
    if (contactEmail === "") {
      setValidated(true);
    } else {
      setValidated(false);
      const payload = {
        tour_id: props.tour.id,
        contact_email: contactEmail,
        account_email: user.user.email,
        other_details: otherDetails ? otherDetails : "NONE",
      };
      let res = await sendTourRequestEmail(payload);
      if (res) {
        setHasRequested(true);
      }
    }
  };

  const goBack = async () => {
    navigate(-1);
  };

  return (
    <Container className="page-margin d-flex justify-content-center">
      <div className="ad-content">
        <div className="d-flex justify-content-center">
          <img
            className="ad-banner-image"
            src="https://www.vuprop.com/wp-content/uploads/2022/03/Blue-Gray-Classic-Corporate-Business-Promo-LinkedIn-Article-Cover-Image-5.png"
          />
        </div>

        <Row className="ad-content-box-black">
          <Col md={6}>
            <div className="ad-banner">Elevate Your Space</div>
          </Col>
          <Col md={6}>
            <div className="ad-content-text">
              Floor plans are crucial in attracting potential buyers as they
              provide an additional visual representation of the property
              layout. With the help of these floor plans, buyers can make
              informed purchasing decisions more quickly.
            </div>
          </Col>
        </Row>

        <Row className="ad-content-box-white">
          <Col md={6}>
            <div className="ad-content-text">
              Floor plans are marketing ready on delivery and can be customized
              further. Vector files (SVG) are included which are ideal for your
              editing the features of the floor plan quickly while maintaining
              the level of detail.
            </div>
          </Col>
          <Col md={6}>
            <div className="ad-banner">Pricing Estimate: €30</div>
          </Col>
        </Row>

        <Row className="ad-content-box-black">
          <Col md={6}>
            <div className="ad-banner">Boost Sales</div>
            <div className="ad-content-small">
              Maximize your property marketing efforts by utilizing both
              immersive virtual tours and traditional methods like schematic
              floor plans. Buyers, especially new homeowners, rely on floor
              plans to grasp the layout and dimensions of a property. Ideal for
              both residential and commercial properties, schematic floor plans
              are a must-have for your property marketing strategy.
            </div>
          </Col>
          <Col md={6}>
            <video
              className="ad-banner-image"
              src="https://www.vuprop.com/wp-content/uploads/2022/03/website-vid.mp4"
              autoplay=""
              loop="true"
              controls=""
              muted="muted"
              controlslist="nodownload"
            ></video>
          </Col>
        </Row>
        <div className="ad-inquire-box">
          <Button variant="primary" className="login-submit-button full-width">
            Request Now
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ClientFloorplanAdPage;
