import { Button, Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import TourBox from "../../components/TourBox";
import { Link } from "react-router-dom";
import {
  addSpace,
  getClientSpaces,
  getClientTours,
  getSpaceDetails,
  getTourDetails,
} from "../../firebase";
import SpaceBox from "../../components/SpaceBox";
const ClientSpacesPage = () => {
  const [spaces, setSpaces] = useState([]);
  const [updateSpace, setUpdateSpace] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    getClientSpaces(user.user.uid, false).then((res) => {
      Promise.all(
        res.map(async (space) => {
          return await getSpaceDetails(space.space_id);
        })
      ).then((res2) => {
        setSpaces(res2);
      });
    });
  }, [user.user]);

  const addEmptySpace = async () => {
    let space = await addSpace(user.user.uid);
    setSpaces([...spaces, space]);
  };

  const editSpace = async () => {};

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">My Spaces</h1>
      <hr />
      <Row>
        {spaces?.map((space, i) => {
          return (
            <Col key={i} xl={3} lg={4} md={6} sm={6}>
              <SpaceBox space={space} />
            </Col>
          );
        })}
      </Row>
      <Link to="/spaces/request" className="text-link">
        <Button variant="primary" bg="black" className="login-submit-button">
          Request A Space
        </Button>
      </Link>
    </Container>
  );
};

export default ClientSpacesPage;
