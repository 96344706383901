import { Card, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddOnBox = (props) => {
  return (
    <Card
      style={{ marginBottom: 25 }}
      className="grow add-on-card"
      onClick={() => props.click()}
    >
      <Stack direction="horizontal" className="d-flex align-items-center ms-3">
        <div className="d-flex justify-content-center">
          <img src={`/images/${props.iconName}`} className="add-on-icon"></img>
        </div>
        <div className="d-flex align-items-end ms-3">
          <div className="add-on-title ">{props.title}</div>
        </div>
      </Stack>
      <div className="d-flex align-items-end ms-3">
        <div className="add-on-content ">{props.content}</div>
      </div>
    </Card>
  );
};

export default AddOnBox;
