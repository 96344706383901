import { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import StaffBox from "../../../components/admin_components/StaffBox";
import Loading from "../../../components/Loading";
import { getCompanyOperators, getStaffDetails } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

const ManagerStaffPage = () => {
  const [operators, setOperators] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);
  useEffect(() => {
    setLoading(true);
    getCompanyOperators(user.details.company_id).then((res) => {
      Promise.all(
        res.map(async (staff) => {
          return await getStaffDetails(staff.id);
        })
      ).then((res2) => {
        setOperators(res2);
        setLoading(false);
      });
    });
  }, [user.details]);

  const addOperator = () => {
    navigate(`add/operator`);
  };
  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Staff`}</h1>
      <hr />

      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Surname</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {operators.length > 0 ? (
                operators.map((operator, i) => {
                  return (
                    <StaffBox
                      staff={operator}
                      company={user.details.company}
                      index={i + 1}
                      key={i}
                    />
                  );
                })
              ) : (
                <div className="m-3">
                  There are currently no operator accounts
                </div>
              )}
            </tbody>
          </Table>
          <hr />
          <Button
            variant="primary"
            bg="black"
            className={"login-submit-button"}
            onClick={() => addOperator()}
          >
            Add Operator Account
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ManagerStaffPage;
