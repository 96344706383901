import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import CompanyBox from "../../../components/admin_components/CompanyBox";
import { generateHtmlInvoice } from "../../../email_templates/Invoice";
import parse from "html-react-parser";
import {
  getAllCompanies,
  getCompanyDetails,
  getInvoice,
  showHash,
} from "../../../firebase";
import AviationCompanyAircraftPage from "./AviationCompanyAircraftPage";
import AviationCompanyBillingPage from "./AviationCompanyBillingPage";
import AviationCompanyDetailsPage from "./AviationCompanyDetailsPage";
import AviationCompanyEquipmentPage from "./AviationCompanyEquipmentPage";
import AviationCompanyHangarsPage from "./AviationCompanyHangarsPage";
import AviationCompanyStaffPage from "./AviationCompanyStaffPage";
import AviationCompanyToursPage from "./AviationCompanyToursPage";

const AviationInvoicePage = () => {
  let [invoice, setInvoice] = useState({});
  let [html, setHtml] = useState("");
  let { invoiceId } = useParams();
  useEffect(() => {
    getInvoice(invoiceId).then((res) => {
      setInvoice(res);
      setHtml(generateHtmlInvoice(res, false));
    });
  }, [invoiceId]);

  const getHtml = () => {
    if (html !== "") {
      return parse(html);
    }
    return "";
  };
  return <div className="page-margin">{getHtml()}</div>;
};

export default AviationInvoicePage;
