
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./providers/UserProvider";
import Application from "./Application";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <div className="App">
          <Application/>
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
