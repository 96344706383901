import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const StaffBox = (props) => {
  const navigate = useNavigate();

  const goToStaff = () => {
    let link = props.admin
      ? `/aviation/company/${props.company}/staff/${props.staff.id}`
      : `/staff/${props.staff.id}`;
    navigate(link);
  };

  return (
    <tr className="client-box grow mb-3 mt-3" onClick={() => goToStaff()}>
      <td className="staff-box-title">{props.index}</td>
      <td className="text-truncate">{props.staff.first_name}</td>
      <td className="text-truncate">{props.staff.last_name}</td>
      <td className="text-truncate">{props.staff.email}</td>
    </tr>
  );
};

export default StaffBox;
