import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  addTour,
  deleteSpace,
  getClientTours,
  getSpaceDetails,
  getTourDetails,
  updateSpaceDetails,
  updateSpaceTours,
  uploadSpacePicture,
} from "../../firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import EditableBox from "../../components/EditableBox";
import TourBox from "../../components/TourBox";
import SelectableTourBox from "../../components/SelectableTourBox";
const ClientSpaceDetailsPage = () => {
  const [spaceDetails, setSpaceDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [showAllTours, setShowAllTours] = useState(false);
  const [loadingAllTours, setLoadingAllTours] = useState(false);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [eircode, setEircode] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [tours, setTours] = useState([]);
  const [tourIds, setTourIds] = useState({});
  const [allTours, setAllTours] = useState([]);
  const [addTourIndex, setAddTourIndex] = useState([]);
  const [removeTourIndex, setRemoveTourIndex] = useState([]);
  const [updateSpace, setUpdateSpace] = useState(false);

  const [deleteT, setDeleteT] = useState(false);

  const user = useContext(UserContext);
  let { spaceId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getSpaceDetails(spaceId).then((res) => {
      setSpaceDetails(res);
      setTitle(res.title);
      setAddress(res.address);
      setAddress2(res.address_2);
      setCity(res.city);
      setCounty(res.county);
      setEircode(res.eircode);
      setImageUrl(res.space_image_url);
      if (res.tours) {
        let tempTourIds = {};
        let tempRemoveIndex = [];
        Promise.all(
          res.tours.map(async (tour) => {
            tempTourIds[tour] = true;
            tempRemoveIndex.push(true);
            return await getTourDetails("", tour);
          })
        ).then((res2) => {
          setTours(res2);
          setTourIds(tempTourIds);
          setRemoveTourIndex(tempRemoveIndex);
        });
      }
    });
  }, [spaceId, user.user, updateSpace]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let space = {
        ...spaceDetails,
        title: title,
        address: address,
        address_2: address2,
        city: city,
        county: county,
        eircode: eircode,
      };
      await updateSpaceDetails(space);
    }
    setEdit(!edit);
  };

  const getMyTours = async () => {
    await getClientTours(user.user.uid, false).then((res) => {
      let tempArr = [];
      Promise.all(
        res.map(async (tour) => {
          if (tour.tour_id in tourIds) {
            return {};
          }
          tempArr.push(false);
          return await getTourDetails("", tour.tour_id);
        })
      ).then((res2) => {
        setAddTourIndex(tempArr);
        setAllTours(
          res2.filter((e) => {
            if (Object.keys(e).length !== 0) {
              return true;
            }
            return false;
          })
        );
      });
    });
  };

  const addTourClicked = async () => {
    if (showAllTours) {
      await changeSpaceTours();
      setShowAllTours(false);
    } else {
      setShowAllTours(true);
      setLoadingAllTours(true);
      await getMyTours();
      setLoadingAllTours(false);
    }
  };

  const changeAddIndex = (i) => {
    let temp = [...addTourIndex];
    temp[i] = !temp[i];
    setAddTourIndex(temp);
  };

  const changeRemoveIndex = (i) => {
    let temp = [...removeTourIndex];
    temp[i] = !temp[i];
    setRemoveTourIndex(temp);
  };

  const changeSpaceTours = async () => {
    let newTours = [];
    removeTourIndex.map((val, i) => {
      if (val) {
        newTours.push(tours[i].id);
      }
    });
    addTourIndex.map((val, i) => {
      if (val) {
        newTours.push(allTours[i].id);
      }
    });
    console.log(newTours);
    await updateSpaceTours(spaceDetails.id, newTours);
    setUpdateSpace(!updateSpace);
  };

  const toggleDelete = async () => {
    if (deleteT) {
      await deleteSpace(spaceId, user.user.uid);
      navigate("/spaces");
    } else {
      setDeleteT(true);
    }
  };

  const changeSpaceImage = async (e) => {
    const file = e.target.files[0];
    let url = await uploadSpacePicture(spaceId, file);
    console.log(url);
    setImageUrl(url);
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{title}</h1>
      <Tabs
        defaultActiveKey="tours"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="tours" title="Tours">
          <div className="section-header">Tours</div>
          <hr />
          <Row>
            {tours.map((tour, i) => {
              return (
                <Col key={i} xl={3} lg={4} md={6} sm={6}>
                  {showAllTours ? (
                    <SelectableTourBox
                      tour={tour}
                      selected={removeTourIndex[i]}
                      clickFunction={changeRemoveIndex}
                      index={i}
                    />
                  ) : (
                    <TourBox tour={tour} />
                  )}
                </Col>
              );
            })}
          </Row>
          <hr />
          <Link to={`/spaces/${spaceId}/request-tour`} className="text-link">
            <Button
              variant="primary"
              bg="black"
              className={
                showAllTours ? "delete-button" : "login-submit-button "
              }
            >
              Request More Tours
            </Button>
          </Link>

          {/* {showAllTours ? (
            <div>
              <Row className="mt-3">
                {loadingAllTours ? (
                  <Col className="d-flex justify-content-center align-items-center text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner-colour"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                ) : (
                  <>
                    {allTours.map((tour, i) => {
                      return (
                        <Col key={i} xl={3} lg={4} md={6} sm={6}>
                          <SelectableTourBox
                            tour={tour}
                            selected={addTourIndex[i]}
                            clickFunction={changeAddIndex}
                            index={i}
                          />
                        </Col>
                      );
                    })}
                  </>
                )}
              </Row>
            </div>
          ) : (
            <></>
          )} */}
        </Tab>
        <Tab eventKey="details" title="Details">
          <Row className="">
            <Col md={6} xs={12} className="">
              <div className="section-header">Details</div>
              <div className="subsection-header">Title</div>
              <EditableBox content={title} updateVal={setTitle} edit={edit} />
              <hr className="d-block d-sm-none" />
            </Col>
            <Col md={6} xs={12}>
              <div className="section-header">Image</div>
              <label htmlFor="space_image_upload" className="file-upload">
                <img
                  className="space-image"
                  src={imageUrl}
                  width="100%"
                  height="350px"
                ></img>
                <div className="file-upload-content">
                  <span className="material-symbols-outlined file-upload-icon">
                    upload_file
                  </span>
                </div>
              </label>
              <input
                type="file"
                onChange={(e) => changeSpaceImage(e)}
                accept="image/*"
                id="space_image_upload"
                style={{ display: "none" }}
              />
            </Col>
          </Row>

          <hr />

          <div className="section-header">Address</div>
          <Row>
            <Col md={6}>
              <div className="subsection-header">Address</div>
              <EditableBox
                content={address}
                updateVal={setAddress}
                edit={edit}
              />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Address 2</div>
              <EditableBox
                content={address2}
                updateVal={setAddress2}
                edit={edit}
              />
            </Col>
            <Col md={4}>
              <div className="subsection-header">City</div>
              <EditableBox content={city} updateVal={setCity} edit={edit} />
            </Col>
            <Col md={4}>
              <div className="subsection-header">County</div>
              <EditableBox content={county} updateVal={setCounty} edit={edit} />
            </Col>
            <Col md={4}>
              <div className="subsection-header">Eircode</div>
              <EditableBox
                content={eircode}
                updateVal={setEircode}
                edit={edit}
              />
            </Col>
          </Row>

          <Stack direction="horizontal">
            <Button
              variant="primary"
              bg="black"
              className="login-submit-button"
              onClick={() => toggleEdit()}
            >
              {edit ? "Confirm" : "Edit Details"}
            </Button>
            {/* <Button
              variant="primary"
              bg="black"
              className="delete-button ms-auto"
              onClick={() => toggleDelete()}
            >
              {deleteT ? "Are You Sure?" : "Delete Space"}
            </Button> */}
          </Stack>
        </Tab>
        <Tab eventKey="stats" title="Stats">
          <div className="section-header">Stats</div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ClientSpaceDetailsPage;
