import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { addTour } from "../firebase";
import { v4 as uuidv4 } from "uuid";

const AddTourPage = () => {
  const [title, setTitle] = useState("");
  const [matterportId, setMatterportId] = useState("");
  const [creationDate, setCreationDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [eircode, setEircode] = useState("");
  const [company, setCompany] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  const navigate = useNavigate();
  const confirmAddClient = async (e) => {
    // Important Details
    if (title === "" || matterportId === "" || email === "" || company === "") {
      return;
    }

    let id = uuidv4().toString();

    let tour = {
      id: id,
      title: title,
      matterport_id: matterportId,
      creation_date: creationDate,
      client_email: email,
      address: address,
      address_2: address2,
      city: city,
      county: county,
      eircode: eircode,
      company: company,
      other_details: otherDetails,
    };

    let res = await addTour(tour);
    if (res) {
      navigate("/tours");
    }
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">Add Tour</h1>
      <hr />
      <Form>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title*</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setTitle(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Matterport ID*</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setMatterportId(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicMobile">
              <Form.Label>Creation Date*</Form.Label>
              <Form.Control
                aria-label="Mobile Number"
                type="date"
                aria-describedby="basic-addon1"
                className="form-control"
                name="mobile"
                onChange={(event) => setCreationDate(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Client Email address*</Form.Label>
              <Form.Control
                type="email"
                aria-describedby="emailHelpBlock"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setAddress(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setAddress2(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setCity(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={4} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setCounty(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={4} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Eircode</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setEircode(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Company*</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="firstNameHelpBlock"
                onChange={(event) => setCompany(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group className="mb-3" controlId="formGridlabel">
              <Form.Label>Other Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="form-control"
                onChange={(event) => setOtherDetails(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          bg="black"
          className="login-submit-button"
          onClick={(e) => confirmAddClient(e)}
        >
          Add Tour
        </Button>
      </Form>
    </Container>
  );
};

export default AddTourPage;
