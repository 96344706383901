import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerHomePage from "../aviation_pages/manager_pages/ManagerHomePage";
import ManagerStaffPage from "./manager_pages/ManagerStaffPage";
import ManagerToursPage from "./manager_pages/ManagerToursPage";
import OperatorToursPage from "./operator_pages/OperatorToursPage";

const AviationStaffPage = (props) => {
  const user = useContext(UserContext);
  const getStaffPage = () => {
    if (user.details.access === "manager") {
      return <ManagerStaffPage />;
    } else if (user.details.access === "operator") {
      return "";
    }
  };
  return getStaffPage();
};

export default AviationStaffPage;
