import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerAccountPage from "./manager_pages/ManagerAccountPage";
import ManagerAircraftDetailsPage from "./manager_pages/ManagerAircraftDetailsPage";
import ManagerAircraftListPage from "./manager_pages/ManagerAircraftListPage";
import OperatorAccountPage from "./operator_pages/OperatorAccountPage";
import OperatorAircraftDetailsPage from "./operator_pages/OperatorAircraftDetailsPage";
import OperatorAircraftListPage from "./operator_pages/OperatorAircraftListPage";

const AviationAccountPage = (props) => {
  const user = useContext(UserContext);
  const getAccountPage = () => {
    if (user.details.access === "manager") {
      return <ManagerAccountPage />;
    } else if (user.details.access === "operator") {
      return <OperatorAccountPage />;
    }
  };
  return getAccountPage();
};

export default AviationAccountPage;
