import { Button, Container, Stack } from "react-bootstrap";

import { auth, getCompanyBilling, resetUserPassword } from "../../../firebase";
import { signOut } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import Loading from "../../../components/Loading";
import AviationBillingBox from "../../../components/aviation_components/AviationBillingBox";
const ManagerAccountPage = () => {
  const user = useContext(UserContext);
  const [resetText, setResetText] = useState("");
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {}, [user]);

  const logout = (e) => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const resetPassword = async () => {
    if (await resetUserPassword(user.user.email)) {
      setResetText("An email has been sent with further instructions.");
    } else {
      setResetText("Something went wrong please try again.");
    }
  };
  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">My Account</h1>
      <hr />
      <div className="section-header">Login Credentials</div>
      <div>
        <b>E-mail Address</b>
      </div>
      <div>{user.user.email}</div>
      <div>
        <b>Password</b>
      </div>
      <div>********</div>
      <br />
      <Button
        variant="primary"
        bg="black"
        className="delete-button ms-auto"
        onClick={() => resetPassword()}
      >
        Reset Password
      </Button>
      <div className="mt-2">{resetText}</div>
      <hr />
      <Button
        variant="primary"
        onClick={() => logout()}
        bg="black"
        className="login-submit-button"
      >
        Logout
      </Button>
    </Container>
  );
};

export default ManagerAccountPage;
