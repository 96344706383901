import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import EditableBox from "../../components/EditableBox";
import EditableDropdown from "../../components/EditableDropdown";
import { addCompany, showHash } from "../../firebase";

const AddCompanyPage = () => {
  const [name, setName] = useState("");
  const [industry, setIndustry] = useState("Aviation");

  const navigate = useNavigate();
  const confirmAddCompany = async (e) => {
    // Important Details
    if (name === "" || industry === "") {
      return;
    }

    let client = {
      name: name,
      unfinished_tours: 0,
      id: showHash(name).toString(),
      industry: industry,
    };

    let res = await addCompany(client);
    if (res) {
      navigate(-1);
    }
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">New Company</h1>
      <hr />
      <Row>
        <Col lg={6} sm={12}>
          <div className="subsection-header">Name*</div>
          <EditableBox content={name} updateVal={setName} edit={true} />
        </Col>

        <Col lg={6} sm={12}>
          <div className="subsection-header">Industry*</div>
          <EditableDropdown
            edit={true}
            updateVal={setIndustry}
            selected={industry}
            values={["Aviation", "Hospitality"]}
          />
        </Col>
      </Row>
      <Button
        variant="primary"
        bg="black"
        className="login-submit-button"
        onClick={(e) => confirmAddCompany(e)}
      >
        Add Company
      </Button>
    </Container>
  );
};

export default AddCompanyPage;
