import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

import { addTour, getTourDetails } from "../../firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import EditableBox from "../../components/EditableBox";
import AddOnBox from "../../components/AddOnBox";
import ClientFloorplanAdPage from "./ClientFloorplanAdPage";
import ClientBrandedAdPage from "./ClientBrandedAdPage";
const ClientTourDetailsPage = () => {
  const [tourDetails, setTourDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [eircode, setEircode] = useState("");
  const [company, setCompany] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [copyTourClicked, setCopyTourClicked] = useState(false);
  const [copyEmbedClicked, setCopyEmbedClicked] = useState(false);
  const [addOnIndex, setAddOnIndex] = useState(0);

  const user = useContext(UserContext);
  let { tourId } = useParams();
  useEffect(() => {
    getTourDetails(user.user.uid, tourId).then((res) => {
      setTourDetails(res);
      setTitle(res.title);
      setAddress(res.address);
      setAddress2(res.address_2);
      setCity(res.city);
      setCounty(res.county);
      setEircode(res.eircode);
      setCompany(res.company);
      setOtherDetails(res.other_details);
    });
  }, [tourId, user.user]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let tour = {
        ...tourDetails,
        title: title,
        address: address,
        address_2: address2,
        city: city,
        county: county,
        eircode: eircode,
        company: company,
        other_details: otherDetails,
      };
      await addTour(tour);
    }
    setEdit(!edit);
  };

  const embedText = `<iframe 
    width='853' 
    height='480' 
    src='https://my.matterport.com/show/?m=${tourDetails.matterport_id}' 
    frameborder='0' 
    allowfullscreen allow='xr-spatial-tracking'>
</iframe>
  `;

  const floorplanText = `Professional black and white schematic floor plans for marketing residential spaces and commercial offices.`;

  const brandingText = `Insert your Logo into the tour and include a link directing visitors to your website.`;

  const copyLinkToClipboard = () => {
    setCopyTourClicked(true);
    const link = `https://my.matterport.com/show/?m=${tourDetails.matterport_id}`;
    navigator.clipboard.writeText(link);
  };

  const copyEmbedToClipboard = () => {
    setCopyEmbedClicked(true);
    navigator.clipboard.writeText(embedText);
  };

  const addOnViews = [
    <Row>
      <Col xl={3} lg={4} md={6} sm={6}>
        <AddOnBox
          iconName={"floorplan.png"}
          title="Floor Plan"
          content={floorplanText}
          click={() => setAddOnIndex(1)}
        />
      </Col>
      <Col xl={3} lg={4} md={6} sm={6}>
        <AddOnBox
          iconName={"branding.png"}
          title="Tour Branding"
          content={brandingText}
          click={() => setAddOnIndex(2)}
        />
      </Col>
    </Row>,
    <ClientFloorplanAdPage />,
    <ClientBrandedAdPage />,
  ];

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{title}</h1>
      <Tabs
        defaultActiveKey="space"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="space" title="Tour">
          <div className="section-header">Tour</div>
          <iframe
            title="tour_space"
            width="100%"
            height="500"
            src={`https://my.matterport.com/show/?m=${tourDetails.matterport_id}`}
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
          <hr />
          <div className="section-header">Share</div>
          <Form.Group>
            <Form.Label className="subsection-header">Tour Link</Form.Label>
            <InputGroup>
              <InputGroup.Text
                title="Copy"
                className="grow"
                onClick={() => copyLinkToClipboard()}
              >
                {copyTourClicked ? (
                  <span class="material-symbols-outlined icon-clicked">
                    content_copy
                  </span>
                ) : (
                  <span class="material-symbols-outlined icon-plain">
                    content_copy
                  </span>
                )}
              </InputGroup.Text>
              <Form.Control
                name="tourLink"
                placeholder={`https://my.matterport.com/show/?m=${tourDetails.matterport_id}`}
                disabled
              />
            </InputGroup>
          </Form.Group>

          <Form.Group>
            <Form.Label className="subsection-header">Embed Link</Form.Label>
            <InputGroup>
              <InputGroup.Text
                title="Copy"
                className="grow"
                onClick={() => copyEmbedToClipboard()}
              >
                {copyEmbedClicked ? (
                  <span class="material-symbols-outlined icon-clicked">
                    content_copy
                  </span>
                ) : (
                  <span class="material-symbols-outlined icon-plain">
                    content_copy
                  </span>
                )}
              </InputGroup.Text>
              <Form.Control
                name="embedLink"
                as="textarea"
                rows={7}
                placeholder={embedText}
                disabled
              />
            </InputGroup>
          </Form.Group>
          <br />
        </Tab>
        <Tab eventKey="details" title="Details" className="tab-bar">
          <div className="section-header">Details</div>
          <div className="subsection-header">Title</div>
          <EditableBox content={title} updateVal={setTitle} edit={edit} />
          <div className="subsection-header">Creation Date</div>
          <div className="section-text">{tourDetails.creation_date}</div>
          <div className="subsection-header">Other Details</div>
          <EditableBox
            content={otherDetails}
            updateVal={setOtherDetails}
            edit={edit}
            as="textarea"
            rows={3}
          />
          <hr />

          <div className="section-header">Address</div>
          <Row>
            <Col md={6}>
              <div className="subsection-header">Address</div>
              <EditableBox
                content={address}
                updateVal={setAddress}
                edit={edit}
              />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Address 2</div>
              <EditableBox
                content={address2}
                updateVal={setAddress2}
                edit={edit}
              />
            </Col>
            <Col md={4}>
              <div className="subsection-header">City</div>
              <EditableBox content={city} updateVal={setCity} edit={edit} />
            </Col>
            <Col md={4}>
              <div className="subsection-header">County</div>
              <EditableBox content={county} updateVal={setCounty} edit={edit} />
            </Col>
            <Col md={4}>
              <div className="subsection-header">Eircode</div>
              <EditableBox
                content={eircode}
                updateVal={setEircode}
                edit={edit}
              />
            </Col>
          </Row>

          <Stack direction="horizontal">
            <Button
              variant="primary"
              bg="black"
              className="login-submit-button"
              onClick={() => toggleEdit()}
            >
              {edit ? "Confirm" : "Edit Tour"}
            </Button>
          </Stack>
        </Tab>
        <Tab eventKey="stats" title="Stats">
          <div className="section-header">Stats</div>
          <div className="subsection-header">Impressions</div>
          <EditableBox
            content={tourDetails.impressions ? tourDetails.impressions : 0}
            edit={false}
          />
          <div className="subsection-header">Unique Visitors</div>
          <EditableBox
            content={tourDetails.visitors ? tourDetails.visitors : 0}
            edit={false}
          />
        </Tab>
        <Tab eventKey="add-ons" title="Add-Ons">
          {addOnIndex === 0 ? (
            <div className="section-header">Add-Ons</div>
          ) : (
            <div
              className="section-header grow"
              onClick={() => setAddOnIndex(0)}
            >
              <u>{"< All Add-Ons"}</u>
            </div>
          )}

          {addOnViews[addOnIndex]}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ClientTourDetailsPage;
