import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CompanyBox from "../../../components/admin_components/CompanyBox";
import StaffBox from "../../../components/admin_components/StaffBox";
import {
  getAllCompanies,
  getCompanyDetails,
  getCompanyManagers,
  getCompanyOperators,
  getStaffDetails,
} from "../../../firebase";

const AviationCompanyStaffPage = (props) => {
  const [managers, setManagers] = useState([]);
  const [operators, setOperators] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getCompanyOperators(props.id).then((res) => {
      Promise.all(
        res.map(async (staff) => {
          return await getStaffDetails(staff.id);
        })
      ).then((res2) => {
        setOperators(res2);
      });
    });
    getCompanyManagers(props.id).then((res) => {
      Promise.all(
        res.map(async (staff) => {
          return await getStaffDetails(staff.id);
        })
      ).then((res2) => {
        setManagers(res2);
      });
    });
  }, [props.id]);

  const addManager = () => {
    navigate(`add/manager`);
  };

  const addOperator = () => {
    navigate(`add/operator`);
  };
  return (
    <Container className="page-margin">
      <h1 className="page-title">{`Staff`}</h1>
      <hr />
      <div className="subsection-header">Managers</div>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Surname</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {managers.length > 0 ? (
              managers.map((manager, i) => {
                return (
                  <StaffBox
                    staff={manager}
                    company={props.company_name}
                    index={i + 1}
                    key={i}
                  />
                );
              })
            ) : (
              <div className="m-3">There are currently no manager accounts</div>
            )}
          </tbody>
        </Table>
        <Button
          variant="primary"
          bg="black"
          className={"login-submit-button"}
          onClick={() => addManager()}
        >
          Add Manager Account
        </Button>
      </div>
      <hr />
      <div className="subsection-header">Operators</div>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Surname</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {operators.length > 0 ? (
              operators.map((operator, i) => {
                return (
                  <StaffBox
                    staff={operator}
                    company={props.company_name}
                    index={i + 1}
                    key={i}
                  />
                );
              })
            ) : (
              <div className="m-3">
                There are currently no operator accounts
              </div>
            )}
          </tbody>
        </Table>
        <Button
          variant="primary"
          bg="black"
          className={"login-submit-button"}
          onClick={() => addOperator()}
        >
          Add Operator Account
        </Button>
      </div>
    </Container>
  );
};

export default AviationCompanyStaffPage;
