import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import {
  addClient,
  getClientDetails,
  deleteClient,
  getClientTours,
  getTourDetails,
  createClientAccount,
  getSpaceDetails,
  getClientSpaces,
  addSpace,
  deleteStaff,
  getStaffTours,
  createStaffAccount,
} from "../../../firebase";
import { useEffect, useState } from "react";
import EditableBox from "../../../components/EditableBox";
import AviationTourBox from "../../../components/aviation_components/AviationTourBox";
import { addStaffToCompany, getStaffDetails } from "../../../firebase";
import EditableDropdown from "../../../components/EditableDropdown";
import Loading from "../../../components/Loading";
const AviationCompanyStaffDetailsPage = () => {
  const [clientDetails, setClientDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [staffTours, setStaffTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { companyId, staffId } = useParams();

  const [deleteT, setDeleteT] = useState(false);
  useEffect(() => {
    getStaffDetails(staffId).then((res) => {
      setClientDetails(res);
      setFirstName(res.first_name);
      setLastName(res.last_name);
      setEmail(res.email);
      setMobile(res.mobile);
      setAccess(res.access);
      setHasAccount(res.has_account);
    });
    getStaffTours(staffId, false).then((res) => {
      Promise.all(
        res.map(async (tour) => {
          return await getTourDetails("", tour.tour_id);
        })
      ).then((res2) => {
        setStaffTours(res2);
        setLoading(false);
      });
    });
  }, [staffId]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let client = {
        ...clientDetails,
        first_name: firstName,
        last_name: lastName,
        mobile: mobile,
        email: email,
        access: access,
      };
      await addStaffToCompany(companyId, client, clientDetails.access, true);
    }
    setEdit(!edit);
  };

  const toggleDelete = async () => {
    if (deleteT) {
      await deleteStaff(clientDetails);
      navigate(-1);
    } else {
      setDeleteT(true);
    }
  };

  const createAccount = async () => {
    let res = await createStaffAccount(email);
    setHasAccount(res);
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{email}</h1>
      <Tabs
        defaultActiveKey="details"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <div className="section-header">Staff Details</div>
          <Row>
            <Col xs={6}>
              <div className="subsection-header">Firstname</div>
              <EditableBox
                content={firstName}
                updateVal={setFirstName}
                edit={edit}
              />
            </Col>
            <Col xs={6}>
              <div className="subsection-header">Surname</div>
              <EditableBox
                content={lastName}
                updateVal={setLastName}
                edit={edit}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <div className="subsection-header">Email</div>
              <EditableBox content={email} updateVal={setEmail} edit={false} />
            </Col>
            <Col xs={6}>
              <div className="subsection-header">Mobile</div>
              <EditableBox content={mobile} updateVal={setMobile} edit={edit} />
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <div className="subsection-header">Account Type</div>
              <EditableDropdown
                edit={edit}
                updateVal={setAccess}
                selected={access}
                values={["manager", "operator"]}
              />
            </Col>
          </Row>
          <hr />
          <Stack direction="horizontal">
            <Button
              variant="primary"
              bg="black"
              className="login-submit-button"
              onClick={() => toggleEdit()}
            >
              {edit ? "Confirm" : "Edit Client"}
            </Button>
            <Button
              variant="primary"
              bg="black"
              className="delete-button ms-auto"
              onClick={() => toggleDelete()}
            >
              {deleteT ? "Are You Sure?" : "Delete Client"}
            </Button>
          </Stack>
          <hr />
          <div className="section-header">Staff Account</div>
          {hasAccount ? (
            <div>
              <div className="section-text">
                This staff member does have an account.
              </div>
            </div>
          ) : (
            <div>
              <div className="section-text">
                This staff member does <b>NOT</b> have an account.
              </div>
              <Button
                variant="primary"
                bg="black"
                className="login-submit-button ms-auto"
                onClick={() => createAccount()}
              >
                Create Staff Account
              </Button>
            </div>
          )}
        </Tab>
        <Tab eventKey="tours" title="Tours">
          <h1 className="page-title">{`Tours`}</h1>
          <hr className="mt-0" />
          {loading ? (
            <div className="small-box-content d-flex align-items-center align-content-center">
              <Loading center={true} />
            </div>
          ) : staffTours.length > 0 ? (
            <Row>
              {staffTours.map((tour, i) => {
                return (
                  <div key={i}>
                    <AviationTourBox
                      tour={tour}
                      industry={"aviation"}
                      key={i}
                    />
                    <hr />
                  </div>
                );
              })}
            </Row>
          ) : (
            <div className="my-3">This account has not created any tours.</div>
          )}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AviationCompanyStaffDetailsPage;
