import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import AviationHangarBox from "../../../components/aviation_components/AviationHangarBox";
import Loading from "../../../components/Loading";
import { getCompanyHangars, getHangarDetails } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

const ManagerHangarListPage = (props) => {
  const [hangars, setHangars] = useState([]);
  const [hangarIds, setHangarIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  useEffect(() => {
    setLoading(true);
    getCompanyHangars(user.details.company_id).then((res) => {
      setHangarIds(res);
      Promise.all(
        res.map(async (hangar) => {
          return await getHangarDetails(hangar.id);
        })
      ).then((res2) => {
        setHangars(res2);
        setLoading(false);
      });
    });
  }, [user.details]);

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Hangars`}</h1>
      <hr />
      {loading ? (
        <Loading />
      ) : (
        <>
          {hangars.length > 0 ? (
            <Row>
              {hangars.map((hangar, i) => {
                return (
                  <Col key={i} xl={3} lg={4} md={6} sm={6}>
                    <AviationHangarBox hangar={hangar} isAdmin={false} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="my-3">
              Order your first camera to start making virtual tours at one of
              your hangars.
            </div>
          )}
          <hr />
          <Button
            variant="primary"
            bg="black"
            className={"login-submit-button mt-3"}
          >
            Setup Hangar for Virtual Tours
          </Button>
        </>
      )}
    </Container>
  );
};

export default ManagerHangarListPage;
