import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerAircraftDetailsPage from "./manager_pages/ManagerAircraftDetailsPage";
import ManagerAircraftListPage from "./manager_pages/ManagerAircraftListPage";
import OperatorAircraftDetailsPage from "./operator_pages/OperatorAircraftDetailsPage";
import OperatorAircraftListPage from "./operator_pages/OperatorAircraftListPage";

const AviationAircraftDetailsPage = (props) => {
  const user = useContext(UserContext);
  const getAircraftPage = () => {
    if (user.details.access === "manager") {
      return <ManagerAircraftDetailsPage />;
    } else if (user.details.access === "operator") {
      return <OperatorAircraftDetailsPage />;
    }
  };
  return getAircraftPage();
};

export default AviationAircraftDetailsPage;
