import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerAircraftListPage from "./manager_pages/ManagerAircraftListPage";
import ManagerBillingPage from "./manager_pages/ManagerBillingPage";
import OperatorAircraftListPage from "./operator_pages/OperatorAircraftListPage";

const AviationBillingPage = () => {
  const user = useContext(UserContext);
  const getBilling = () => {
    if (user.details.access === "manager") {
      return <ManagerBillingPage />;
    } else if (user.details.access === "operator") {
      return <div />;
    }
  };
  return getBilling();
};

export default AviationBillingPage;
