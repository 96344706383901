// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { v4 as uuidv4 } from "uuid";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { TourCompleteEmail } from "./email_templates/TourCompleteEmail";

export const cyrb53 = (str, seed = 0) => {
  let str2 = str.toLowerCase();
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str2.length; i++) {
    ch = str2.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCOhWgSt3Y3x-zgwP3S48sBtnK223VzesM",

  authDomain: "vuprop-a0b9f.firebaseapp.com",

  projectId: "vuprop-a0b9f",

  storageBucket: "vuprop-a0b9f.appspot.com",

  messagingSenderId: "84934060029",

  appId: "1:84934060029:web:1bd2790d40f1d6946fdb94",

  measurementId: "G-R03BPJYN25",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const firestoreDb = getFirestore(app);

const functions = getFunctions();

const storage = getStorage();

export const auth = getAuth(app);

// Tour Functions

export async function getTours(userId, limit = -1) {
  if (limit <= 0) {
    var query = collection(firestoreDb, `tours`);
  } else {
    query = query(collection(firestoreDb, `tours`), limit(limit));
  }

  const querySnapshot = await getDocs(query);
  let tours = querySnapshot.docs.map((doc) => {
    return doc.data();
  });
  return tours;
}

export async function getTourDetails(userId, tourId) {
  let query = doc(firestoreDb, `tours`, `${tourId}`);
  // TODO add check for tours created by someone else
  let tour = await getDoc(query);
  if (tour.exists()) {
    return tour.data();
  }
  return {};
}

// export async function updateTourStats(userId, tourId) {
//   const url = `https://my.matterport.com/api/v2/models/${tourId}/statistics`;
//   console.log(url)
//   let res = await fetch(url, header('Access-Control-Allow-Headers: X-Requested-With, privatekey'))
//   let data = await res.json();
//   console.log(data);
// }

export async function addClient(client) {
  try {
    let hash = cyrb53(client.email).toString();
    let clientId = {
      ...client,
      id: hash,
    };
    let query = doc(firestoreDb, `clients`, hash);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, clientId);
    return hash;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getClients(filter = "") {
  try {
    let query = collection(firestoreDb, `clients`);
    // TODO add check for tours created by someone else
    const querySnapshot = await getDocs(query);
    let clients = querySnapshot.docs.map((doc) => {
      return doc.data();
    });
    return clients;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function addTour(tour) {
  try {
    let query = doc(firestoreDb, `tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, tour);
    await assignTourToClient(tour, tour.client_email);
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function deleteTour(tour) {
  try {
    let query = doc(firestoreDb, `tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await deleteDoc(query, tour);
    // await deleteTourPhotos(tour.id);
    await removeTourFromClient(tour, tour.client_email);
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getClientDetails(clientId) {
  let query = doc(firestoreDb, `clients`, `${clientId}`);
  let client = await getDoc(query);
  if (client.exists()) {
    return client.data();
  }
  return {};
}

export async function deleteClient(client) {
  try {
    let query = doc(firestoreDb, `clients`, client.id);
    let docRef = await deleteDoc(query, client);
    return client.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function assignTourToClient(tour, clientEmail) {
  try {
    let hash = cyrb53(clientEmail).toString();
    let query = doc(firestoreDb, `clients/${hash}/tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, { tour_id: tour.id });
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function removeTourFromClient(tour, clientEmail) {
  try {
    let hash = cyrb53(clientEmail).toString();
    let query = doc(firestoreDb, `clients/${hash}/tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await deleteDoc(query, { tour_id: tour.id });
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getClientTours(clientEmail, doHash = true, limit = -1) {
  try {
    let hash = clientEmail;
    if (doHash) {
      hash = cyrb53(clientEmail.email).toString();
    }

    if (limit <= 0) {
      var query = collection(firestoreDb, `clients/${hash}/tours`);
    } else {
      query = query(
        collection(firestoreDb, `clients/${hash}/tours`),
        limit(limit)
      );
    }
    const querySnapshot = await getDocs(query);
    let tours = querySnapshot.docs.map((doc) => {
      return doc.data();
    });
    return tours;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function createClientAccount(clientEmail) {
  try {
    let hash = cyrb53(clientEmail).toString();
    const createClientCallable = httpsCallable(
      functions,
      "createClientAccount"
    );
    let response = await createClientCallable({
      client_email: clientEmail,
      id: hash,
    });
    if (response.data.status === "success") {
      let query = doc(firestoreDb, `clients/${hash}`);
      // TODO add check for tours created by someone else
      let docRef = await updateDoc(query, { has_account: true });
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getUserType(userId) {
  try {
    let query = doc(firestoreDb, `staff/`, userId);
    // TODO add check for tours created by someone else
    let docSnap = await getDoc(query);
    if (docSnap.exists()) {
      return docSnap.data().type;
    }
    return "admin";
  } catch (e) {
    console.log(e);
    return "admin";
  }
}

export async function getUserIndustry(userId) {
  try {
    let query = doc(firestoreDb, `clients/`, userId);
    // TODO add check for tours created by someone else
    let docSnap = await getDoc(query);
    if (docSnap.exists()) {
      return docSnap.data().industry;
    }
    return "";
  } catch (e) {
    console.log(e);
    return "";
  }
}

export async function addSpace(userId, title = "untitled") {
  try {
    let space = {
      title: title,
    };
    let query = collection(firestoreDb, `spaces`);
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, space);
    let id = docRef.id;
    await assignSpaceToUser(userId, id);
    let query2 = doc(firestoreDb, `spaces/`, id);
    await updateDoc(query2, { id: id });
    space = {
      ...space,
      id: id,
    };
    return space;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function assignSpaceToUser(userId, spaceId) {
  try {
    let query = doc(firestoreDb, `clients/${userId}/spaces`, spaceId);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, { space_id: spaceId });
    return spaceId;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getClientSpaces(clientEmail, doHash = true, limit = -1) {
  try {
    let hash = clientEmail;
    if (doHash) {
      hash = cyrb53(clientEmail.email).toString();
    }

    if (limit <= 0) {
      var query = collection(firestoreDb, `clients/${hash}/spaces`);
    } else {
      query = query(
        collection(firestoreDb, `clients/${hash}/spaces`),
        limit(limit)
      );
    }
    const querySnapshot = await getDocs(query);
    let tours = querySnapshot.docs.map((doc) => {
      return doc.data();
    });
    return tours;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getSpaceDetails(spaceId) {
  let query = doc(firestoreDb, `spaces`, `${spaceId}`);
  // TODO add check for tours created by someone else
  let space = await getDoc(query);
  if (space.exists()) {
    return space.data();
  }
  return {};
}

export async function updateSpaceTours(spaceId, tours) {
  try {
    let query = doc(firestoreDb, `spaces`, spaceId);
    console.log();
    // TODO add check for tours created by someone else
    let docRef = await updateDoc(query, { tours: tours });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function addTourToSpace(spaceId, tourId) {
  try {
    let query = doc(firestoreDb, `spaces/${spaceId}/tours`, tourId);
    console.log();
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, { id: tourId });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function removeTourFromSpace(spaceId, tourId) {
  try {
    let query = doc(firestoreDb, `spaces/${spaceId}/tours`, tourId);
    let docRef = await deleteDoc(query);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getSpaceTours(spaceId) {
  try {
    let query = collection(firestoreDb, `spaces/${spaceId}/tours`);
    return await getDocs(query).then((snap) => {
      return snap.docs.map((val) => {
        return val.data().id;
      });
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function updateSpaceDetails(space) {
  try {
    let query = doc(firestoreDb, `spaces`, space.id);
    // TODO add check for tours created by someone else
    let docRef = await updateDoc(query, space);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function deleteSpace(spaceId, clientId) {
  try {
    let query = doc(firestoreDb, `clients/${clientId}/spaces`, spaceId);
    let docRef = await deleteDoc(query);
    query = doc(firestoreDb, `spaces/`, spaceId);
    docRef = await deleteDoc(query);
    const spaceImageRef = ref(storage, `space_images/${spaceId}.jpg`);
    await deleteObject(spaceImageRef);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadSpacePicture(spaceId, blob) {
  try {
    const spaceImageRef = ref(storage, `space_images/${spaceId}.jpg`);
    let url = await uploadBytes(spaceImageRef, blob).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then(async (url) => {
        let query = doc(firestoreDb, `spaces`, spaceId);
        // TODO add check for tours created by someone else
        let docRef = await updateDoc(query, { space_image_url: url });
        return url;
      });
    });
    return url;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function sendSpaceRequestEmail(payload) {
  try {
    const createClientCallable = httpsCallable(functions, "requestSpace");
    let response = await createClientCallable(payload);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function sendTourRequestEmail(payload) {
  try {
    const createClientCallable = httpsCallable(functions, "requestTour");
    let response = await createClientCallable(payload);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function resetUserPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (e) {
    return false;
  }
}

export async function uploadTourPhoto(tourId, blob) {
  try {
    let id = uuidv4().toString();
    const tourImageRef = ref(storage, `tour_images/${tourId}/${id}.png`);
    let url = await uploadBytes(tourImageRef, blob).then(async (snapshot) => {
      return getDownloadURL(snapshot.ref).then(async (url) => {
        let query = doc(firestoreDb, `tours/${tourId}/images`, id);
        let docRef = await setDoc(query, {
          image_id: id,
          image_url: url,
          image_name: blob.name,
        });
        return url;
      });
    });
    return url;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getTourPhotos(tourId) {
  try {
    let query = collection(firestoreDb, `tours/${tourId}/images`);
    let snap = await getDocs(query);
    return snap.docs.map((doc) => {
      return doc.data();
    });
  } catch (e) {
    console.log(e);
    return {};
  }
}

export async function updateTourPhotoUrls(tourId, urls) {
  try {
    let query = doc(firestoreDb, `tours`, tourId);
    // TODO add check for tours created by someone else
    let docRef = await updateDoc(query, { image_urls: urls });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteTourPhotos(tourId) {
  try {
    const tourImageRef = ref(storage, `tour_images/${tourId}`);
    let res = await deleteObject(tourImageRef);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadTourPreviewPicture(tourId, blob) {
  try {
    const tourImageRef = ref(storage, `tour_images/${tourId}/preview.jpg`);
    let url = await uploadBytes(tourImageRef, blob).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then(async (url) => {
        let query = doc(firestoreDb, "tours", tourId);
        // TODO add check for tours created by someone else
        let docRef = await updateDoc(query, { preview_url: url });
        return url;
      });
    });
    return url;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function sendPhotosUploadedEmail(tourName, clientEmail, tourId) {
  try {
    let query = collection(firestoreDb, "mail");
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, {
      to: "ryan@vuprop.com",
      message: {
        subject: "Tour pictures have been uploaded.",
        html: `Hi.
        <br/>
        <br/>
        ${clientEmail} has uploaded the pictures for tour ${tourName}.
        <br/>
        <br/>
        Tour ID: ${tourId}.
        <br/>
        <br/>
        This is an automated email.`,
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export function showHash(val) {
  return cyrb53(val);
}

export async function getAllCompanies() {
  let query = collection(firestoreDb, "companies");
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function getCompanyDetails(id) {
  let query = doc(firestoreDb, "companies", id.toString());
  const querySnapshot = await getDoc(query);
  return querySnapshot.data();
}

export async function getCompanyOperators(id) {
  let query = collection(firestoreDb, `companies/${id}/operators`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function getCompanyManagers(id) {
  let query = collection(firestoreDb, `companies/${id}/managers`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function getCompanyHangars(id) {
  let query = collection(firestoreDb, `companies/${id}/hangars`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function getCompanyAircraft(id) {
  let query = collection(firestoreDb, `companies/${id}/aircraft`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function getCompanyTours(id) {
  let query = collection(firestoreDb, `companies/${id}/tours`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function addStaffToCompany(
  companyId,
  staff,
  type,
  update = false
) {
  try {
    if (update) {
      let query = doc(firestoreDb, `staff`, staff.id);
      // TODO add check for tours created by someone else
      let docRef = await setDoc(query, staff);
      let query2 = doc(
        firestoreDb,
        `companies/${companyId}/${type}s`,
        staff.id
      );
      await setDoc(query2, { email: staff.email, id: hash });
      return staff.id;
    }
    let hash = cyrb53(staff.email).toString();
    let clientId = {
      ...staff,
      id: hash,
    };
    let query = doc(firestoreDb, `staff`, hash);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, clientId);
    let query2 = doc(firestoreDb, `companies/${companyId}/${type}s`, hash);
    await setDoc(query2, { email: staff.email, id: hash });
    return hash;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getStaffDetails(staffId) {
  let query = doc(firestoreDb, `staff`, `${staffId}`);
  let staff = await getDoc(query);
  if (staff.exists()) {
    return staff.data();
  }
  return {};
}

export async function createStaffAccount(staffEmail) {
  try {
    let hash = cyrb53(staffEmail).toString();
    const createClientCallable = httpsCallable(
      functions,
      "createClientAccount"
    );
    let response = await createClientCallable({
      client_email: staffEmail,
      id: hash,
    });
    if (response.data.status === "success") {
      let query = doc(firestoreDb, `staff/${hash}`);
      // TODO add check for tours created by someone else
      let docRef = await updateDoc(query, { has_account: true });
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteStaff(staff) {
  try {
    let query = doc(firestoreDb, `staff`, staff.id);
    let docRef = await deleteDoc(query);
    let query2 = doc(
      firestoreDb,
      `companies/${staff.company_id}/${staff.access}s`,
      staff.id
    );
    await deleteDoc(query2);
    return staff.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getStaffTours(staffEmail, doHash = false, limit = -1) {
  try {
    let hash = staffEmail;
    if (doHash) {
      hash = cyrb53(staffEmail).toString();
    }

    if (limit <= 0) {
      var query = collection(firestoreDb, `staff/${hash}/tours`);
    } else {
      query = query(
        collection(firestoreDb, `staff/${hash}/tours`),
        limit(limit)
      );
    }
    const querySnapshot = await getDocs(query);
    let tours = querySnapshot.docs.map((doc) => {
      return doc.data();
    });
    return tours;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteAviationTour(tour) {
  try {
    let query = doc(firestoreDb, `tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await deleteDoc(query, tour);
    await deleteTourImages(tour);
    await removeTourFromAircraft(tour);
    await removeTourFromCompany(tour.company_id, tour.id);
    await removeTourFromStaff(tour, tour.creator_id);
    await deleteTourDocuments(tour);
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function removeTourFromStaff(tour, staffId) {
  try {
    let query = doc(firestoreDb, `staff/${staffId}/tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await deleteDoc(query, { tour_id: tour.id });
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function removeTourFromCompany(companyId, tourId) {
  try {
    let query = doc(firestoreDb, `companies/${companyId}/tours`, tourId);
    let docRef = await deleteDoc(query);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function addAviationTour(tour) {
  try {
    let query = doc(firestoreDb, `tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, tour);
    await assignTourToAircraft(tour);
    await assignTourToStaff(tour);
    await assignTourToCompany(tour);
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function assignTourToStaff(tour) {
  try {
    let query = doc(firestoreDb, `staff/${tour.creator_id}/tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, { tour_id: tour.id });
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function assignTourToCompany(tour) {
  try {
    let query = doc(firestoreDb, `companies/${tour.company_id}/tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, { tour_id: tour.id });
    return tour.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function deleteAviationSpace(spaceId, companyId) {
  try {
    let query = doc(firestoreDb, `companies/${companyId}/aircraft`, spaceId);
    let docRef = await deleteDoc(query);
    query = doc(firestoreDb, `spaces/`, spaceId);
    docRef = await deleteDoc(query);
    const spaceImageRef = ref(storage, `space_images/${spaceId}.jpg`);
    await deleteObject(spaceImageRef);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function addCompany(company) {
  try {
    let query = doc(firestoreDb, `companies`, company.id);
    let docRef = await setDoc(query, company);
    return company.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function addHangar(hangar) {
  try {
    let query = doc(firestoreDb, `hangars`, hangar.id);
    let docRef = await setDoc(query, hangar);
    let query2 = doc(
      firestoreDb,
      `companies/${hangar.company_id}/hangars`,
      hangar.id
    );
    await setDoc(query2, { id: hangar.id });
    return hangar.id;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getHangarDetails(hangarId) {
  let query = doc(firestoreDb, `hangars`, `${hangarId}`);
  let hangar = await getDoc(query);
  if (hangar.exists()) {
    return hangar.data();
  }
  return {};
}

export async function deleteAviationHangar(hangarId, companyId) {
  try {
    let query = doc(firestoreDb, `companies/${companyId}/hangars`, hangarId);
    let docRef = await deleteDoc(query);
    query = doc(firestoreDb, `hangars/`, hangarId);
    docRef = await deleteDoc(query);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getUserDetails(userId) {
  try {
    let query = doc(firestoreDb, `staff/`, userId);
    // TODO add check for tours created by someone else
    let docSnap = await getDoc(query);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    return "admin";
  } catch (e) {
    console.log(e);
    return "admin";
  }
}

export async function createAircraft(
  companyId,
  companyName,
  userId,
  title = "untitled"
) {
  try {
    let aircraft = {
      title: title,
      company_id: companyId,
      company: companyName,
      creator: userId,
    };
    let query = collection(firestoreDb, `aircraft`);
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, aircraft);
    let id = docRef.id;

    let query2 = doc(firestoreDb, `aircraft/`, id);
    await updateDoc(query2, { id: id });
    aircraft = {
      ...aircraft,
      id: id,
    };
    let query3 = doc(firestoreDb, `companies/${companyId}/aircraft`, id);
    await setDoc(query3, { id: id });
    return aircraft;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function updateAircraft(aircraft) {
  try {
    let query = doc(firestoreDb, `aircraft/`, aircraft.id);
    // TODO add check for tours created by someone else
    let docRef = await updateDoc(query, aircraft);
    return aircraft;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getAircraft(id) {
  let query = doc(firestoreDb, `aircraft`, `${id}`);
  // TODO add check for tours created by someone else
  let space = await getDoc(query);
  if (space.exists()) {
    return space.data();
  }
  return {};
}

export async function getAircraftTours(aircraftId) {
  try {
    let query = collection(firestoreDb, `aircraft/${aircraftId}/tours`);
    return await getDocs(query).then((snap) => {
      return snap.docs.map((val) => {
        return val.data().id;
      });
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteAircraft(aircraft) {
  try {
    await deleteAircraftTours(aircraft.id);
    let query = doc(
      firestoreDb,
      `companies/${aircraft.company_id}/aircraft`,
      aircraft.id
    );
    let docRef = await deleteDoc(query);
    query = doc(firestoreDb, `aircraft/`, aircraft.id);
    docRef = await deleteDoc(query);
    const spaceImageRef = ref(storage, `aircraft_images/${aircraft.id}.jpg`);
    await deleteObject(spaceImageRef);
    await deleteAircraftDocuments(aircraft.id);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function uploadAircraftPicture(aircraftId, blob) {
  try {
    const spaceImageRef = ref(storage, `aircraft_images/${aircraftId}.jpg`);
    let url = await uploadBytes(spaceImageRef, blob).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then(async (url) => {
        let query = doc(firestoreDb, `aircraft`, aircraftId);
        // TODO add check for tours created by someone else
        let docRef = await updateDoc(query, { aircraft_image_url: url });
        return url;
      });
    });
    return url;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function assignTourToAircraft(tour) {
  try {
    let query = doc(firestoreDb, `aircraft/${tour.aircraft_id}/tours`, tour.id);
    // TODO add check for tours created by someone else
    let docRef = await setDoc(query, { id: tour.id });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function removeTourFromAircraft(tour) {
  try {
    let query = doc(firestoreDb, `aircraft/${tour.aircraft_id}/tours`, tour.id);
    let docRef = await deleteDoc(query);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function deleteAircraftTours(aircraftId) {
  try {
    let query = collection(firestoreDb, `aircraft/${aircraftId}/tours`);
    await getDocs(query).then((snap) => {
      snap.docs.map((val) => {
        getTourDetails("", val.data().id).then((res) => {
          deleteAviationTour(res);
        });
      });
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteTourImages(tour) {
  try {
    let query = collection(firestoreDb, `tours/${tour.id}/images`);
    await getDocs(query).then((snap) => {
      snap.docs.map((val) => {
        let data = val.data();
        let q2 = doc(firestoreDb, `tours/${tour.id}/images`, data.image_id);
        deleteDoc(q2);
        const imageRef = ref(storage, `tour_images/${tour.id}/data.image_name`);
        deleteObject(imageRef);
      });
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteTourDocuments(tour) {
  try {
    let query = collection(firestoreDb, `tours/${tour.id}/documents`);
    await getDocs(query).then((snap) => {
      snap.docs.map((val) => {
        let data = val.data();
        deleteTourDocument(tour.id, data.id);
      });
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteTourDocument(tourId, documentId) {
  try {
    let q = doc(firestoreDb, `tours/${tourId}/documents`, documentId);
    await deleteDoc(q);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteAircraftDocuments(aircraftId) {
  try {
    let query = collection(firestoreDb, `aircraft/${aircraftId}/documents`);
    await getDocs(query).then((snap) => {
      snap.docs.map((val) => {
        let data = val.data();
        deleteAircraftDocument(aircraftId, data.id);
      });
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function deleteAircraftDocument(aircraftId, documentId) {
  try {
    let q = doc(firestoreDb, `aircraft/${aircraftId}/documents`, documentId);
    await deleteDoc(q);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function increaseUnfinishedTours(companyId) {
  try {
    console.log(companyId);
    let query = doc(firestoreDb, `companies`, companyId);
    let data = await getDoc(query);
    if (data.exists()) {
      console.log(data.data());
    }
    await updateDoc(query, {
      unfinished_tours: data.data().unfinished_tours + 1,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function decreaseUnfinishedTours(companyId) {
  try {
    let query = doc(firestoreDb, `companies`, companyId);
    let data = await getDoc(query);
    await updateDoc(query, {
      unfinished_tours: data.data().unfinished_tours - 1,
    });
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getCompanyAircraftCount(id) {
  let query = collection(firestoreDb, `companies/${id}/aircraft`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.length;
}

export async function getCompanyNewTours(id) {
  let query = doc(firestoreDb, `companies/`, id.toString());
  const querySnapshot = await getDoc(query);
  if (querySnapshot.exists()) {
    if (querySnapshot.data().new_tours) {
      return querySnapshot.data().new_tours;
    }
    return 0;
  }
  return 0;
}

export async function getCompanyEquipmentCount(id) {
  let query = collection(firestoreDb, `companies/${id}/equipment`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.length;
}

export async function getEquipment(id) {
  let query = doc(firestoreDb, `equipment`, `${id}`);
  let equipment = await getDoc(query);
  if (equipment.exists()) {
    return equipment.data();
  }
  return {};
}

export async function getCompanyEquipment(id) {
  let query = collection(firestoreDb, `companies/${id}/equipment`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => {
    return doc.data();
  });
}

export async function getCompanyBilling(id) {
  let query = collection(firestoreDb, `companies/${id}/billing`);
  const querySnapshot = await getDocs(query);
  let bills = querySnapshot.docs.map((doc) => {
    return doc.data();
  });

  return bills.sort((a, b) => {
    return b.date_issued.toDate() - a.date_issued.toDate();
  });
}

export async function getAircraftTourCount(id) {
  let query = collection(firestoreDb, `aircraft/${id}/tours`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.length;
}

export async function getCompanyTourCount(id, complete = false) {
  let q1 = collection(firestoreDb, `companies/${id}/tours`);
  const querySnapshot = await getDocs(q1);

  if (complete) {
    let ids = querySnapshot.docs.map((doc) => {
      return doc.data().tour_id;
    });

    console.log(ids);
    if (ids.length === 0) {
      return 0;
    }

    const coll = collection(firestoreDb, "tours");
    const q = query(
      coll,
      where("id", "in", ids),
      where("status", "==", "complete")
    );
    const result = await getCountFromServer(q);
    console.log(result.data());
    return result.data().count;
  }
  return querySnapshot.docs.length;
}

export async function getTourDocuments(id) {
  let query = collection(firestoreDb, `tours/${id}/documents`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((val) => {
    return val.data();
  });
}

export async function uploadTourDocument(tourId, document) {
  let query = doc(firestoreDb, `tours/${tourId}/documents`, document.id);
  const querySnapshot = await setDoc(query, document);
}

export async function getAircraftDocuments(id) {
  let query = collection(firestoreDb, `aircraft/${id}/documents`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((val) => {
    return val.data();
  });
}

export async function uploadAircraftDocument(aircraftId, document) {
  let query = doc(firestoreDb, `aircraft/${aircraftId}/documents`, document.id);
  const querySnapshot = await setDoc(query, document);
}

export async function sendTourCompleteEmail(
  tourName,
  aircraftName,
  tourUrl,
  clientEmail
) {
  try {
    let query = collection(firestoreDb, "mail");
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, {
      to: "burkejohn1999@gmail.com",
      message: {
        subject: "Your tour is finished!.",
        html: TourCompleteEmail(tourName, aircraftName, tourUrl),
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function addEquipmentToCompany(companyId, equipment) {
  try {
    // Adding Equipment Object to Database
    let query = doc(firestoreDb, `equipment`, equipment.id);
    const querySnapshot = await setDoc(query, equipment);

    // Assigning Equipment to Company
    let query2 = doc(
      firestoreDb,
      `companies/${companyId}/equipment`,
      equipment.id
    );
    const querySnapshot2 = await setDoc(query2, { id: equipment.id });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getCompanyStaffCount(id) {
  let query = collection(firestoreDb, `companies/${id}/operators`);
  const querySnapshot = await getDocs(query);
  let operatorLength = querySnapshot.docs.length;

  let query2 = collection(firestoreDb, `companies/${id}/managers`);
  const querySnapshot2 = await getDocs(query2);

  return querySnapshot2.docs.length + operatorLength;
}

export async function getCompanyToursProcessedThisMonth(
  companyId,
  monthString
) {
  let query = collection(firestoreDb, `companies/${companyId}/${monthString}`);
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.length;
}

export async function addCompanyTourProcessedToMonth(
  companyId,
  monthString,
  tourId
) {
  let query = doc(firestoreDb, `companies/${companyId}/${monthString}`, tourId);
  const querySnapshot = await setDoc(query, { id: tourId });
  return true;
}

export async function updateCompanyBillingInfo(companyId, billingInfo) {
  let query = doc(firestoreDb, `companies`, companyId);
  const querySnapshot = await updateDoc(query, billingInfo);
  return true;
}

export async function getBillingEstimate(companyId) {
  var date = new Date().toLocaleDateString("en-UK");
  date = date.substring(date.indexOf("/") + 1).replace("/", "_");
  const tours = await getCompanyToursProcessedThisMonth(companyId, date);
  const details = await getCompanyDetails(companyId);
  if (details === undefined) {
    return 0;
  }

  const totalTours = await getCompanyTourCount(companyId, true);
  const totalAccounts = await getCompanyStaffCount(companyId);
  return (
    details.price_per_tour * tours +
    details.hosting_fee * totalTours +
    details.account_fee * totalAccounts
  );
}

export async function addInvoice(companyId, invoice) {
  let query = doc(firestoreDb, `companies/${companyId}/billing`, invoice.id);
  const querySnapshot = await setDoc(query, {
    id: invoice.id,
    amount: invoice.total_price,
    date_issued: new Date(),
    number: invoice.invoice_number,
  });

  let query2 = doc(firestoreDb, `invoices`, invoice.id);
  const querySnapshot2 = await setDoc(query2, invoice);
}

export async function getInvoice(invoiceId) {
  let query = doc(firestoreDb, `invoices`, invoiceId);
  const data = await getDoc(query);
  if (data.exists()) {
    return data.data();
  }
  return {};
}

export async function sendCameraRequestEmail(request) {
  try {
    let query = collection(firestoreDb, "mail");
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, {
      to: "ryan@vuprop.com",
      message: {
        subject: `Camera Request for ${request.company}`,
        html: `Hi,
        <br/>
        <br/>
        ${request.company} has requested an additional ${request.cameras} cameras.
        <br/>
        <br/>
        Contact Email: ${request.contactEmail}.
        <br/>
        <br/>
        Account Email: ${request.accountEmail}.
        <br/>
        <br/>
        This in an automated email. Please respond to the contact email instead.`,
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getCompanyMostRecentTour(id) {
  try {
    const ids = (await getCompanyTours(id)).map((tour) => tour.tour_id);
    console.log(ids);
    const coll = collection(firestoreDb, "tours");
    const q = query(
      coll,
      where("id", "in", ids),
      where("status", "==", "complete")
    );

    let completedTours = (await getDocs(q)).docs.map((doc) => doc.data());
    console.log(completedTours);
    let tours = completedTours
      .sort((a, b) => {
        return a.creation_date.seconds < b.creation_date.seconds;
      })
      .slice(0, 2);
    return await Promise.all(
      tours.map(async (tour) => {
        tour.aircraft = await getAircraft(tour.aircraft_id);
        return tour;
      })
    );
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function sendHelpEmail(request) {
  try {
    let query = collection(firestoreDb, "mail");
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, {
      to: "ryan@vuprop.com",
      message: {
        subject: `Help Request from ${request.company}`,
        html: `Hi,
        <br/>
        <br/>
        ${request.company} has requested help.
        <br/>
        <br/>
        Contact Email: ${request.contactEmail}.
        <br/>
        <br/>
        Account Email: ${request.accountEmail}.
        <br/>
        <br/>
        ${request.message}
        <br/>
        <br/>
        This in an automated email. Please respond to the contact email instead.`,
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
