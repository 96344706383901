import "./App.css";
import LoggedInNavbar from "./components/LoggedInNavbar";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from "./providers/UserProvider";
import LoginPage from "./pages/Login";
import LoggedOutNavbar from "./components/LoggedOutNavbar";
import { useContext } from "react";
import AccountPage from "./pages/AccountPage";
import LoggedOutFooter from "./components/LoggedOutFooter";
import ToursPage from "./pages/ToursPage";
import TourPage from "./pages/TourPage";
import ClientsPage from "./pages/ClientsPage";
import AddClientPage from "./pages/AddClientPage";
import AddTourPage from "./pages/AddTourPage";
import ClientDetailsPage from "./pages/ClientDetailsPage";
import ClientTours from "./pages/client_pages/ClientTours";
import ClientNavbar from "./components/ClientNavbar";
import ClientTourDetailsPage from "./pages/client_pages/ClientTourDetailsPage";
import ClientSpacesPage from "./pages/client_pages/ClientSpacesPage";
import ClientSpaceDetailsPage from "./pages/client_pages/ClientSpaceDetailsPage";
import ClientDetailsSpacePage from "./pages/ClientDetailsSpacePage";
import ClientSpaceRequestPage from "./pages/client_pages/ClientSpaceRequestPage";
import ClientTourRequestPage from "./pages/client_pages/ClientTourRequestPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import AviationNavbar from "./components/aviation_components/AviationNavbar";
import AviationTourDetailsPage from "./pages/aviation_pages/AviationTourPageDetails";
import ClientAviationTourPage from "./pages/ClientAviationTourPage";
import ClientAviationSpacePage from "./pages/ClientAviationSpacePage";
import ListOfCompaniesPage from "./pages/admin_pages/ListOfCompaniesPage";
import AviationCompanyPage from "./pages/admin_pages/aviation/AviationCompanyPage";
import AviationCompanyStaffAddPage from "./pages/admin_pages/aviation/AviationCompanyStaffAddPage";
import AviationCompanyStaffDetailsPage from "./pages/admin_pages/aviation/AviationCompanyStaffDetailsPage";
import AviationTourPage from "./pages/admin_pages/aviation/AviationTourPage";
import AviationAircraftPage from "./pages/admin_pages/aviation/AviationAircraftPage";
import AddCompanyPage from "./pages/admin_pages/AddCompanyPage";
import AviationCompanyHangarAddPage from "./pages/admin_pages/aviation/AviationCompanyHangarAdd";
import AviationHangarPage from "./pages/admin_pages/aviation/AviationHangarPage";
import ClientHomePage from "./pages/client_pages/ClientHomePage";
import AviationToursPage from "./pages/aviation_pages/AviationToursPage";
import AviationAircraftListPage from "./pages/aviation_pages/AviationAircraftListPage";
import AviationAircraftDetailsPage from "./pages/aviation_pages/AviationAircraftDetailsPage";
import AviationStaffPage from "./pages/aviation_pages/AviationStaffPage";
import AviationStaffAddPage from "./pages/aviation_pages/AviationStaffAddPage";
import AviationStaffDetailsPage from "./pages/aviation_pages/AviationStaffDetailsPage";
import AviationHangarListPage from "./pages/aviation_pages/AviationHangarListPage";
import AviationHangarDetailsPage from "./pages/aviation_pages/AviationHangarDetailsPage";
import AviationEquipmentListPage from "./pages/aviation_pages/AviationEquipmentListPage";
import AviationEquipmentPage from "./pages/aviation_pages/AviationEquipmentPage";
import AviationEquipmentDetailsPage from "./pages/admin_pages/aviation/AviationEquipmentDetailsPage";
import AviationAddEquipmentPage from "./pages/admin_pages/aviation/AviationAddEquipmentPage";
import AviationInvoicePage from "./pages/admin_pages/aviation/AviationInvoicePage";
import AviationInvoiceDetailsPage from "./pages/aviation_pages/AviationInvoiceDetailsPage";
import AviationAccountPage from "./pages/aviation_pages/AviationAccountPage";
import AviationBillingPage from "./pages/aviation_pages/AviationBillingPage";
import AviationOrdeCameraPage from "./pages/aviation_pages/AviationOrderCameraPage";
import AviationHelpPage from "./pages/aviation_pages/AviationHelpPage";

function Application() {
  const user = useContext(UserContext);
  return (
    <div>
      {user?.user ? (
        <>
          <div className="content-wrap">
            {user?.type === "admin" ? (
              <>
                <LoggedInNavbar />
                <div className="content">
                  <Routes>
                    {/* <Route path="/" element={<ClientsPage />} /> */}
                    <Route path="/" element={<ListOfCompaniesPage />} />
                    <Route path="/company" element={<ListOfCompaniesPage />} />
                    <Route path="/company/add" element={<AddCompanyPage />} />

                    <Route path="/clients" element={<ListOfCompaniesPage />} />
                    <Route path="/clients/add" element={<AddCompanyPage />} />
                    <Route path="/tours" element={<ToursPage />} />
                    <Route path="/tours/add" element={<AddTourPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route
                      path="/tours/:tourId"
                      element={<AviationTourPage />}
                    />
                    <Route
                      path="/aviation-tours/:tourId"
                      element={<AviationTourPage />}
                    />
                    <Route
                      path="/aviation/company/:companyName"
                      element={<AviationCompanyPage />}
                    />

                    <Route
                      path="/aviation/company/:companyName/add/manager"
                      element={<AviationCompanyStaffAddPage access="manager" />}
                    />
                    <Route
                      path="/aviation/company/:companyName/add/operator"
                      element={
                        <AviationCompanyStaffAddPage access="operator" />
                      }
                    />

                    <Route
                      path="/aviation/company/:companyName/add/equipment"
                      element={<AviationAddEquipmentPage />}
                    />

                    <Route
                      path="/staff/:staffId"
                      element={<AviationCompanyStaffDetailsPage />}
                    />
                    <Route
                      path="/aircraft/:aircraftId"
                      element={<AviationAircraftPage />}
                    />

                    <Route
                      path="/aviation/company/:companyName/hangar/:hangarId"
                      element={<AviationHangarPage />}
                    />

                    <Route
                      path="/aviation/company/:companyName/add/hangar"
                      element={<AviationCompanyHangarAddPage />}
                    />

                    <Route
                      path="/equipment/:equipmentId"
                      element={<AviationEquipmentDetailsPage />}
                    />

                    <Route
                      path="/invoices/:invoiceId"
                      element={<AviationInvoicePage />}
                    />
                  </Routes>
                </div>
              </>
            ) : user?.details.industry === "aviation" ? (
              <>
                <AviationNavbar
                  access={user?.details.access}
                  industry={user?.details.industry}
                />
                <div className="content">
                  <Routes>
                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route
                      path="/"
                      element={
                        <ClientHomePage
                          access={user?.details.access}
                          industry={user?.details.industry}
                        />
                      }
                    />
                    <Route
                      path="/aircraft"
                      element={<AviationAircraftListPage />}
                    />
                    <Route path="/tours" element={<AviationToursPage />} />
                    <Route
                      path="/tours/:tourId"
                      element={<AviationTourDetailsPage />}
                    />
                    <Route
                      path="/aircraft/:aircraftId"
                      element={<AviationAircraftDetailsPage />}
                    />
                    <Route
                      path="/aircraft/:spaceId/tours/:tourId"
                      element={<AviationTourDetailsPage />}
                    />
                    <Route path="/account" element={<AviationAccountPage />} />
                    <Route path="/staff" element={<AviationStaffPage />} />
                    <Route
                      path="/staff/add/operator"
                      element={<AviationStaffAddPage access="operator" />}
                    />
                    <Route
                      path="/staff/:staffId"
                      element={<AviationStaffDetailsPage />}
                    />
                    <Route
                      path="/hangars"
                      element={<AviationHangarListPage />}
                    />
                    <Route
                      path="/hangars/:hangarId"
                      element={<AviationHangarDetailsPage />}
                    />
                    <Route
                      path="/equipment"
                      element={<AviationEquipmentListPage />}
                    />
                    <Route
                      path="/equipment/:equipmentId"
                      element={<AviationEquipmentPage />}
                    />
                    <Route
                      path="/equipment/order"
                      element={<AviationOrdeCameraPage />}
                    />
                    <Route path="/help" element={<AviationHelpPage />} />
                    <Route
                      path="/invoices/:invoiceId"
                      element={<AviationInvoiceDetailsPage />}
                    />

                    <Route path="/billing" element={<AviationBillingPage />} />
                  </Routes>
                </div>
              </>
            ) : (
              <>
                <ClientNavbar
                  access={user?.details.access}
                  industry={user?.details.industry}
                />
                <div className="content">
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <ClientHomePage
                          access={user?.details.access}
                          industry={user?.details.industry}
                        />
                      }
                    />
                    <Route path="/spaces" element={<ClientSpacesPage />} />
                    <Route
                      path="/spaces/request"
                      element={<ClientSpaceRequestPage />}
                    />
                    <Route
                      path="/spaces/:spaceId/request-tour"
                      element={<ClientTourRequestPage />}
                    />
                    <Route
                      path="/spaces/:spaceId"
                      element={<ClientSpaceDetailsPage />}
                    />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/tours" element={<ClientTours />} />
                    <Route
                      path="/tours/:tourId"
                      element={<ClientTourDetailsPage />}
                    />
                  </Routes>
                </div>
              </>
            )}
          </div>
          <LoggedOutFooter />
        </>
      ) : (
        <>
          <div className="content-wrap">
            <LoggedOutNavbar />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <LoggedOutFooter />
        </>
      )}
    </div>
  );
}

export default Application;
