import { Card, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

const BoxTitle = (props) => {
  return (
    <div className={"dashboard-box-title-" + props.color}>{props.title}</div>
  );
};

export default BoxTitle;
