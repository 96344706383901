import { useContext } from "react";
import { useParams } from "react-router";
import { UserContext } from "../../providers/UserProvider";
import ManagerStaffDetailsPage from "./manager_pages/ManagerStaffDetailsPage";

const AviationStaffDetailsPage = (props) => {
  let { staffId } = useParams();
  const user = useContext(UserContext);
  const getStaffPage = () => {
    if (user.details.access === "manager") {
      return <ManagerStaffDetailsPage staffId={staffId} />;
    } else if (user.details.access === "operator") {
      return <div></div>;
    }
  };
  return getStaffPage();
};

export default AviationStaffDetailsPage;
