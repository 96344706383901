import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Stack, Table } from "react-bootstrap";
import AviationBillingBox from "../../../components/aviation_components/AviationBillingBox";
import EditableBox from "../../../components/EditableBox";
import Loading from "../../../components/Loading";
import { generateHtmlInvoice } from "../../../email_templates/Invoice";

import { v4 as uuidv4 } from "uuid";
import {
  addInvoice,
  getCompanyBilling,
  getCompanyDetails,
  getCompanyStaffCount,
  getCompanyTourCount,
  getCompanyToursProcessedThisMonth,
  updateCompanyBillingInfo,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import AviationPriceBox from "../../../components/aviation_components/AviationPriceBox";
import InvoiceList from "../../../components/InvoiceList";

const ManagerBillingPage = (props) => {
  const [monthlyTours, setMonthlyTours] = useState(0);
  const [tourPrice, setTourPrice] = useState(0);
  const [softwarePrice, setSoftwarePrice] = useState(0);
  const [hostingPrice, setHostingPrice] = useState(0);
  const [accountPrice, setAccountPrice] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(0);

  const [totalAccounts, setTotalAccounts] = useState(0);
  const [totalTours, setTotalTours] = useState(0);
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyBilling(user.details.company_id).then((res) => {
      setInvoices(
        res.map((x) =>
          Object.assign({}, x, { date_issued: x.date_issued.toDate() })
        )
      );
      setInvoiceNumber(res.length + 1);
      setLoading(false);
    });
    var date = new Date().toLocaleDateString("en-UK");
    date = date.substring(date.indexOf("/") + 1).replace("/", "_");
    getCompanyToursProcessedThisMonth(user.details.company_id, date).then(
      (res) => {
        setMonthlyTours(res);
      }
    );

    getCompanyStaffCount(user.details.company_id).then((res) => {
      setTotalAccounts(res);
    });

    getCompanyTourCount(user.details.company_id, true).then((res) => {
      setTotalTours(res);
    });

    getCompanyDetails(user.details.company_id).then((res) => {
      setSoftwarePrice(res.software_price_monthly);
      setTourPrice(res.price_per_tour);
      setHostingPrice(res.hosting_fee);
      setAccountPrice(res.account_fee);
    });
  }, [user]);

  const totalPrice = () => {
    return (
      monthlyTours * tourPrice +
      totalAccounts * accountPrice +
      totalTours * hostingPrice
    );
  };

  return (
    <Container className="page-margin">
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <h1 className="page-title">{`Billing`}</h1>
          <hr />
          <div className="section-header">
            Current Estimate (Billed at the end of the month)
          </div>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Price €</th>
                <th>Quantity</th>
                <th>Total €</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Tour Processing</b>
                </td>
                <td>{tourPrice}</td>
                <td>{monthlyTours}</td>
                <td>{tourPrice * monthlyTours}</td>
              </tr>
              <tr>
                <td>
                  <b>Hosting Fee</b>
                </td>
                <td>{hostingPrice}</td>
                <td>{totalTours}</td>
                <td>{hostingPrice * totalTours}</td>
              </tr>
              <tr>
                <td>
                  <b>Account Fee</b>
                </td>
                <td>{accountPrice}</td>
                <td>{totalAccounts}</td>
                <td>{accountPrice * totalAccounts}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>{totalPrice()}</td>
              </tr>
            </tbody>
          </Table>

          <Stack direction="horizontal">
            <div className="section-header">Invoices</div>
          </Stack>
          <hr />

          <Stack>
            <InvoiceList invoices={invoices} />
            <br />
            <br />
          </Stack>
        </div>
      )}
    </Container>
  );
};

export default ManagerBillingPage;
