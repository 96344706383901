import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const AviationBillingBox = (props) => {
  const navigate = useNavigate();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const goToPDF = () => {
    if (props.bill.new) {
      return;
    }
    navigate(`/invoices/${props.bill.id}`);
  };
  return (
    <Stack direction="horizontal" className="client-box m-3 px-3">
      <div className="billing-box-text">
        {props.bill.date_issued.toLocaleDateString(undefined, options)}
      </div>
      <div className="billing-box-text ms-auto me-3">€{props.bill.amount}</div>
      <Stack
        direction="horizontal"
        className="billing-box-link"
        onClick={() => goToPDF()}
      >
        {props.bill.new ? (
          <Button
            variant="primary"
            bg="black"
            className="login-submit-button ms-auto"
            onClick={() => props.bill.saveInvoice()}
          >
            Save
          </Button>
        ) : (
          <>
            <span className={"material-symbols-outlined billing-box-icon"}>
              download
            </span>
            <div className="billing-box-text">View</div>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AviationBillingBox;
