import { Component, createContext } from "react";
import {
  auth,
  getStaffDetails,
  getUserDetails,
  getUserIndustry,
  getUserType,
} from "../firebase";

export const UserContext = createContext({ user: null, data: null });

class UserProvider extends Component {
  state = {
    user: null,
    data: null,
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(async (userAuth) => {
      let type = null;
      let industry = null;
      let details = null;
      if (userAuth) {
        type = await getUserType(userAuth.uid);
        details = await getStaffDetails(userAuth.uid);
        industry = await getUserIndustry(userAuth.uid);
      }
      this.setState({
        user: userAuth,
        type: type,
        industry: industry,
        details: details,
      });
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
