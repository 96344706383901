import { Col, Container, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const AviationDocumentsBox = (props) => {
  const openUrl = () => {
    window.open("https://" + props.document.url, "_blank", "resizable=yes");
  };
  return (
    <Stack direction="horizontal" className="client-box m-3 px-3">
      <div className="document-box-text">{props.document.title}</div>
      <Stack className="documents-box-link"></Stack>
      <a href={props.document.url} target="_blank" className="text-link ">
        <Stack direction="horizontal" className="billing-box-link">
          <span className={"material-symbols-outlined document-box-icon"}>
            download
          </span>
          <div className="document-box-text">Link</div>
        </Stack>
      </a>

      <span
        onClick={() => props.delete(props.document.id)}
        className={
          "material-symbols-outlined document-box-icon ms-3 billing-box-link"
        }
      >
        close
      </span>
    </Stack>
  );
};

export default AviationDocumentsBox;
