import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import EditableBox from "../../../components/EditableBox";
import Loading from "../../../components/Loading";
import { getEquipment } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

const AviationEquipmentDetailsPage = (props) => {
  const [equipment, setEquipment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [hasTripod, setHasTripod] = useState("");
  const [tripodId, setTripodId] = useState("");
  const [type, setType] = useState("");
  const [serial, setSerial] = useState("");
  const [model, setModel] = useState("");

  const user = useContext(UserContext);
  let { equipmentId } = useParams();
  useEffect(() => {
    setLoading(true);
    getEquipment(equipmentId).then((res) => {
      setEquipment(res);
      setTitle(res.title);
      setAddress(res.address);
      setAddress2(res.address_2);
      setCity(res.city);
      setCountry(res.country);
      setPostalCode(res.post_code);
      setHasTripod(res.has_tripod ? "YES" : "NO");
      setTripodId(res.tripod_id);
      setSerial(res.serial);
      setModel(res.model);
      setType(res.type);
      setLoading(false);
    });
  }, [equipmentId]);
  return (
    <Container className="page-margin">
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <h1 className="page-title title-margin">{`Equipment: ${title}`}</h1>
          <hr />
          <div className="section-header">Details</div>
          <Row>
            <Col md={6}>
              <div className="subsection-header">Title</div>
              <EditableBox content={title} updateVal={setTitle} edit={edit} />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Type</div>
              <EditableBox content={type} updateVal={setType} edit={edit} />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Model</div>
              <EditableBox content={model} updateVal={setModel} edit={edit} />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Serial Number</div>
              <EditableBox content={serial} updateVal={setSerial} edit={edit} />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Has Tripod</div>
              <EditableBox
                content={hasTripod}
                updateVal={setHasTripod}
                edit={edit}
              />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Tripod ID</div>
              <EditableBox
                content={tripodId}
                updateVal={setTripodId}
                edit={edit}
              />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Address</div>
              <EditableBox
                content={address}
                updateVal={setAddress}
                edit={edit}
              />
            </Col>
            <Col md={6}>
              <div className="subsection-header">Address 2</div>
              <EditableBox
                content={address2}
                updateVal={setAddress2}
                edit={edit}
              />
            </Col>
            <Col md={4}>
              <div className="subsection-header">City</div>
              <EditableBox content={city} updateVal={setCity} edit={edit} />
            </Col>
            <Col md={4}>
              <div className="subsection-header">Country</div>
              <EditableBox
                content={country}
                updateVal={setCountry}
                edit={edit}
              />
            </Col>
            <Col md={4}>
              <div className="subsection-header">Postal Address</div>
              <EditableBox
                content={postalCode}
                updateVal={setPostalCode}
                edit={edit}
              />
            </Col>
          </Row>
          <br />
        </div>
      )}
    </Container>
  );
};

export default AviationEquipmentDetailsPage;
