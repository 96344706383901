import { useContext, useEffect, useState } from "react";
import { Button, Container, Stack } from "react-bootstrap";

import { UserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router";
import EditableBox from "../../components/EditableBox";
import { sendHelpEmail } from "../../firebase";

const AviationHelpPage = () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const user = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(user.details.email);
  }, [user]);

  const sendMessage = async () => {
    if (email === "" || message === "") {
      return;
    }
    const request = {
      contactEmail: email,
      accountEmail: user.details.email,
      message: message,
      company: user.details.company,
    };
    await sendHelpEmail(request);
    setEmailSend(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Help`}</h1>
      <hr />
      {!emailSend ? (
        <div className="order-camera-form">
          <div className="form-title">{`Contact Us`}</div>
          <div className="form-text">
            Leave us a message and we'll email you shortly.
          </div>
          <br />
          <div className="form-text text-start">Email</div>

          <EditableBox content={email} updateVal={setEmail} edit={true} />
          <br />
          <div className="form-text text-start">Message</div>
          <EditableBox
            content={message}
            updateVal={setMessage}
            edit={true}
            rows={5}
          />
          <br />
          <Stack direction="horizontal" className="">
            <Button
              variant="primary"
              bg="black"
              className={"login-submit-button m-auto"}
              onClick={() => sendMessage()}
            >
              Send
            </Button>
          </Stack>
        </div>
      ) : (
        <div className="order-camera-form">
          <div className="form-title">{`Contact Us`}</div>
          <div className="form-text">
            Thank you. We will contact you shortly.
          </div>
          <br />
          <Stack direction="horizontal" className="">
            <Button
              variant="primary"
              bg="black"
              className={"login-submit-button m-auto"}
              onClick={() => goBack()}
            >
              Go Back
            </Button>
          </Stack>
        </div>
      )}
    </Container>
  );
};

export default AviationHelpPage;
