import { useEffect, useState } from "react";
import { Card, Spinner, Stack } from "react-bootstrap";

const AviationTourImageBox = (props) => {
  let [status, setStatus] = useState("uploading");
  let [url, setUrl] = useState("");
  useEffect(() => {
    props.url
      .then((res) => {
        setUrl(res);
        setStatus("complete");
      })
      .catch((e) => {
        console.log(e);
      });
  }, [props]);

  return (
    <div className={"aviation-tour-image-box-" + status}>
      {status === "uploading" ? (
        <Stack direction="horizontal">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="aviation-tour-image-text">
            Uploading Image: {props.name}
          </div>
        </Stack>
      ) : (
        <Stack direction="horizontal">
          <span className="material-symbols-outlined green-tick">done</span>
          <div className="aviation-tour-image-text">Uploaded: {props.name}</div>
          <div className=" ms-auto aviation-tour-image-text grow">
            <a href={url} className="link-style" target="_blank">
              View
            </a>
          </div>
        </Stack>
      )}
    </div>
  );
};

export default AviationTourImageBox;
