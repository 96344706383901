import Spinner from "react-bootstrap/Spinner";

function Loading(props) {
  return (
    <Spinner
      animation="border"
      role="status"
      className={props.center ? "m-auto" : ""}
      style={props.white ? { color: "white" } : {}}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Loading;
