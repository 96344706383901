import { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Stack,
} from "react-bootstrap";
import AviationTourBox from "../../../components/aviation_components/AviationTourBox";
import Loading from "../../../components/Loading";
import {
  getAircraft,
  getCompanyTours,
  getStaffTours,
  getTourDetails,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

const OperatorToursPage = () => {
  const [tours, setTours] = useState([]);
  const [sortedTours, setSortedTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tourIds, setTourIds] = useState({});
  const [dateSelected, setDateSelected] = useState(true);
  const [msnSelected, setMsnSelected] = useState(false);
  const [statusSelected, setStatusSelected] = useState(false);
  const user = useContext(UserContext);
  useEffect(() => {
    setLoading(true);
    getStaffTours(user.details.id).then((ids) => {
      setTourIds(ids);
      Promise.all(
        ids.map(async (tour) => {
          let details = await getTourDetails("", tour.tour_id);
          details.aircraft = await getAircraft(details.aircraft_id);
          return details;
        })
      ).then((res2) => {
        let order = orderTours(res2);
        setTours(order);
        setSortedTours(order);
        setLoading(false);
      });
    });
  }, [user]);

  const orderTours = (tours) => {
    return tours.sort((a, b) => {
      return a.creation_date.seconds < b.creation_date.seconds;
    });
  };

  const searchByMSN = (text) => {
    if (text === "") {
      setSortedTours(tours);
      return;
    }
    setSortedTours(
      tours.filter((tour) =>
        tour.aircraft.title.toLowerCase().includes(text.toLowerCase())
      )
    );
  };
  const sortByMSN = () => {
    setDateSelected(false);
    setMsnSelected(true);
    setStatusSelected(false);
    setSortedTours(
      sortedTours.sort((a, b) =>
        a.aircraft.title.localeCompare(b.aircraft.title)
      )
    );
  };

  const sortByDate = () => {
    setDateSelected(true);
    setMsnSelected(false);
    setStatusSelected(false);
    setSortedTours(orderTours(sortedTours));
  };
  const sortByStatus = () => {
    setDateSelected(false);
    setMsnSelected(false);
    setStatusSelected(true);
    setSortedTours(
      sortedTours.sort((a, b) => {
        if (a.status === "complete") {
          return -1;
        }
        if (a.status === "processing") {
          if (b.status === "complete") {
            return 1;
          }
          return -1;
        }
        if (a.status === "upload") {
          if (b.status === "upload") {
            return -1;
          }
          return 1;
        }
      })
    );
  };

  return (
    <Container className="page-margin">
      <Stack direction="horizontal">
        <h1 className="page-title">{`Tours`}</h1>
        <div className="ms-auto me-3 mb-auto mt-1">
          <DropdownButton
            title={
              <span className={"material-symbols-outlined sort-icon"}>
                sort
              </span>
            }
            bg="black"
            align={"end"}
            className=""
            variant="dropdown"
          >
            <Dropdown.ItemText>
              <Form.Control
                onChange={(e) => searchByMSN(e.target.value)}
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Search by MSN..."
              />
            </Dropdown.ItemText>
            <Dropdown.Divider />
            <Dropdown.Item
              as="button"
              onClick={() => sortByMSN()}
              className={msnSelected ? "dropdown-item-selected" : ""}
            >
              Sort By MSN
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => sortByDate()}
              className={dateSelected ? "dropdown-item-selected" : ""}
            >
              Sort By Date
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => sortByStatus()}
              className={statusSelected ? "dropdown-item-selected" : ""}
            >
              Sort By Status
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </Stack>
      <hr className="mt-0" />
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : tours.length > 0 ? (
        <Row>
          {sortedTours.map((tour, i) => {
            return (
              <div key={i}>
                <AviationTourBox tour={tour} industry={"aviation"} key={i} />
                <hr />
              </div>
            );
          })}
        </Row>
      ) : (
        <div className="my-3">You have not created any tours.</div>
      )}
    </Container>
  );
};

export default OperatorToursPage;
