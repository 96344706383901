import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const TourBox = (props) => {
  return (
    <Link className="text-link" to={`/tours/${props.tour.id}`}>
      <Card style={{ marginBottom: 25 }} className="grow">
        <Card.Img
          height="180"
          width="260"
          variant="top"
          src={
            props.empty
              ? "https://via.placeholder.com/500x260?text=Add+Tour"
              : `https://my.matterport.com/api/v1/player/models/${props.tour.matterport_id}/thumb?width=1088&dpr=1&disable=upscale`
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://silvawebdesigns.com/wp-content/uploads/2020/12/wordpress-hide-or-change-password-protected-page.jpg";
          }}
          style={{ maxHeight: "260px", maxWidth: "700px", objectFit: "fit" }}
        />

        {props.empty ? (
          <div className=" text-truncate tour-box-title">Add a tour</div>
        ) : (
          <>
            <div className=" text-truncate tour-box-title">
              {props.tour.title}
            </div>

            <div className="tour-box-text">
              {props.tour.city ? props.tour.city : "No Address"}
            </div>
            <div className="tour-box-date">{props.tour.creation_date}</div>
          </>
        )}
      </Card>
    </Link>
  );
};

export default TourBox;
