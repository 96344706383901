import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerAircraftListPage from "./manager_pages/ManagerAircraftListPage";
import ManagerEquipmentListPage from "./manager_pages/ManagerEquipmentListPage";
import ManagerHangarListPage from "./manager_pages/ManagerHangarListPage";
import ManagerOrderCameraPage from "./manager_pages/ManagerOrderCameraPage";
import OperatorAircraftListPage from "./operator_pages/OperatorAircraftListPage";

const AviationOrdeCameraPage = (props) => {
  const user = useContext(UserContext);
  const getEquipmentPage = () => {
    if (user.details.access === "manager") {
      return <ManagerOrderCameraPage />;
    } else if (user.details.access === "operator") {
      return <div />;
    }
  };
  return getEquipmentPage();
};

export default AviationOrdeCameraPage;
