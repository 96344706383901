import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerHomePage from "../aviation_pages/manager_pages/ManagerHomePage";
import ManagerStaffAddPage from "./manager_pages/ManagerStaffAddPage";
import ManagerStaffPage from "./manager_pages/ManagerStaffPage";
import ManagerToursPage from "./manager_pages/ManagerToursPage";
import OperatorToursPage from "./operator_pages/OperatorToursPage";

const AviationStaffAddPage = (props) => {
  const user = useContext(UserContext);
  const getStaffPage = () => {
    if (user.details.access === "manager") {
      return <ManagerStaffAddPage access={props.access} />;
    } else if (user.details.access === "operator") {
      return <div></div>;
    }
  };
  return getStaffPage();
};

export default AviationStaffAddPage;
