import { useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { generateHtmlInvoice } from "../../../email_templates/Invoice";
import parse from "html-react-parser";
import { getInvoice } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

const ManagerInvoiceDetailsPage = () => {
  const user = useContext(UserContext);
  let [invoice, setInvoice] = useState({});
  let [html, setHtml] = useState("");
  let { invoiceId } = useParams();
  useEffect(() => {
    getInvoice(invoiceId).then((res) => {
      if (res.company_id.toString() !== user.details.company_id.toString()) {
      } else {
        setInvoice(res);
        setHtml(generateHtmlInvoice(res, false));
      }
    });
  }, [invoiceId]);

  const getHtml = () => {
    if (html !== "") {
      return parse(html);
    }
    return "";
  };
  return <div className="page-margin">{getHtml()}</div>;
};

export default ManagerInvoiceDetailsPage;
