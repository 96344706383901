import { useEffect } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../Loading";
import AviationBillingBox from "../AviationBillingBox";
import AviationTourBox from "../AviationTourBox";

const AviationMostRecentTourContent = (props) => {
  useEffect(() => {}, [props]);
  return props.loading ? (
    <div className="small-box-content d-flex align-items-center align-content-center">
      <Loading center={true} />
    </div>
  ) : props.tours !== [] && props.tours !== undefined ? (
    <Row className="p-3">
      {props.tours.map((tour, i) => {
        return (
          <div key={i}>
            <AviationTourBox
              tour={tour}
              industry={"aviation"}
              key={i}
              short={true}
            />
            {i != props.tours.length - 1 ? <hr /> : <></>}
          </div>
        );
      })}
    </Row>
  ) : (
    <div className="my-3 text-center">Create some tours to get started.</div>
  );
};

export default AviationMostRecentTourContent;
