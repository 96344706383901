import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import TourBox from "../../components/TourBox";
import { Link, useNavigate } from "react-router-dom";
import {
  addSpace,
  getClientSpaces,
  getClientTours,
  getSpaceDetails,
  getTourDetails,
  sendSpaceRequestEmail,
} from "../../firebase";
import SpaceBox from "../../components/SpaceBox";
const ClientSpaceRequestPage = () => {
  const [spaces, setSpaces] = useState([]);
  const [hasRequested, setHasRequested] = useState(false);
  const [spaceName, setSpaceName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [numberOfTours, setNumberOfTours] = useState(1);
  const [otherDetails, setOtherDetails] = useState("");
  const [validated, setValidated] = useState(false);

  const user = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    setContactEmail(user.user.email);
  }, [user.user]);

  const goBack = async () => {
    navigate(-1);
  };

  const requestSpace = async () => {
    if (spaceName === "") {
      setValidated(true);
    } else {
      setValidated(false);
      const payload = {
        space_name: spaceName,
        number_of_tours: numberOfTours,
        contact_email: contactEmail,
        account_email: user.user.email,
        other_details: otherDetails ? otherDetails : "NONE",
      };
      let res = await sendSpaceRequestEmail(payload);
      if (res) {
        setHasRequested(true);
      }
    }
  };

  const editSpace = async () => {};

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">Request A New Space</h1>
      <hr />
      {hasRequested ? (
        <div>
          <div className="placeholder-element">
            Thank you for your request, we will email you with more details
            shortly.
          </div>
          <div className="d-flex justify-content-center m-3">
            <Button
              variant="primary"
              bg="black"
              className="login-submit-button"
              onClick={() => goBack()}
            >
              My Spaces
            </Button>
          </div>
        </div>
      ) : (
        <Form noValidate className="login-form" validated={validated}>
          <Row>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Space Name*</Form.Label>
                <Form.Control
                  required
                  className="editable-box"
                  type="text"
                  placeholder=""
                  aria-describedby="emailHelpBlock"
                  onChange={(event) => setSpaceName(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Number of Tours*</Form.Label>
                <Form.Control
                  className="editable-box"
                  type="number"
                  placeholder="1"
                  onChange={(event) => setNumberOfTours(event.target.value)}
                  aria-describedby="passwordHelpBlock"
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contact Email*</Form.Label>
            <Form.Control
              className="editable-box"
              type="email"
              placeholder={contactEmail}
              onChange={(event) => setContactEmail(event.target.value)}
              aria-describedby="passwordHelpBlock"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridlabel">
            <Form.Label>Other Details</Form.Label>
            <Form.Control
              className="editable-box"
              as="textarea"
              rows={3}
              onChange={(event) => setOtherDetails(event.target.value)}
            />
          </Form.Group>
          <Button
            variant="primary"
            bg="black"
            className="login-submit-button"
            onClick={() => requestSpace()}
          >
            Request Space
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default ClientSpaceRequestPage;
