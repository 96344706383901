import { Button, Col, Container, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import {
  addTour,
  addTourToSpace,
  deleteAviationHangar,
  deleteSpace,
  getClientTours,
  getHangarDetails,
  getSpaceDetails,
  getSpaceTours,
  getTourDetails,
  updateSpaceDetails,
  uploadSpacePicture,
} from "../../../firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import EditableBox from "../../../components/EditableBox";
import AviationTourBox from "../../../components/aviation_components/AviationTourBox";
import {
  addAviationTour,
  deleteAviationSpace,
  showHash,
} from "../../../firebase";
const AviationHangarPage = () => {
  const [hangarDetails, setHangarDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [hangarImageUrl, setHangarImageUrl] = useState("");
  const [updateHangar, setUpdateHangar] = useState(false);
  const [deleteT, setDeleteT] = useState(false);

  const user = useContext(UserContext);
  let { hangarId, companyName } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getHangarDetails(hangarId).then((res) => {
      setHangarDetails(res);
      setTitle(res.title);
      setAddress(res.address);
      setAddress2(res.address_2);
      setCity(res.city);
      setCountry(res.country);
      setPostalCode(res.postalCode);
      setHangarImageUrl(res.hangar_image_url);
    });
  }, [hangarId, updateHangar]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let hangar = {
        ...hangarDetails,
        title: title ? title : "",
        address: address ? address : "",
        address_2: address2 ? address2 : "",
        city: city ? city : "",
        country: country ? country : "",
        postal_code: postalCode ? postalCode : "",
        hangar_image_url: hangarImageUrl ? hangarImageUrl : "",
      };
      setHangarDetails(hangar);
      await updateHangar(hangar);
    }
    setEdit(!edit);
  };

  const toggleDelete = async () => {
    if (deleteT) {
      await deleteAviationHangar(hangarId, hangarDetails.company_id);
      navigate(-1);
    } else {
      setDeleteT(true);
    }
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Hangar: ${title}`}</h1>
      <Tabs
        defaultActiveKey="details"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <Row className="">
            <Col md={6} xs={12} className="">
              <div className="section-header">Details</div>
              <div className="subsection-header">Title</div>
              <EditableBox content={title} updateVal={setTitle} edit={edit} />
              <div className="subsection-header">Address</div>
              <EditableBox
                content={address}
                updateVal={setAddress}
                edit={edit}
              />
              <div className="subsection-header">Address 2</div>
              <EditableBox
                content={address2}
                updateVal={setAddress2}
                edit={edit}
              />
              <div className="subsection-header">City</div>
              <EditableBox content={city} updateVal={setCity} edit={edit} />
              <div className="subsection-header">Country</div>
              <EditableBox
                content={country}
                updateVal={setCountry}
                edit={edit}
              />
              <div className="subsection-header">Postal Code</div>
              <EditableBox
                content={postalCode}
                updateVal={setPostalCode}
                edit={edit}
              />
              <hr className="d-block d-sm-none" />
            </Col>
            <Col md={6} xs={12}>
              <div className="section-header">Image</div>
              <img
                className="space-image"
                src={hangarImageUrl}
                width="100%"
                height="350px"
              ></img>
              <div className="subsection-header">Image Url</div>
              <EditableBox
                content={hangarImageUrl}
                updateVal={setHangarImageUrl}
                edit={edit}
              />
            </Col>
          </Row>
          <hr />
          <Stack direction="horizontal">
            <Button
              variant="primary"
              bg="black"
              className="login-submit-button"
              onClick={() => toggleEdit()}
            >
              {edit ? "Confirm" : "Edit Details"}
            </Button>
            <Button
              variant="primary"
              bg="black"
              className="delete-button ms-auto"
              onClick={() => toggleDelete()}
            >
              {deleteT ? "Are You Sure?" : "Delete Hangar"}
            </Button>
          </Stack>
        </Tab>
        <Tab eventKey="equipment" title="Equipment">
          <div className="section-header">Equipment</div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AviationHangarPage;
