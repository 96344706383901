export function generateHtmlInvoice(invoiceDetails, download = true) {
  if (download) {
    return `

<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <title>VuProp Invoice #2</title>

    <!-- Favicon -->
    <link rel="icon" href="./images/favicon.png" type="image/x-icon" />

    <!-- Invoice styling -->
    <style>
      body {
        font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        text-align: center;
        color: #777;
      }

      body h1 {
        font-weight: 300;
        margin-bottom: 0px;
        padding-bottom: 0px;
        color: #000;
      }

      body h3 {
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 20px;
        font-style: italic;
        color: #555;
      }

      body a {
        color: #06f;
      }

      .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        color: #555;
      }

      .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
        border-collapse: collapse;
      }

      .invoice-box table td {
        padding: 5px;
        vertical-align: top;
      }

      .invoice-box table tr td:nth-child(2) {
        text-align: right;
      }

      .invoice-box table tr td:nth-child(3) {
        text-align: right;
      }

      .invoice-box table tr td:nth-child(4) {
        text-align: right;
      }

      .invoice-box table tr.top table td {
        padding-bottom: 20px;
      }

      .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
      }

      .invoice-box table tr.information table td {
        padding-bottom: 40px;
      }

      .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }

      .invoice-box table tr.details td {
        padding-bottom: 20px;
      }

      .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
      }

      .invoice-box table tr.item.last td {
        border-bottom: none;
      }

      .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
      }

      @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
          width: 100%;
          display: block;
          text-align: center;
        }

        .invoice-box table tr.information table td {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    </style>
  </head>

  <body>
    <div class="invoice-box">
      <table>
        <tr class="top">
          <td colspan="4">
            <table>
              <tr>
                <td class="title">
                  <img
                    src="https://www.vuprop.com/wp-content/uploads/2022/01/cropped-g88888888.png"
                    alt="VuProp"
                    style="width: 100%; max-width: 120px"
                  />
                </td>

                <td>
                  Invoice #${invoiceDetails.invoice_number}<br />
                  Created: ${invoiceDetails.creation_date}<br />
                  Due: ${invoiceDetails.due_date}
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="4">
            <table>
              <tr>
                <td>
                  VuProp LTD.<br />
                  34 Millview Court,<br />
                  Malahide,<br />
                  Ireland,<br />
                  K36 7E4T,<br />
                </td>

                <td>
                  ${invoiceDetails.billing_name}.<br />
                  ${invoiceDetails.billing_address},<br />
                  ${invoiceDetails.billing_city},<br />
                  ${invoiceDetails.billing_country},<br />
                  ${invoiceDetails.billing_postal}.<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="heading">
          <td>Item</td>
          <td>Quantity</td>
          <td>Rate</td>
          <td>Amount</td>
        </tr>

        <tr class="item">
          <td>Tour Processing</td>
          <td>${invoiceDetails.tour_quantity}</td>
          <td>${invoiceDetails.tour_price}</td>
          <td>${invoiceDetails.tour_quantity * invoiceDetails.tour_price}</td>
        </tr>

        <tr class="item">
          <td>Software Licensing</td>
          <td>1</td>
          <td>${invoiceDetails.software_price}</td>
          <td>${invoiceDetails.software_price}</td>
        </tr>

        <tr class="total">
          <td></td>
          <td></td>
          <td></td>
          <td>Total: ${
            invoiceDetails.tour_quantity * invoiceDetails.tour_price +
            invoiceDetails.software_price
          }</td>
        </tr>
      </table>
    </div>
  </body>
</html>`;
  }
  return `
    <div class="invoice-box">
    <style>

      .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        color: #555;
      }

      .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
        border-collapse: collapse;
      }

      .invoice-box table td {
        padding: 5px;
        vertical-align: top;
      }

      .invoice-box table tr td:nth-child(2) {
        text-align: right;
      }

      .invoice-box table tr td:nth-child(3) {
        text-align: right;
      }

      .invoice-box table tr td:nth-child(4) {
        text-align: right;
      }

      .invoice-box table tr.top table td {
        padding-bottom: 20px;
      }

      .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
      }

      .invoice-box table tr.information table td {
        padding-bottom: 40px;
      }

      .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }

      .invoice-box table tr.details td {
        padding-bottom: 20px;
      }

      .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
      }

      .invoice-box table tr.item.last td {
        border-bottom: none;
      }

      .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
      }

      @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
          width: 100%;
          display: block;
          text-align: center;
        }

        .invoice-box table tr.information table td {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    </style>
      <table>
        <tr class="top">
          <td colspan="4">
            <table>
              <tr>
                <td class="title">
                  <img
                    src="https://www.vuprop.com/wp-content/uploads/2022/01/cropped-g88888888.png"
                    alt="VuProp"
                    style="width: 100%; max-width: 120px"
                  />
                </td>

                <td>
                  Invoice #${invoiceDetails.invoice_number}<br />
                  Created: ${invoiceDetails.creation_date}<br />
                  Due: ${invoiceDetails.due_date}
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="4">
            <table>
              <tr>
                <td>
                  VuProp LTD.<br />
                  34 Millview Court,<br />
                  Malahide,<br />
                  Ireland,<br />
                  K36 7E4T,<br />
                </td>

                <td>
                  ${invoiceDetails.billing_name}.<br />
                  ${invoiceDetails.billing_address},<br />
                  ${invoiceDetails.billing_city},<br />
                  ${invoiceDetails.billing_country},<br />
                  ${invoiceDetails.billing_postal}.<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="heading">
          <td>Item</td>
          <td>Quantity</td>
          <td>Rate €</td>
          <td>Amount €</td>
        </tr>

        <tr class="item">
          <td>Tour Processing</td>
          <td>${invoiceDetails.tour_quantity}</td>
          <td>${invoiceDetails.tour_price}</td>
          <td>${invoiceDetails.tour_quantity * invoiceDetails.tour_price}</td>
        </tr>

        <tr class="item">
          <td>Hosting Fee</td>
          <td>${invoiceDetails.total_tours_hosting}</td>
          <td>${invoiceDetails.hosting_price}</td>
          <td>${
            invoiceDetails.total_tours_hosting * invoiceDetails.hosting_price
          }</td>
        </tr>

        <tr class="item">
          <td>Account Fee</td>
          <td>${invoiceDetails.total_accounts}</td>
          <td>${invoiceDetails.account_price}</td>
          <td>${
            invoiceDetails.total_accounts * invoiceDetails.account_price
          }</td>
        </tr>

        <tr class="total">
          <td></td>
          <td></td>
          <td></td>
          <td>Total: ${
            invoiceDetails.tour_quantity * invoiceDetails.tour_price +
            invoiceDetails.total_accounts * invoiceDetails.account_price +
            invoiceDetails.total_tours_hosting * invoiceDetails.hosting_price
          }</td>
        </tr>
      </table>
    </div>`;
}
