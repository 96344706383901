import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "save-as";
import { v4 as uuidv4 } from "uuid";
import {
  addAviationTour,
  addCompanyTourProcessedToMonth,
  addTour,
  decreaseUnfinishedTours,
  deleteAviationTour,
  deleteTourDocument,
  getAircraft,
  getStaffDetails,
  getTourDetails,
  getTourDocuments,
  getTourPhotos,
  sendTourCompleteEmail,
  uploadTourDocument,
  uploadTourPhoto,
  uploadTourPreviewPicture,
} from "../../../firebase";

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import EditableBox from "../../../components/EditableBox";
import AviationTourImageBox from "../../../components/aviation_components/AviationTourImageBox";
import EditableDropdown from "../../../components/EditableDropdown";
import DocumentsTabContent from "../../../components/aviation_components/tour_details_components/DocumentsTabContent";
import ConfirmationBox from "../../../components/ConfirmationBox";
const AviationTourPage = () => {
  const [tourDetails, setTourDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [virtualTourId, setVirtualTourId] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [copyTourClicked, setCopyTourClicked] = useState(false);
  const [copyEmbedClicked, setCopyEmbedClicked] = useState(false);
  const [deleteT, setDeleteT] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [companyId, setCompanyId] = useState("");

  const [showDeleteDocConfirm, setShowDeleteDocConfirm] = useState(false);
  const [tourDocuments, setTourDocuments] = useState([]);
  const [deleteDocId, setDeleteDocId] = useState("");
  const user = useContext(UserContext);
  const [aircraft, setAircraft] = useState({});
  const navigate = useNavigate();
  let { tourId } = useParams();
  useEffect(() => {
    getTourPhotos(tourId).then((res) => {
      setImageUrls(
        Object.values(res).map((vals) => {
          return {
            url: Promise.resolve(vals.image_url),
            name: vals.image_name,
          };
        })
      );
    });
    getTourDetails(user.user.uid, tourId).then((res) => {
      setTourDetails(res);
      setImageUrl(res.preview_url);
      setTitle(res.title);
      setVirtualTourId(res.tour_id);
      setStatus(res.status);
      setCreatorId(res.creator_id);
      setCompanyId(res.company_id);

      getAircraft(res.aircraft_id).then((res) => {
        setAircraft(res);
      });
    });

    getTourDocuments(tourId).then((res) => {
      setTourDocuments(res);
    });
  }, [tourId, user.user]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let tour = {
        ...tourDetails,
        title: title,
        status: status,
        tour_id: virtualTourId,
      };
      if (tourDetails.status !== "complete" && status === "complete") {
        var date = new Date().toLocaleDateString("en-UK");
        date = date.substring(date.indexOf("/") + 1).replace("/", "_");
        console.log(date);
        await addCompanyTourProcessedToMonth(companyId, date, tourId);
      }
      await addAviationTour(tour);
      setTourDetails(tour);
    }
    setEdit(!edit);
  };

  const downloadAllPhotos = async (e) => {
    const folderName = "tour-" + tourId + "-pictures";
    let zip = new JSZip();
    let count = 0;
    imageUrls.forEach(async (url) => {
      let fileName = url.name;
      let link = await url.url.then((val) => val);
      JSZipUtils.getBinaryContent(link, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(fileName, data, { binary: true });
        count++;
        if (count === imageUrls.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, folderName);
          });
        }
      });
    });
  };

  const embedText = `<iframe 
    width='853' 
    height='480' 
    src='https://embed.vuprop.com/tour/${virtualTourId}' 
    frameborder='0' 
    allowfullscreen allow='xr-spatial-tracking'>
</iframe>
  `;

  const changeTourImage = async (e) => {
    const file = e.target.files[0];
    let url = await uploadTourPreviewPicture(tourId, file);
    setImageUrl(url);
  };

  const toggleDelete = async () => {
    if (deleteT) {
      await deleteAviationTour(tourDetails);
      await navigate(-1);
    } else {
      setDeleteT(true);
    }
  };

  const copyLinkToClipboard = () => {
    setCopyTourClicked(true);
    const link = `embed.vuprop.com/tour/${tourDetails.tour_id}`;
    navigator.clipboard.writeText(link);
  };

  const copyEmbedToClipboard = () => {
    setCopyEmbedClicked(true);
    navigator.clipboard.writeText(embedText);
  };

  const addTourImages = async (e) => {
    const files = e.target.files;
    let urls = Array.from(files).map((file) => {
      return { name: file.name, url: uploadTourPhoto(tourId, file) };
    });
    let newUrls = [].concat(imageUrls).concat(urls);
    setImageUrls(newUrls);
    let tour = {
      ...tourDetails,
      status: "processing",
    };
    setTourDetails(tour);
    await addTour(tour);
  };

  const uploadDocument = async (name, url) => {
    let id = uuidv4().toString();

    const document = {
      id: id,
      title: name,
      url: url,
    };

    await uploadTourDocument(tourId, document);
    setTourDocuments([...tourDocuments, document]);
  };

  const deleteDocument = async () => {
    await deleteTourDocument(tourId, deleteDocId);
    setTourDocuments(tourDocuments.filter((e) => e.id !== deleteDocId));
    setDeleteDocId("");
    setShowDeleteDocConfirm(false);
  };

  const toggleDeleteDocument = async (id) => {
    setShowDeleteDocConfirm(true);
    setDeleteDocId(id);
  };

  const sendCompleteEmail = async () => {
    const email = (await getStaffDetails(creatorId)).email;
    if (email === null) {
      return;
    }
    const link = `https://embed.vuprop.com/tour/${tourDetails.tour_id}`;
    await sendTourCompleteEmail(title, aircraft.title, link, email);
  };

  return (
    <Container className="page-margin">
      <ConfirmationBox
        heading="Delete Document"
        show={showDeleteDocConfirm}
        handleClose={() => setShowDeleteDocConfirm(false)}
        cancelText="Cancel"
        body="Are you sure that you wish to delete this document?"
        confirmText="Delete Document"
        handleSubmit={() => deleteDocument()}
      />
      <h1 className="page-title title-margin">{`${aircraft.title}: ${title}`}</h1>
      <Tabs
        defaultActiveKey="upload"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="upload" title="Upload" className="tab-bar">
          <Row>
            <Col md={6}>
              <div className="section-header">Upload Virtual Tour Images</div>
              <div className="section-text">
                Add the images taken from the Ricoh Theta SC2 camera here. Once
                uploaded, we'll notify you when your virtual tour is ready.
              </div>
              <hr />
              <label htmlFor="space_image_upload" className="file-upload">
                <div className="tour-image-div"></div>
                <div className="">
                  <span className="material-symbols-outlined file-upload-icon">
                    upload_file
                  </span>
                </div>
              </label>
              <input
                type="file"
                onChange={(e) => addTourImages(e)}
                accept="image/*"
                multiple
                id="tour_image_upload"
                style={{ display: "none" }}
              />
              <hr />
            </Col>
            <Col md={6}>
              <div className="d-grid">
                <Button
                  onClick={(e) => downloadAllPhotos(e)}
                  variant="primary"
                  bg="black"
                  className="login-submit-button"
                >
                  Download All Photos
                </Button>

                <hr />
              </div>
              {Object.values(imageUrls).map((image, i) => {
                return (
                  <div key={i}>
                    <AviationTourImageBox url={image.url} name={image.name} />
                  </div>
                );
              })}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="details" title="Details" className="tab-bar">
          <Row className="">
            <Col md={6} xs={12} className="">
              <div className="section-header">Details</div>
              <div className="subsection-header">Title</div>
              <EditableBox content={title} updateVal={setTitle} edit={edit} />
              <div className="subsection-header">Creation Date</div>
              <div className="section-text">
                {tourDetails.creation_date?.toDate().toLocaleString()}
              </div>
              <div className="subsection-header">Tour Id</div>
              <EditableBox
                content={virtualTourId}
                updateVal={setVirtualTourId}
                edit={edit}
              />
              <div className="subsection-header">Tour Status</div>
              <EditableDropdown
                edit={edit}
                updateVal={setStatus}
                selected={status}
                values={["upload", "processing", "complete"]}
              />
              <Button
                variant="primary"
                bg="black"
                className="login-submit-button"
                onClick={() => sendCompleteEmail()}
              >
                Send Tour Complete Email
              </Button>
            </Col>
            <Col md={6} xs={12}>
              <div className="section-header">Image</div>
              <label htmlFor="space_image_upload" className="file-upload">
                <img
                  className="space-image"
                  src={imageUrl}
                  width="100%"
                  height="350px"
                ></img>
                <div className="file-upload-content">
                  <span className="material-symbols-outlined file-upload-icon">
                    upload_file
                  </span>
                </div>
              </label>
              <input
                type="file"
                onChange={(e) => changeTourImage(e)}
                accept="image/*"
                id="space_image_upload"
                style={{ display: "none" }}
              />
            </Col>
          </Row>

          <hr />
          <Stack direction="horizontal">
            <Button
              variant="primary"
              bg="black"
              className="login-submit-button"
              onClick={() => toggleEdit()}
            >
              {edit ? "Confirm" : "Edit Details"}
            </Button>

            <Button
              variant="primary"
              bg="black"
              className="delete-button ms-auto"
              onClick={() => toggleDelete()}
            >
              {deleteT ? "Are You Sure?" : "Delete Tour"}
            </Button>
          </Stack>
        </Tab>

        <Tab eventKey="tour" title="Virtual Tour">
          <div className="section-header">Virtual Tour</div>
          <div>
            <iframe
              title="tour_space"
              width="100%"
              height="500"
              src={`https://embed.vuprop.com/tour/${virtualTourId}`}
              allowfullscreen
            ></iframe>
            <hr />
            <div className="section-header">Share</div>
            <Form>
              <Form.Group>
                <Form.Label className="subsection-header">Tour Link</Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    title="Copy"
                    className="grow"
                    onClick={() => copyLinkToClipboard()}
                  >
                    {copyTourClicked ? (
                      <span className="material-symbols-outlined icon-clicked">
                        content_copy
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon-plain">
                        content_copy
                      </span>
                    )}
                  </InputGroup.Text>
                  <Form.Control
                    name="tourLink"
                    placeholder={`https://embed.vuprop.com/tour/${virtualTourId}`}
                    disabled
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label className="subsection-header">
                  Embed Link
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    title="Copy"
                    className="grow"
                    onClick={() => copyEmbedToClipboard()}
                  >
                    {copyEmbedClicked ? (
                      <span className="material-symbols-outlined icon-clicked">
                        content_copy
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon-plain">
                        content_copy
                      </span>
                    )}
                  </InputGroup.Text>
                  <Form.Control
                    name="embedLink"
                    as="textarea"
                    rows={7}
                    placeholder={embedText}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
        </Tab>
        <Tab eventKey="documents" title="Documents">
          <DocumentsTabContent
            documents={tourDocuments}
            uploadDocument={uploadDocument}
            deleteDocument={toggleDeleteDocument}
          />
        </Tab>
      </Tabs>
      <br />
    </Container>
  );
};

export default AviationTourPage;
