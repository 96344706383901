import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerHomePage from "../aviation_pages/manager_pages/ManagerHomePage";
import ManagerToursPage from "./manager_pages/ManagerToursPage";
import OperatorToursPage from "./operator_pages/OperatorToursPage";

const AviationToursPage = (props) => {
  const user = useContext(UserContext);
  const getToursPage = () => {
    if (user.details.access === "manager") {
      return <ManagerToursPage />;
    } else if (user.details.access === "operator") {
      return <OperatorToursPage />;
    }
  };
  return getToursPage();
};

export default AviationToursPage;
