import { Button, Modal } from "react-bootstrap";

const ConfirmationBox = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pre-line">{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {props.cancelText}
        </Button>
        <Button className="delete-button" onClick={props.handleSubmit}>
          {props.confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationBox;
