import { Form } from "react-bootstrap";

const EditableDropdown = (props) => {
  return (
    <div>
      <Form className="mb-3">
        <Form.Select
          disabled={!props.edit}
          className="editable-box"
          value={props.selected}
          onChange={(event) => props.updateVal(event.target.value)}
        >
          {props.values.map((val, i) => {
            return <option key={i}>{val}</option>;
          })}
        </Form.Select>
      </Form>
    </div>
  );
};

export default EditableDropdown;
