import { Navbar, Container } from "react-bootstrap";
import React from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";

const LoggedOutNavbar = () => {
  return (
    <Navbar bg="black" expand="sm" variant="dark">
      <Container fluid>
        <Navbar.Brand href="https://www.vuprop.com">
          <img
            alt=""
            src={logo}
            width="150"
            height="50"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
         
      </Container>
    </Navbar>
  );
};

export default LoggedOutNavbar;
