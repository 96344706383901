import { useContext, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  addClient,
  addStaffToCompany,
  createStaffAccount,
  showHash,
} from "../../../firebase";
import EditableDropdown from "../../../components/EditableDropdown";
import EditableBox from "../../../components/EditableBox";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { UserContext } from "../../../providers/UserProvider";

const ManagerStaffAddPage = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const confirmAddClient = async (e) => {
    // Important Details
    if (firstName === "" || lastName === "" || email === "") {
      return;
    }
    let companyId = user.details.company_id;
    let client = {
      first_name: firstName,
      last_name: lastName,
      mobile: mobile,
      email: email,
      company: user.details.company,
      company_id: companyId,
      industry: "aviation",
      has_account: false,
      type: "client",
      access: props.access,
    };

    let res = await addStaffToCompany(companyId, client, props.access);
    if (res) {
      createStaffAccount(email);
      navigate(-1);
    }
  };

  return (
    <Container className="page-margin">
      <ConfirmationBox
        heading={`Create new ${props.access} account for ${user.details.company}`}
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        cancelText="Cancel"
        body={`Are you sure you wish to create a new ${props.access} account for ${user.details.company}.
        First Name: ${firstName}.
        Surname: ${lastName}.
        Email: ${email}.
        Phone number: ${mobile}.`}
        confirmText="Create Account"
        handleSubmit={() => confirmAddClient()}
      />
      <h1 className="page-title title-margin">
        {`Create ${props.access} account for ${user.details.company}`}
      </h1>
      <hr />
      <Form>
        <Row>
          <Col lg={6} sm={12}>
            <div className="subsection-header">First Name*</div>
            <EditableBox
              content={firstName}
              updateVal={setFirstName}
              edit={true}
            />
          </Col>
          <Col lg={6} sm={12}>
            <div className="subsection-header">Last Name*</div>
            <EditableBox
              content={lastName}
              updateVal={setLastName}
              edit={true}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <div className="subsection-header">Email Address*</div>
            <EditableBox content={email} updateVal={setEmail} edit={true} />
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="formBasicMobile">
              <Form.Label>Mobile Number</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">+353</InputGroup.Text>
                <Form.Control
                  aria-label="Mobile Number"
                  type="mobile"
                  aria-describedby="basic-addon1"
                  className="form-control"
                  name="mobile"
                  onChange={(event) => setMobile(event.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Button
          variant="primary"
          bg="black"
          className="login-submit-button"
          onClick={(e) => setShowConfirm(true)}
        >
          {`Create ${props.access} account`}
        </Button>
      </Form>
    </Container>
  );
};

export default ManagerStaffAddPage;
