import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
  Tab,
  Tabs,
  Toast,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  addTour,
  deleteAviationTour,
  deleteTourDocument,
  getAircraft,
  getTourDetails,
  getTourDocuments,
  getTourPhotos,
  increaseUnfinishedTours,
  sendPhotosUploadedEmail,
  uploadTourDocument,
  uploadTourPhoto,
} from "../../firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import EditableBox from "../../components/EditableBox";
import AviationTourImageBox from "../../components/aviation_components/AviationTourImageBox";
import ConfirmationBox from "../../components/ConfirmationBox";
import Loading from "../../components/Loading";
import DocumentsTabContent from "../../components/aviation_components/tour_details_components/DocumentsTabContent";
const AviationTourDetailsPage = () => {
  const [tourDetails, setTourDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [imageUrls, setImageUrls] = useState({});
  const [copyTourClicked, setCopyTourClicked] = useState(false);
  const [copyEmbedClicked, setCopyEmbedClicked] = useState(false);
  const [deleteT, setDeleteT] = useState(false);
  const [showDeleteDocConfirm, setShowDeleteDocConfirm] = useState(false);
  const [status, setStatus] = useState("");
  const [key, setKey] = useState("upload");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [aircraft, setAircraft] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);
  const [tourDocuments, setTourDocuments] = useState([]);
  const [deleteDocId, setDeleteDocId] = useState("");

  const user = useContext(UserContext);
  const navigate = useNavigate();
  let { tourId } = useParams();
  useEffect(() => {
    getTourDetails(user.user.uid, tourId).then((res) => {
      setTourDetails(res);
      setStatus(res.status);
      setTitle(res.title);
      if (res.status === "complete") {
        setKey("tour");
      }
      getTourPhotos(tourId).then((res) => {
        setImageUrls(
          Object.values(res).map((vals) => {
            return {
              url: Promise.resolve(vals.image_url),
              name: vals.image_name,
            };
          })
        );
        setLoadingImages(false);
      });

      getAircraft(res.aircraft_id).then((res) => {
        setAircraft(res);
        setLoading(false);
      });
    });
    getTourDocuments(tourId).then((res) => {
      setTourDocuments(res);
    });
  }, [tourId, user.user]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let tour = {
        ...tourDetails,
        title: title,
      };
      await addTour(tour);
      setTourDetails(tour);
    }
    setEdit(!edit);
  };

  const embedText = `<iframe 
    width='853' 
    height='480' 
    src='https://embed.vuprop.com/tour/${tourDetails.tour_id}' 
    frameborder='0' 
    allowfullscreen allow='xr-spatial-tracking'>
</iframe>
  `;

  const toggleDelete = async () => {
    setShowDeleteConfirm(true);
  };

  const toggleDeleteDocument = async (id) => {
    setShowDeleteDocConfirm(true);
    setDeleteDocId(id);
  };
  const deleteEntireTour = async () => {
    setShowDeleteConfirm(false);
    await deleteAviationTour(tourDetails);
    await navigate(-1);
  };

  const copyLinkToClipboard = () => {
    setCopyTourClicked(true);
    const link = `embed.vuprop.com/tour/${tourDetails.tour_id}`;
    navigator.clipboard.writeText(link);
  };

  const copyEmbedToClipboard = () => {
    setCopyEmbedClicked(true);
    navigator.clipboard.writeText(embedText);
  };

  const addTourImages = async (e) => {
    const files = e.target.files;
    let urls = Array.from(files).map((file) => {
      return { name: file.name, url: uploadTourPhoto(tourId, file) };
    });
    let newUrls = [].concat(imageUrls).concat(urls);
    let tempStatus = "processing";
    if (status === "complete") {
      tempStatus = status;
    }

    setImageUrls(newUrls);
    let tour = {
      ...tourDetails,
      status: tempStatus,
    };

    setStatus(tempStatus);
    setTourDetails(tour);
    let p1 = addTour(tour);
    let p2 = sendPhotosUploadedEmail(title, user.user.email, tourId);
    let p3 = increaseUnfinishedTours(user.details.company_id.toString());
    let allPromises = [p1, p2, p3].concat(urls.map((x) => x.url));
    Promise.all(allPromises).then(() => toggleShowUploadedToast());
  };
  const [showUploadedToast, setShowUploadedToast] = useState(false);
  const toggleShowUploadedToast = () =>
    setShowUploadedToast(!showUploadedToast);

  const uploadDocument = async (name, url) => {
    let id = uuidv4().toString();

    const document = {
      id: id,
      title: name,
      url: url,
    };

    await uploadTourDocument(tourId, document);
    setTourDocuments([...tourDocuments, document]);
  };

  const deleteDocument = async () => {
    await deleteTourDocument(tourId, deleteDocId);
    setTourDocuments(tourDocuments.filter((e) => e.id !== deleteDocId));
    setDeleteDocId("");
    setShowDeleteDocConfirm(false);
  };

  return (
    <Container className="page-margin">
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <ConfirmationBox
            heading="Delete Tour"
            show={showDeleteConfirm}
            handleClose={() => setShowDeleteConfirm(false)}
            cancelText="Cancel"
            body="Are you sure that you wish to delete this tour? This cannot be undone."
            confirmText="Delete Tour"
            handleSubmit={() => deleteEntireTour()}
          />

          <ConfirmationBox
            heading="Delete Document"
            show={showDeleteDocConfirm}
            handleClose={() => setShowDeleteDocConfirm(false)}
            cancelText="Cancel"
            body="Are you sure that you wish to delete this document?"
            confirmText="Delete Document"
            handleSubmit={() => deleteDocument()}
          />
          <h1 className="page-title title-margin">{`${aircraft.title}: ${title}`}</h1>
          {status === "complete" ? (
            <Tabs
              defaultActiveKey="tour"
              id="tab1"
              className="mb-3"
              onSelect={(k) => setKey(k)}
              activeKey={key}
            >
              <Tab eventKey="tour" title="Virtual Tour">
                <div className="section-header">Virtual Tour</div>
                <div>
                  <iframe
                    title="tour_space"
                    width="100%"
                    height="500"
                    src={`https://embed.vuprop.com/tour/${tourDetails.tour_id}`}
                    allowfullscreen
                  ></iframe>
                  <hr />
                  <div className="section-header">Share</div>
                  <Form.Group>
                    <Form.Label className="subsection-header">
                      Tour Link
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text
                        title="Copy"
                        className="grow"
                        onClick={() => copyLinkToClipboard()}
                      >
                        {copyTourClicked ? (
                          <span className="material-symbols-outlined icon-clicked">
                            content_copy
                          </span>
                        ) : (
                          <span className="material-symbols-outlined icon-plain">
                            content_copy
                          </span>
                        )}
                      </InputGroup.Text>
                      <Form.Control
                        name="tourLink"
                        placeholder={`https://embed.vuprop.com/tour/${tourDetails.tour_id}`}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="subsection-header">
                      Embed Link
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text
                        title="Copy"
                        className="grow"
                        onClick={() => copyEmbedToClipboard()}
                      >
                        {copyEmbedClicked ? (
                          <span className="material-symbols-outlined icon-clicked">
                            content_copy
                          </span>
                        ) : (
                          <span className="material-symbols-outlined icon-plain">
                            content_copy
                          </span>
                        )}
                      </InputGroup.Text>
                      <Form.Control
                        name="embedLink"
                        as="textarea"
                        rows={7}
                        placeholder={embedText}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </Tab>
              <Tab eventKey="details" title="Details" className="tab-bar">
                <div className="section-header">Details</div>
                <div className="subsection-header">Title</div>
                <EditableBox content={title} updateVal={setTitle} edit={edit} />
                <div className="subsection-header">Creation Date</div>
                <div className="section-text">
                  {tourDetails.creation_date?.toDate().toLocaleString()}
                </div>
                <div className="subsection-header">Tour Status</div>
                <div className="section-text">
                  {status === "upload"
                    ? "Please upload 360 photos from your Ricoh Theta SC2 to continue."
                    : status === "processing"
                    ? "We are currently processing your tour, we will notify you when it is finished."
                    : "Your tour has been completed!"}
                </div>

                <hr />
                <Stack direction="horizontal">
                  <Button
                    variant="primary"
                    bg="black"
                    className="login-submit-button"
                    onClick={() => toggleEdit()}
                  >
                    {edit ? "Confirm" : "Edit Details"}
                  </Button>

                  <Button
                    variant="primary"
                    bg="black"
                    className="delete-button ms-auto"
                    onClick={() => toggleDelete()}
                  >
                    {deleteT ? "Are You Sure?" : "Delete Tour"}
                  </Button>
                </Stack>
              </Tab>
              <Tab eventKey="documents" title="Documents">
                <DocumentsTabContent
                  documents={tourDocuments}
                  uploadDocument={uploadDocument}
                  deleteDocument={toggleDeleteDocument}
                />
              </Tab>
              <Tab eventKey="upload" title="Upload" tabClassName="ml-auto">
                <Row>
                  <Col md={6}>
                    <div className="section-header">
                      Upload Virtual Tour Images
                    </div>
                    <div className="section-text">
                      Add the images taken from the Ricoh Theta SC2 camera here.
                      Once uploaded, we'll notify you when your virtual tour is
                      ready.
                    </div>
                    <hr />
                    <label htmlFor="space_image_upload" className="file-upload">
                      <div className="tour-image-div"></div>
                      <div className="">
                        <span className="material-symbols-outlined file-upload-icon">
                          upload_file
                        </span>
                      </div>
                    </label>
                    <input
                      type="file"
                      onChange={(e) => addTourImages(e)}
                      accept="image/*"
                      multiple
                      id="space_image_upload"
                      style={{ display: "none" }}
                    />
                    <hr />
                  </Col>
                  <Col md={6}>
                    {loadingImages ? (
                      <div className="small-box-content d-flex align-items-center align-content-center">
                        <Loading center={true} />
                      </div>
                    ) : (
                      Object.values(imageUrls).map((image, i) => {
                        return (
                          <div key={i}>
                            <AviationTourImageBox
                              url={image.url}
                              name={image.name}
                            />
                          </div>
                        );
                      })
                    )}
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          ) : (
            <Tabs defaultActiveKey="upload" id="tab2" className="mb-3">
              <Tab eventKey="upload" title="Upload" className="tab-bar">
                <Row>
                  <Col md={6}>
                    <div className="section-header">
                      Upload Virtual Tour Images
                    </div>
                    <div className="section-text">
                      Add the images taken from the Ricoh Theta SC2 camera here.
                      Once uploaded, we'll notify you when your virtual tour is
                      ready.
                    </div>
                    <hr />
                    <label htmlFor="space_image_upload" className="file-upload">
                      <div className="tour-image-div"></div>
                      <div className="">
                        <span className="material-symbols-outlined file-upload-icon">
                          upload_file
                        </span>
                      </div>
                    </label>
                    <input
                      type="file"
                      onChange={(e) => addTourImages(e)}
                      accept="image/*"
                      multiple
                      id="space_image_upload"
                      style={{ display: "none" }}
                    />
                    <hr />
                  </Col>
                  <Col md={6}>
                    <Toast
                      className="m-auto my-3"
                      show={showUploadedToast}
                      onClose={toggleShowUploadedToast}
                    >
                      <Toast.Header>
                        <strong className="me-auto">Photos Uploaded</strong>
                      </Toast.Header>
                      <Toast.Body>
                        The photos have been successfully uploaded, and we've
                        notified an operator. You virtual tour should be ready
                        within 24 hours.
                      </Toast.Body>
                    </Toast>
                    {Object.values(imageUrls).map((image, i) => {
                      return (
                        <div key={i}>
                          <AviationTourImageBox
                            url={image.url}
                            name={image.name}
                          />
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="details" title="Details" className="tab-bar">
                <div className="section-header">Details</div>
                <div className="subsection-header">Title</div>
                <EditableBox content={title} updateVal={setTitle} edit={edit} />
                <div className="subsection-header">Creation Date</div>
                <div className="section-text">
                  {tourDetails.creation_date?.toDate().toLocaleString()}
                </div>
                <div className="subsection-header">Tour Status</div>
                <div className="section-text">
                  {status === "upload"
                    ? "Please upload 360 photos from your Ricoh Theta SC2 to continue."
                    : status === "processing"
                    ? "We are currently processing your tour, we will notify you when it is finished."
                    : "Your tour has been completed!"}
                </div>

                <hr />
                <Stack direction="horizontal">
                  <Button
                    variant="primary"
                    bg="black"
                    className="login-submit-button"
                    onClick={() => toggleEdit()}
                  >
                    {edit ? "Confirm" : "Edit Details"}
                  </Button>

                  <Button
                    variant="primary"
                    bg="black"
                    className="delete-button ms-auto"
                    onClick={() => toggleDelete()}
                  >
                    {deleteT ? "Are You Sure?" : "Delete Tour"}
                  </Button>
                </Stack>
              </Tab>
              <Tab eventKey="documents" title="Documents">
                <DocumentsTabContent
                  documents={tourDocuments}
                  uploadDocument={uploadDocument}
                  deleteDocument={toggleDeleteDocument}
                />
              </Tab>
              <Tab eventKey="tour" title="Virtual Tour">
                <div className="section-header">Virtual Tour</div>
                {status === "upload" ? (
                  <div width="100%" height="500" className="section-text">
                    Please upload 360 photos from your Ricoh Theta SC2 to
                    continue.
                  </div>
                ) : (
                  <div width="100%" height="500" className="section-text">
                    We are currently processing your tour, we will notify you
                    when it is finished.
                  </div>
                )}
              </Tab>
            </Tabs>
          )}

          <br />
        </div>
      )}
    </Container>
  );
};

export default AviationTourDetailsPage;
