import { Button, Col, Container, Row } from "react-bootstrap";

import { getTours } from "../firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import TourBox from "../components/TourBox";
import { Link } from "react-router-dom";
const ToursPage = () => {
  const [tours, setTours] = useState([]);

  const user = useContext(UserContext);

  useEffect(() => {
    getTours(user.user.uid).then((res) => {
      setTours(res);
    });
  }, [user.user]);

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">All Tours</h1>
      <hr />
      <Row>
        {tours.map((tour, i) => {
          return (
            <Col key={i} xl={3} lg={4} md={6} sm={6}>
              <TourBox tour={tour} />
            </Col>
          );
        })}
      </Row>
      <hr/>
      <Link to="/tours/add" className="text-link">
        <Button variant="primary" bg="black" className="login-submit-button">
          Add Tour
        </Button>
      </Link>
    </Container>
  );
};

export default ToursPage;
