import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AviationAircraftContent from "../../../components/aviation_components/dashboard_components/AviationAircraftContent";
import AviationBillingBoxContent from "../../../components/aviation_components/dashboard_components/AviationBillingBoxContent";
import AviationMostRecentTourContent from "../../../components/aviation_components/dashboard_components/AviationMostRecentTourContent";
import AviationNewToursContent from "../../../components/aviation_components/dashboard_components/AviationNewToursContent";
import AviationStaffBoxContent from "../../../components/aviation_components/dashboard_components/AviationStaffBoxContent";
import AviationWelcomeContent from "../../../components/aviation_components/dashboard_components/AviationWelcomeContent";
import BoxTitle from "../../../components/aviation_components/dashboard_components/BoxTitle";
import { UserContext } from "../../../providers/UserProvider";
import { useNavigate } from "react-router";
import logo from "../../../logo.png";
import {
  getCompanyAircraftCount,
  getCompanyBilling,
  getCompanyDetails,
  getCompanyEquipmentCount,
  getCompanyNewTours,
  getCompanyOperators,
  getCompanyTourCount,
  getStaffDetails,
  getCompanyMostRecentTour,
} from "../../../firebase";
import AviationEquipmentContent from "../../../components/aviation_components/dashboard_components/AviationEquipmentContent";

const ManagerHomePage = (props) => {
  const [staff, setStaff] = useState([]);
  const [bills, setBills] = useState([]);
  const [newToursCount, setNewToursCount] = useState(0);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [aircraftCount, setAircraftCount] = useState(0);
  const [loadingStaff, setLoadingStaff] = useState(true);
  const [loadingEquipment, setLoadingEquipment] = useState(true);
  const [loadingAircraft, setLoadingAircraft] = useState(true);
  const [loadingNewTours, setLoadingNewTours] = useState(true);
  const [loadingBilling, setLoadingBilling] = useState(true);
  const [mostRecentTours, setMostRecentTours] = useState([]);
  // const [company, setCompany] = useState(true);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  useEffect(() => {
    setLoadingStaff(true);
    setLoadingAircraft(true);
    setLoadingEquipment(true);
    getCompanyOperators(user.details.company_id).then((res) => {
      Promise.all(
        res.map(async (staff) => {
          return await getStaffDetails(staff.id);
        })
      ).then((res2) => {
        setStaff(res2);
        setLoadingStaff(false);
      });
    });

    getCompanyTourCount(user.details.company_id).then((res) => {
      setNewToursCount(res);
      setLoadingNewTours(false);
    });

    getCompanyAircraftCount(user.details.company_id).then((res) => {
      setAircraftCount(res);
      setLoadingAircraft(false);
    });

    getCompanyEquipmentCount(user.details.company_id).then((res) => {
      setEquipmentCount(res);
      setLoadingEquipment(false);
    });

    getCompanyBilling(user.details.company_id).then((res) => {
      setBills(
        res.map((x) =>
          Object.assign({}, x, { date_issued: x.date_issued.toDate() })
        )
      );
      setLoadingBilling(false);
    });

    getCompanyMostRecentTour(user.details.company_id).then((res) => {
      setMostRecentTours(res);
    });

    // getCompanyDetails(user.details.company_id).then((res) => {
    //   setCompany(res);
    // });
  }, [user.details]);

  const goToTours = () => {
    navigate("/tours");
  };

  const goToAircraft = () => {
    navigate("/aircraft");
  };

  const goToEquipment = () => {
    navigate("/equipment");
  };

  const goToStaff = () => {
    navigate("/staff");
  };

  const goToBilling = () => {
    navigate("/billing");
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Dashboard`}</h1>
      <Row>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div className="dashboard-box-small-black">
            <BoxTitle title="Welcome" color="black" />
            <AviationWelcomeContent logo_url={logo}/>
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div
            className="dashboard-box-small-black"
            onClick={() => goToTours()}
          >
            <BoxTitle title="View Your Tours" color="black" />
            <AviationNewToursContent
              num_new_tours={newToursCount}
              loading={loadingNewTours}
            />
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div
            className="dashboard-box-small-black"
            onClick={() => goToAircraft()}
          >
            <BoxTitle title="View Your Fleet" color="black" />
            <AviationAircraftContent
              num_aircraft={aircraftCount}
              loading={loadingAircraft}
            />
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <div
            className="dashboard-box-small-black"
            onClick={() => goToEquipment()}
          >
            <BoxTitle title="View Your Equipment" color="black" />
            <AviationEquipmentContent
              num_equipment={equipmentCount}
              loading={loadingEquipment}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className="dashboard-box-large">
            <BoxTitle title="View Your Staff" color="black" />
            <AviationStaffBoxContent
              staff={staff}
              loading={loadingStaff}
              goTo={() => goToStaff()}
            />
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className="dashboard-box-large">
            <BoxTitle title="Recently Uploaded Tours" color="black" />

            <AviationMostRecentTourContent
              loading={loadingBilling}
              tours={mostRecentTours}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ManagerHomePage;
