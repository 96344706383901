import { Button, Col, Container, Row, Stack, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import {
  addClient,
  getClientDetails,
  deleteClient,
  getClientTours,
  getTourDetails,
  createClientAccount,
  getSpaceDetails,
  getClientSpaces,
  addSpace,
  deleteStaff,
  getStaffTours,
  createStaffAccount,
  getAircraft,
} from "../../../firebase";
import { useContext, useEffect, useState } from "react";
import EditableBox from "../../../components/EditableBox";
import AviationTourBox from "../../../components/aviation_components/AviationTourBox";
import { addStaffToCompany, getStaffDetails } from "../../../firebase";
import EditableDropdown from "../../../components/EditableDropdown";
import { UserContext } from "../../../providers/UserProvider";
import ConfirmationBox from "../../../components/ConfirmationBox";
import Loading from "../../../components/Loading";
const ManagerStaffDetailsPage = (props) => {
  const [clientDetails, setClientDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTours, setLoadingTours] = useState(true);
  const [staffTours, setStaffTours] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [deleteT, setDeleteT] = useState(false);
  useEffect(() => {
    getStaffDetails(props.staffId).then((res) => {
      setClientDetails(res);
      setFirstName(res.first_name);
      setLastName(res.last_name);
      setEmail(res.email);
      setMobile(res.mobile);
      setAccess(res.access);
      setHasAccount(res.has_account);
      setLoading(false);
    });
    getStaffTours(props.staffId, false).then((res) => {
      Promise.all(
        res.map(async (tour) => {
          let details = await getTourDetails("", tour.tour_id);
          details.aircraft = await getAircraft(details.aircraft_id);
          return details;
        })
      ).then((res2) => {
        setStaffTours(res2);
        setLoadingTours(false);
      });
    });
  }, [props.staffId]);

  const toggleEdit = async (e) => {
    if (edit === true) {
      let client = {
        ...clientDetails,
        first_name: firstName,
        last_name: lastName,
        mobile: mobile,
        email: email,
        access: access,
      };
      await addStaffToCompany(
        user.details.company_id,
        client,
        clientDetails.access,
        true
      );
    }
    setEdit(!edit);
  };

  const deleteStaffMember = async () => {
    await deleteStaff(clientDetails);
    navigate(-1);
  };

  const toggleDelete = async () => {
    setShowDeleteConfirm(true);
  };

  return (
    <Container className="page-margin">
      {loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <div>
          <ConfirmationBox
            heading={`Delete Operator: ${email}`}
            show={showDeleteConfirm}
            handleClose={() => setShowDeleteConfirm(false)}
            cancelText="Cancel"
            body="Are you sure that you wish to delete this operator's account? This cannot be undone."
            confirmText="Delete Operator"
            handleSubmit={() => deleteStaffMember()}
          />
          <h1 className="page-title title-margin">{email}</h1>

          <Tabs
            defaultActiveKey="details"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="details" title="Details">
              <div className="section-header">Staff Details</div>
              <Row>
                <Col xs={6}>
                  <div className="subsection-header">Firstname</div>
                  <EditableBox
                    content={firstName}
                    updateVal={setFirstName}
                    edit={edit}
                  />
                </Col>
                <Col xs={6}>
                  <div className="subsection-header">Surname</div>
                  <EditableBox
                    content={lastName}
                    updateVal={setLastName}
                    edit={edit}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <div className="subsection-header">Email</div>
                  <EditableBox
                    content={email}
                    updateVal={setEmail}
                    edit={false}
                  />
                </Col>
                <Col xs={6}>
                  <div className="subsection-header">Mobile</div>
                  <EditableBox
                    content={mobile}
                    updateVal={setMobile}
                    edit={edit}
                  />
                </Col>
              </Row>
              <hr />
              <Stack direction="horizontal">
                <Button
                  variant="primary"
                  bg="black"
                  className="login-submit-button"
                  onClick={() => toggleEdit()}
                >
                  {edit ? "Confirm" : "Edit Operator"}
                </Button>
                <Button
                  variant="primary"
                  bg="black"
                  className="delete-button ms-auto"
                  onClick={() => toggleDelete()}
                >
                  {deleteT ? "Are You Sure?" : "Delete Operator"}
                </Button>
              </Stack>
            </Tab>
            <Tab eventKey="tours" title="Tours">
              <div className="section-header mb-3">Created Tours</div>
              <hr />
              {loadingTours ? (
                <div className="small-box-content d-flex align-items-center align-content-center">
                  <Loading center={true} />
                </div>
              ) : (
                <div>
                  {staffTours.length > 0 ? (
                    <Row>
                      {staffTours.map((tour, i) => {
                        return (
                          <div key={i}>
                            <AviationTourBox
                              tour={tour}
                              industry={"aviation"}
                              key={i}
                            />
                            <hr />
                          </div>
                        );
                      })}
                    </Row>
                  ) : (
                    <div className="my-3">
                      This operator has not created any tours.
                    </div>
                  )}
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      )}
    </Container>
  );
};

export default ManagerStaffDetailsPage;
