import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import CompanyBox from "../../../components/admin_components/CompanyBox";
import {
  getAllCompanies,
  getCompanyDetails,
  showHash,
} from "../../../firebase";
import AviationCompanyAircraftPage from "./AviationCompanyAircraftPage";
import AviationCompanyBillingPage from "./AviationCompanyBillingPage";
import AviationCompanyDetailsPage from "./AviationCompanyDetailsPage";
import AviationCompanyEquipmentPage from "./AviationCompanyEquipmentPage";
import AviationCompanyHangarsPage from "./AviationCompanyHangarsPage";
import AviationCompanyStaffPage from "./AviationCompanyStaffPage";
import AviationCompanyToursPage from "./AviationCompanyToursPage";

const AviationCompanyPage = () => {
  const [details, setDetails] = useState({});
  const [title, setTitle] = useState("");
  const [companyId, setCompanyId] = useState("default");

  let { companyName } = useParams();
  useEffect(() => {
    let id = showHash(companyName).toString();
    setCompanyId(id);
    getCompanyDetails(id).then((res) => {
      setTitle(res.name);
      setDetails(res);
    });
  }, []);
  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin mb-3">{title}</h1>
      <Tabs
        defaultActiveKey="details"
        id="company-details-category-tabs"
        className="mb-3"
      >
        <Tab eventKey="details" title="Details">
          <AviationCompanyDetailsPage id={companyId} />
        </Tab>
        <Tab eventKey="staff" title="Staff">
          <AviationCompanyStaffPage id={companyId} company_name={companyName} />
        </Tab>
        <Tab eventKey="hangars" title="Equipment">
          <AviationCompanyEquipmentPage id={companyId} />
        </Tab>
        <Tab eventKey="aircraft" title="Aircraft">
          <AviationCompanyAircraftPage id={companyId} />
        </Tab>
        <Tab eventKey="tours" title="Tours">
          <AviationCompanyToursPage id={companyId} />
        </Tab>
        <Tab eventKey="billing" title="Billing">
          <AviationCompanyBillingPage
            id={companyId}
            softwarePrice={details.software_price_monthly}
            tourPrice={details.price_per_tour}
            billingName={details.billing_name}
            billingAddress={details.billing_address}
            billingCity={details.billing_city}
            billingCountry={details.billing_country}
            billingPostal={details.billing_postal}
            hostingPrice={details.hosting_fee}
            accountPrice={details.account_fee}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AviationCompanyPage;
