import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import AviationBillingBox from "../../../components/aviation_components/AviationBillingBox";
import EditableBox from "../../../components/EditableBox";
import Loading from "../../../components/Loading";
import { generateHtmlInvoice } from "../../../email_templates/Invoice";

import { v4 as uuidv4 } from "uuid";
import {
  addInvoice,
  getCompanyBilling,
  getCompanyStaffCount,
  getCompanyTourCount,
  getCompanyToursProcessedThisMonth,
  updateCompanyBillingInfo,
} from "../../../firebase";

const AviationCompanyBillingPage = (props) => {
  const [monthlyTours, setMonthlyTours] = useState(0);
  const [tourPrice, setTourPrice] = useState(0);
  const [softwarePrice, setSoftwarePrice] = useState(0);
  const [hostingPrice, setHostingPrice] = useState(0);
  const [accountPrice, setAccountPrice] = useState(0);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [totalToursHosting, setTotalToursHosting] = useState(0);
  const [invoices, setInvoices] = useState([]);

  const [companyBillingName, setCompanyBillingName] = useState("");
  const [companyBillingAddress, setCompanyBillingAddress] = useState("");
  const [companyBillingCity, setCompanyBillingCity] = useState("");
  const [companyBillingCountry, setCompanyBillingCountry] = useState("");
  const [companyBillingPostal, setCompanyBillingPostal] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [edit, setEdit] = useState(false);
  const [editPrice, setEditPrice] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyBilling(props.id).then((res) => {
      setInvoices(
        res.map((x) =>
          Object.assign({}, x, { date_issued: x.date_issued.toDate() })
        )
      );
      setInvoiceNumber(res.length + 1);
      setLoading(false);
    });

    getCompanyTourCount(props.id, true).then((res) => {
      console.log(res);
      setTotalToursHosting(res);
    });

    getCompanyStaffCount(props.id).then((res) => {
      console.log(res);
      setTotalAccounts(res);
    });
    var date = new Date().toLocaleDateString("en-UK");
    date = date.substring(date.indexOf("/") + 1).replace("/", "_");
    getCompanyToursProcessedThisMonth(props.id, date).then((res) => {
      setMonthlyTours(res);
    });

    setSoftwarePrice(props.softwarePrice);
    setTourPrice(props.tourPrice);

    setCompanyBillingName(props.billingName);
    setCompanyBillingAddress(props.billingAddress);
    setCompanyBillingCity(props.billingCity);
    setCompanyBillingCountry(props.billingCountry);
    setCompanyBillingPostal(props.billingPostal);

    setHostingPrice(props.hostingPrice);
    setAccountPrice(props.accountPrice);
  }, [props]);

  const totalPrice = () => {
    return (
      monthlyTours * tourPrice +
      totalAccounts * accountPrice +
      totalToursHosting * hostingPrice
    );
  };

  const createInvoice = async () => {
    let id = uuidv4().toString();
    let invoiceDetails = {
      invoice_number: invoiceNumber,
      billing_name: companyBillingName,
      billing_address: companyBillingAddress,
      billing_city: companyBillingCity,
      billing_country: companyBillingCountry,
      billing_postal: companyBillingPostal,
      tour_quantity: monthlyTours,
      tour_price: tourPrice,
      hosting_price: hostingPrice,
      account_price: accountPrice,
      total_accounts: totalAccounts,
      total_tours_hosting: totalToursHosting,
      total_price: totalPrice(),
      creation_date: new Date().toLocaleDateString("en-UK"),
      due_date: new Date(
        new Date().setMonth(new Date().getMonth() + 1)
      ).toLocaleDateString("en-UK"),
      company_id: props.id,
      id: id,
    };
    let htmlString = generateHtmlInvoice(invoiceDetails);

    const element = document.createElement("a");
    const file = new Blob([htmlString], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "VuProp_Invoice.html";
    element.target = "_blank";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    const saveInvoice = async () => {
      await addInvoice(props.id, invoiceDetails);
      setInvoices([
        Object.assign({}, invoiceOverview, { new: false }),
        ...invoices,
      ]);
    };

    const invoiceOverview = {
      id: id,
      amount: totalPrice(),
      date_issued: new Date(),
      new: true,
      number: invoiceNumber,
      saveInvoice: saveInvoice,
    };

    setInvoices([invoiceOverview, ...invoices]);
  };

  const editBillingInfo = async () => {
    if (edit) {
      if (
        companyBillingName === "" ||
        companyBillingAddress === "" ||
        companyBillingCity === "" ||
        companyBillingCountry === "" ||
        companyBillingPostal === ""
      ) {
        return;
      }

      setEdit(false);

      const billingInfo = {
        billing_name: companyBillingName,
        billing_address: companyBillingAddress,
        billing_city: companyBillingCity,
        billing_country: companyBillingCountry,
        billing_postal: companyBillingPostal,
      };

      await updateCompanyBillingInfo(props.id, billingInfo);
    } else {
      setEdit(true);
    }
  };

  const editBillingPrice = async () => {
    if (editPrice) {
      if (
        hostingPrice === "" ||
        isNaN(hostingPrice) ||
        hostingPrice < 0 ||
        accountPrice === "" ||
        isNaN(accountPrice) ||
        accountPrice < 0 ||
        tourPrice === "" ||
        isNaN(tourPrice) ||
        tourPrice < 0
      ) {
        return;
      }

      setEditPrice(false);

      const priceInfo = {
        hosting_fee: parseInt(hostingPrice),
        account_fee: parseInt(accountPrice),
        price_per_tour: parseInt(tourPrice),
      };

      await updateCompanyBillingInfo(props.id, priceInfo);
    } else {
      setEditPrice(true);
    }
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title">{`Billing`}</h1>
      <hr />
      <Stack direction="horizontal">
        <div className="section-header">Invoice Details</div>
        <Button
          variant="primary"
          bg="black"
          className="login-submit-button ms-auto"
          onClick={() => editBillingInfo()}
        >
          {edit ? "Save" : "Edit Details"}
        </Button>
      </Stack>
      <hr />
      <Row>
        <Col xs={6}>
          <div className="subsection-header">Company Name</div>
          <EditableBox
            content={companyBillingName}
            updateVal={setCompanyBillingName}
            edit={edit}
          />
        </Col>
        <Col xs={6}>
          <div className="subsection-header">Address</div>
          <EditableBox
            content={companyBillingAddress}
            updateVal={setCompanyBillingAddress}
            edit={edit}
          />
        </Col>

        <Col xs={4}>
          <div className="subsection-header">City</div>
          <EditableBox
            content={companyBillingCity}
            updateVal={setCompanyBillingCity}
            edit={edit}
          />
        </Col>
        <Col xs={4}>
          <div className="subsection-header">Country</div>
          <EditableBox
            content={companyBillingCountry}
            updateVal={setCompanyBillingCountry}
            edit={edit}
          />
        </Col>
        <Col xs={4}>
          <div className="subsection-header">Postal</div>
          <EditableBox
            content={companyBillingPostal}
            updateVal={setCompanyBillingPostal}
            edit={edit}
          />
        </Col>
      </Row>
      <hr />
      <Stack direction="horizontal">
        <div className="section-header">
          Invoice for {new Date().toLocaleString("default", { month: "long" })}
        </div>
        <Button
          variant="primary"
          bg="black"
          className="login-submit-button ms-auto"
          onClick={() => editBillingPrice()}
        >
          {edit ? "Save" : "Edit Details"}
        </Button>
      </Stack>
      <hr />

      <hr />
      <Row>
        <Col xs={6}>
          <div className="subsection-header">Tours This Month</div>
          <EditableBox
            content={monthlyTours}
            updateVal={setMonthlyTours}
            edit={false}
          />
        </Col>
        <Col xs={6}>
          <div className="subsection-header">Tour Processing Fee</div>
          <EditableBox
            content={tourPrice}
            updateVal={setTourPrice}
            edit={editPrice}
          />
        </Col>

        <Col xs={6}>
          <div className="subsection-header">Total Tours Hosting</div>
          <EditableBox
            content={totalToursHosting}
            updateVal={setTotalToursHosting}
            edit={false}
          />
        </Col>
        <Col xs={6}>
          <div className="subsection-header">Hosting Fee (Monthly)</div>
          <EditableBox
            content={hostingPrice}
            updateVal={setHostingPrice}
            edit={editPrice}
          />
        </Col>

        <Col xs={6}>
          <div className="subsection-header">Total Accounts</div>
          <EditableBox
            content={totalAccounts}
            updateVal={setTotalAccounts}
            edit={false}
          />
        </Col>
        <Col xs={6}>
          <div className="subsection-header">Account Fee</div>
          <EditableBox
            content={accountPrice}
            updateVal={setAccountPrice}
            edit={editPrice}
          />
        </Col>

        {/* <Col xs={4}>
          <div className="subsection-header">Software Price (Monthly)</div>
          <EditableBox
            content={softwarePrice}
            updateVal={setSoftwarePrice}
            edit={false}
          />
        </Col> */}
        <Col xs={6}>
          <div className="subsection-header">Invoice Number</div>
          <EditableBox
            content={invoiceNumber}
            updateVal={() => {}}
            edit={false}
          />
        </Col>
        <Col xs={6}>
          <div className="subsection-header">Total Price</div>
          <EditableBox
            content={totalPrice()}
            updateVal={() => {}}
            edit={false}
          />
        </Col>
      </Row>
      <hr />
      <Stack direction="horizontal">
        <div className="section-header">Past Invoices</div>
        <Button
          variant="primary"
          bg="black"
          className="login-submit-button ms-auto"
          onClick={() => createInvoice()}
        >
          Generate Invoice
        </Button>
      </Stack>
      <hr />

      <div>
        {loading ? (
          <div className="small-box-content d-flex align-items-center align-content-center">
            <Loading center={true} />
          </div>
        ) : (
          <Stack>
            {invoices.map((bill, i) => {
              return (
                <div key={i}>
                  <AviationBillingBox bill={bill} />
                  <hr className="mx-3" />
                </div>
              );
            })}
            <br />
            <br />
          </Stack>
        )}
      </div>
    </Container>
  );
};

export default AviationCompanyBillingPage;
