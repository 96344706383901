import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import React from "react";
import logo from "../../logo.png";
import { Link } from "react-router-dom";

const AviationOperatorNavbar = () => {
  return (
    <Navbar bg="black" expand="lg" variant="dark">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={logo}
            width="150"
            height="50"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <span className="material-symbols-outlined white-icon">menu</span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="justify-content-end nav-text-colour">
            <Nav.Link
              as={Link}
              to="/aircraft"
              className="navigation-item ms-auto"
            >
              Aircraft
            </Nav.Link>
            <Nav.Link as={Link} to="/tours" className="navigation-item ms-auto">
              Tours
            </Nav.Link>

            <NavDropdown
              align="end"
              title="Settings"
              className="navigation-item ms-auto"
              id="navbarScrollingDropdown"
              drop="down-centered"
            >
              <NavDropdown.Item
                as={Link}
                to="/account"
                className="navigation-item ms-auto"
              >
                Account
              </NavDropdown.Item>
              <NavDropdown.Divider className="white-divider" />
              <NavDropdown.Item
                as={Link}
                to="/help"
                className="navigation-item ms-auto"
              >
                Help
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AviationOperatorNavbar;
