import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { auth, resetUserPassword } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function ForgotPasswordPage() {
  const [hasReset, setHasReset] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const navigate = useNavigate();
  const handleValidation = async (event) => {
    let formIsValid = true;

    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const resetPassword = async () => {
    if (handleValidation()) {
      if (await resetUserPassword(email)){
        setHasReset(true)
      } else {
        setemailError("Something went wrong please try again.")
      }
    }
  };

  return (
    <Container >
      <Container className="login-form-box">
      <h1 className="login-form-title">Forgot Password</h1>
      <hr />
      {hasReset ? (
        <div className="m-3 text-center fs-5">
            An email has been sent with further instructions
        </div>
      ) : 
      <Form className="login-form">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label >Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            aria-describedby="emailHelpBlock"
            onChange={(event) => setEmail(event.target.value)}
          />
          <Form.Text id="emailHelpBlock" muted>
            {emailError}
          </Form.Text>
        </Form.Group>
        <div className="forgot-password-description">
            We'll send an email so you can reset your password.
        </div>
        
        <div className="d-grid">
          <Button variant="primary" bg="black" className="login-submit-button" onClick={() => resetPassword()}>
            Reset Password
          </Button>
        </div>
      </Form>}

      <Link
        to={`/`}
        className="text-link d-grid m-3 text-center"
      >
        <div className="forgot-password-text">Login Instead</div>
      </Link>
      </Container>
      
    </Container>
  );
}

export default ForgotPasswordPage;
