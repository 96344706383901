import { useEffect } from "react";
import { Button, Card, Col, Row, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import StaffBox from "../../admin_components/StaffBox";
import Loading from "../../Loading";

const AviationStaffBoxContent = (props) => {
  useEffect(() => {}, [props]);
  return (
    <div className="">
      {props.loading ? (
        <div className="small-box-content d-flex align-items-center align-content-center">
          <Loading center={true} />
        </div>
      ) : (
        <Stack style={{ height: "210px" }} className>
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Surname</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {props.staff.length > 0 ? (
                props.staff.map((operator, i) => {
                  if (i > -1 && i < 3) {
                    return (
                      <StaffBox
                        staff={operator}
                        company={props.company_name}
                        index={i + 1}
                        key={i}
                      />
                    );
                  }
                  return "";
                })
              ) : (
                <div className="m-3">
                  There are currently no operator accounts
                </div>
              )}
            </tbody>
          </Table>
          <Button
            onClick={() => props.goTo()}
            variant="primary"
            bg="black"
            className={"login-submit-button mx-3 mt-auto mb-3"}
          >
            All Staff
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default AviationStaffBoxContent;
