import { Container } from "react-bootstrap";
import ManagerHomePage from "../aviation_pages/manager_pages/ManagerHomePage";
import OperatorAircraftPage from "../aviation_pages/operator_pages/OperatorAircraftListPage";
import OperatorHomePage from "../aviation_pages/operator_pages/OperatorHomePage";

const ClientHomePage = (props) => {
  const getHomePage = () => {
    if (props.industry === "aviation") {
      if (props.access === "manager") {
        return <ManagerHomePage />;
      } else if (props.access === "operator") {
        return <OperatorAircraftPage />;
      }
    }
  };
  return getHomePage();
};

export default ClientHomePage;
