import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import AviationBillingBox from "../../../components/aviation_components/AviationBillingBox";
import EditableBox from "../../../components/EditableBox";
import Loading from "../../../components/Loading";
import { generateHtmlInvoice } from "../../../email_templates/Invoice";

import { v4 as uuidv4 } from "uuid";
import {
  addInvoice,
  getCompanyBilling,
  getCompanyDetails,
  getCompanyToursProcessedThisMonth,
  sendCameraRequestEmail,
  updateCompanyBillingInfo,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import AviationPriceBox from "../../../components/aviation_components/AviationPriceBox";
import InvoiceList from "../../../components/InvoiceList";
import { useNavigate } from "react-router";

const ManagerOrderCameraPage = () => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const user = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(user.details.email);
  }, [user]);

  const orderEquipment = async () => {
    if (email === "" || numberOfCameras < 1) {
      return;
    }
    const request = {
      contactEmail: email,
      accountEmail: user.details.email,
      cameras: numberOfCameras,
      company: user.details.company,
    };
    await sendCameraRequestEmail(request);
    setEmailSend(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container className="page-margin">
      <h1 className="page-title title-margin">{`Equipment`}</h1>
      <hr />
      <Row className="">
        <Col md={6}>
          {!emailSend ? (
            <div className="order-camera-form">
              <div className="form-title">{`Order Camera`}</div>
              <div className="form-text">
                Let us know what you need and we'll email you back shortly.
              </div>
              <br />
              <div className="form-text text-start">Email</div>

              <EditableBox content={email} updateVal={setEmail} edit={true} />
              <br />
              <div className="form-text text-start">Number of Cameras</div>
              <EditableBox
                content={numberOfCameras}
                updateVal={setNumberOfCameras}
                edit={true}
                type="numeric"
              />
              <br />
              <Stack direction="horizontal" className="">
                <Button
                  variant="primary"
                  bg="black"
                  className={"login-submit-button m-auto"}
                  onClick={() => orderEquipment()}
                >
                  Order Camera
                </Button>
              </Stack>
            </div>
          ) : (
            <div className="order-camera-form">
              <div className="form-title">{`Order Camera`}</div>
              <div className="form-text">
                Thank you. We will contact you shortly.
              </div>
              <br />
              <Stack direction="horizontal" className="">
                <Button
                  variant="primary"
                  bg="black"
                  className={"login-submit-button m-auto"}
                  onClick={() => goBack()}
                >
                  Go Back
                </Button>
              </Stack>
            </div>
          )}
        </Col>

        <Col md={6}>
          <img src="https://gspplatform.cfemedia.com/pe/file/image/0/200/id/60facbe282bcfa234a01c9f6"></img>
        </Col>
      </Row>
    </Container>
  );
};

export default ManagerOrderCameraPage;
