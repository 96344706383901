import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const AviationHangarBox = (props) => {
  return (
    <Link
      className="text-link"
      to={
        props.isAdmin
          ? `hangar/${props.hangar.id}`
          : `/hangars/${props.hangar.id}`
      }
    >
      <Card style={{ marginBottom: 25 }} className="grow">
        <Card.Img
          className="card-image-underline"
          variant="top"
          height="180"
          width="260"
          src={
            props.hangar.hangar_image_url ? props.hangar.hangar_image_url : ""
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "https://via.placeholder.com/500x260?text=Hangar";
          }}
          style={{ maxHeight: "260px", maxWidth: "700px", objectFit: "fit" }}
        />
        <div className=" text-truncate tour-box-title">
          {props.hangar.title}
        </div>
        <div className="tour-box-text">
          {props.hangar.country ? props.hangar.country : "No Address"}
        </div>
      </Card>
    </Link>
  );
};

export default AviationHangarBox;
