import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const SpaceBox = (props) => {
  return (
    <Link
      className="text-link"
      to={
        props.isAdmin
          ? `/clients/${props.clientId}/spaces/${props.space.id}`
          : `/spaces/${props.space.id}`
      }
    >
      <Card style={{ marginBottom: 25 }} className="grow">
        <Card.Img
          variant="top"
          height="180"
          width="260"
          src={props.space.space_image_url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "https://via.placeholder.com/500x260?text=My+Space";
          }}
          style={{ maxHeight: "260px", maxWidth: "700px", objectFit: "fit" }}
        />
        <div className=" text-truncate tour-box-title">{props.space.title}</div>
        <div className="tour-box-text">
          Number of Tours: {props.space.tours ? props.space.tours.length : 0}
        </div>
        <div className="tour-box-text">
          {props.space.city ? props.space.city : "No Address"}
        </div>
      </Card>
    </Link>
  );
};

export default SpaceBox;
