import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ManagerAircraftListPage from "./manager_pages/ManagerAircraftListPage";
import ManagerEquipmentListPage from "./manager_pages/ManagerEquipmentListPage";
import ManagerEquipmentPage from "./manager_pages/ManagerEquipmentPage";
import ManagerHangarListPage from "./manager_pages/ManagerHangarListPage";
import OperatorAircraftListPage from "./operator_pages/OperatorAircraftListPage";

const AviationEquipmentPage = (props) => {
  const user = useContext(UserContext);
  const getEquipmentPage = () => {
    if (user.details.access === "manager") {
      return <ManagerEquipmentPage />;
    } else if (user.details.access === "operator") {
      return <div />;
    }
  };
  return getEquipmentPage();
};

export default AviationEquipmentPage;
