import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import TourBox from "../../components/TourBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addSpace,
  getClientSpaces,
  getClientTours,
  getSpaceDetails,
  getTourDetails,
  sendSpaceRequestEmail,
  sendTourRequestEmail,
} from "../../firebase";
import SpaceBox from "../../components/SpaceBox";
const ClientBrandedAdPage = (props) => {
  const [spaces, setSpaces] = useState([]);
  const [hasRequested, setHasRequested] = useState(false);
  const [spaceName, setSpaceName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [numberOfTours, setNumberOfTours] = useState(1);
  const [otherDetails, setOtherDetails] = useState("");
  const [validated, setValidated] = useState(false);

  const user = useContext(UserContext);
  const navigate = useNavigate();
  let { spaceId } = useParams();
  useEffect(() => {
    setContactEmail(user.user.email);
  }, [user.user]);

  const requestFloorplan = async () => {
    if (contactEmail === "") {
      setValidated(true);
    } else {
      setValidated(false);
      const payload = {
        tour_id: props.tour.id,
        contact_email: contactEmail,
        account_email: user.user.email,
        other_details: otherDetails ? otherDetails : "NONE",
      };
      let res = await sendTourRequestEmail(payload);
      if (res) {
        setHasRequested(true);
      }
    }
  };

  const goBack = async () => {
    navigate(-1);
  };

  return (
    <Container className="page-margin">
      <img
        className="ad-banner-image"
        src="https://www.vuprop.com/wp-content/uploads/2022/03/Blue-Gray-Classic-Corporate-Business-Promo-LinkedIn-Article-Cover-Image-5.png"
      />
      <div className="page-title text-center m-3">
        Checkout one of our Branded Tours
      </div>

      <Row className="m-3">
        <Col md={6}>
          <iframe
            title="tour_space"
            width="100%"
            height="500"
            src={`https://embed.vuprop.com/tour/fvekeo0tIvqSyi5y0x9j`}
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
        </Col>
        <Col md={6}>
          <div className="ad-banner">Boost Sales</div>
          <div className="align-middle m-3">
            Maximize your property marketing efforts by utilizing both immersive
            virtual tours and traditional methods like schematic floor plans.
            Buyers, especially new homeowners, rely on floor plans to grasp the
            layout and dimensions of a property. Adding floor plans to your
            marketing portfolio will enhance the visual representation of your
            property and lead to increased sales opportunities. Ideal for both
            residential and commercial properties, schematic floor plans are a
            must-have for your property marketing strategy.
          </div>
        </Col>
      </Row>
      <Button variant="primary" bg="black" className="login-submit-button">
        Request Now
      </Button>
    </Container>
  );
};

export default ClientBrandedAdPage;
