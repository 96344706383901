import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import React from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";

const LoggedInNavbar = () => {
  return (
    <Navbar bg="black" expand="lg" variant="dark">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={logo}
            width="150"
            height="50"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <span className="material-symbols-outlined white-icon">menu</span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="justify-content-end nav-text-colour">
            <Nav.Link
              as={Link}
              to="/clients"
              className="navigation-item ms-auto"
            >
              Clients
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/account"
              className="navigation-item ms-auto"
            >
              My Account
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default LoggedInNavbar;
