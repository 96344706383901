import { useEffect } from "react";
import { Button, Stack, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router";

function InvoiceList(props) {
  const navigate = useNavigate();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    console.log(props.invoices);
  }, [props]);
  const goToPDF = (id, newInvoice) => {
    if (newInvoice) {
      return;
    }
    navigate(`/invoices/${id}`);
  };
  return (
    <Table className="billing-table">
      <thead className="billing-table-head">
        <tr>
          <th>Invoice</th>
          <th>Billing Date</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.invoices.length > 0 ? (
          props.invoices.map((invoice, i) => {
            return (
              <tr key={i}>
                <td className="">Invoice #{invoice.number}</td>
                <td className="">
                  {invoice.date_issued.toLocaleDateString(undefined, options)}
                </td>
                <td className="">EUR €{invoice.amount}</td>
                <td className="">
                  <Stack
                    direction="horizontal"
                    className="billing-box-link justify-content-end"
                    onClick={() => goToPDF(invoice.id, invoice.new)}
                  >
                    {invoice.new ? (
                      <Button
                        variant="primary"
                        bg="black"
                        className="login-submit-button ms-auto"
                        onClick={() => invoice.saveInvoice()}
                      >
                        Save
                      </Button>
                    ) : (
                      <>
                        <span
                          className={
                            "material-symbols-outlined billing-box-icon"
                          }
                        >
                          download
                        </span>
                        <div className="billing-box-text">View</div>
                      </>
                    )}
                  </Stack>
                </td>
              </tr>
            );
          })
        ) : (
          <></>
        )}
      </tbody>
    </Table>
  );
}

export default InvoiceList;
