import { useEffect } from "react";
import { Form } from "react-bootstrap";

const EditableBox = (props) => {
  return (
    <div>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            disabled={!props.edit}
            className="editable-box"
            type={props.type}
            as={props.as}
            rows={props.row}
            onChange={(event) => props.updateVal(event.target.value)}
            placeholder={props.content}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditableBox;
