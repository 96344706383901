import AviationManagerNavbar from "./AviationManagerNavbar";
import AviationOperatorNavbar from "./AviationOperatorNavbar";

const AviationNavbar = (props) => {
  const getNavbar = () => {
    if (props.access === "manager") {
      return <AviationManagerNavbar />;
    } else if (props.access === "operator") {
      return <AviationOperatorNavbar />;
    }
  };
  return getNavbar();
};

export default AviationNavbar;
