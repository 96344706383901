import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

const LoggedOutFooter = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col></Col>
          <Col>
            <a
              href="https://www.linkedin.com/company/vuprop"
              className="text-link"
              target="_blank"
            >
              <i className="fa-brands fa-linkedin m-2 fa-lg grow-2"></i>
            </a>
            <a
              href="https://www.facebook.com/VuProp-110691664847472/?view_public_for=110691664847472"
              className="text-link grow"
              target="_blank"
            >
              <i className="fa-brands fa-facebook-f m-2 fa-lg grow-2"></i>
            </a>
            <a
              href="https://www.instagram.com/vuprop/"
              className="text-link grow"
              target="_blank"
            >
              <i className="fa-brands fa-instagram m-2 fa-lg grow-2"></i>
            </a>
          </Col>
          <Col>©2024 VuProp</Col>
        </Row>
      </Container>
    </footer>
  );
};

export default LoggedOutFooter;
