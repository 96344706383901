import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  addClient,
  addHangar,
  addStaffToCompany,
  showHash,
} from "../../../firebase";
import { v4 as uuidv4 } from "uuid";
import EditableBox from "../../../components/EditableBox";
import ConfirmationBox from "../../../components/ConfirmationBox";

const AviationCompanyHangarAddPage = () => {
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [hangarImageUrl, setHangarImageUrl] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();
  let { companyName } = useParams();
  const confirmAddHangar = async (e) => {
    // Important Details
    if (title === "" || address === "" || city === "" || country === "") {
      return;
    }
    let id = uuidv4().toString();
    let companyId = showHash(companyName);
    let hangar = {
      id: id,
      title: title,
      address: address,
      address_2: address2,
      city: city,
      country: country,
      postal_code: postalCode,
      company: companyName,
      company_id: companyId,
      hangar_image_url: hangarImageUrl,
      renting_camera: true,
    };

    let res = await addHangar(hangar);
    if (res) {
      navigate(-1);
    }
  };

  return (
    <Container className="page-margin">
      <ConfirmationBox
        heading={`Confirm New Hangar`}
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        cancelText="Cancel"
        body={`Are you sure you wish to create a new hangar for ${companyName}?
        Title: ${title}.
        Image Url: ${hangarImageUrl}.
        Address: ${address}.
        Address 2: ${address2}.
        City: ${city}.
        Country: ${country}.
        Postal Code: ${postalCode}.`}
        confirmText="Create Hangar"
        handleSubmit={() => confirmAddHangar()}
      />
      <h1 className="page-title title-margin">
        {`Create new hangar for ${companyName}`}
      </h1>
      <hr />
      <Row>
        <Col md={6}>
          <div className="subsection-header">Title</div>
          <EditableBox content={title} updateVal={setTitle} edit={true} />
        </Col>
        <Col md={6}>
          <div className="subsection-header">Image Url</div>
          <EditableBox
            content={hangarImageUrl}
            updateVal={setHangarImageUrl}
            edit={true}
          />
        </Col>
        <Col md={6}>
          <div className="subsection-header">Address</div>
          <EditableBox content={address} updateVal={setAddress} edit={true} />
        </Col>
        <Col md={6}>
          <div className="subsection-header">Address 2</div>
          <EditableBox content={address2} updateVal={setAddress2} edit={true} />
        </Col>
        <Col md={4}>
          <div className="subsection-header">City</div>
          <EditableBox content={city} updateVal={setCity} edit={true} />
        </Col>
        <Col md={4}>
          <div className="subsection-header">Country</div>
          <EditableBox content={country} updateVal={setCountry} edit={true} />
        </Col>
        <Col md={4}>
          <div className="subsection-header">Postal Code</div>
          <EditableBox
            content={postalCode}
            updateVal={setPostalCode}
            edit={true}
          />
        </Col>
      </Row>
      <hr />
      <Button
        variant="primary"
        bg="black"
        className="login-submit-button"
        onClick={(e) => setShowConfirm(true)}
      >
        {`Create Hangar`}
      </Button>
    </Container>
  );
};

export default AviationCompanyHangarAddPage;
